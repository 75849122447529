<div class="row collectibles-container d-none d-md-flex">

    <div class="col-4">
        <div #colLeft class="row align-content-start">

            <ng-container *ngFor="let colNft of flexColLeftNft">
                <ng-container *ngTemplateOutlet="nftTemplate; context: {columnNft: colNft}"></ng-container>
            </ng-container>

        </div>
    </div>

    <div class="col-4">
        <div #colMiddle class="row align-content-start">

            <ng-container *ngFor="let colNft of flexColMiddleNft">
                <ng-container *ngTemplateOutlet="nftTemplate; context: {columnNft: colNft}"></ng-container>
            </ng-container>

        </div>
    </div>

    <div class="col-4">
        <div #colRight class="row align-content-start">

            <ng-container *ngFor="let colNft of flexColRightNft">
                <ng-container *ngTemplateOutlet="nftTemplate; context: {columnNft: colNft}"></ng-container>
            </ng-container>

        </div>
    </div>

    <ng-template #nftTemplate let-colNft="columnNft">
        <div class="col-12 mb-4 no-padding-lr collectible-panel">
            <div class="col-12 mb-2 featured-nft" [routerLink]="['/browse', colNft.assetAddress, colNft.tokenID]">
                <img src="{{colNft.imageUrl}}" alt="Collectible" draggable="false">
                <div class="nft-hover">
                    <span class="nft-title">{{colNft.name}}</span>
                </div>
            </div>

            <div class="col-12 profile-container" [routerLink]="['/celebrities/celebrity-detail', colNft.creator.id]">
                <div class="profile-picture">
                    <img class="profile-image" src="{{colNft.creator.profilePicture}}" alt="Profile Picture"
                        draggable="false">
                    <img *ngIf="colNft.creator.isVerfied" class="verified-check"
                        src="assets/img/svg-pangolin/Icon-verified-24.svg" alt="Verified" draggable="false">
                </div>
                <div class="profile-name">{{colNft.creator.name}}</div>
            </div>
        </div>
    </ng-template>

</div>

<div class="row collectibles-container d-md-none">

    <div class="col-12">

        <ng-container *ngFor="let colNft of mobileColNft">
            <ng-container *ngTemplateOutlet="nftTemplate; context: {columnNft: colNft}"></ng-container>
        </ng-container>

    </div>
</div>
