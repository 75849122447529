import { BlockUiService } from 'src/app/shared/services/blockUi/block-ui.service';
import { BlockUiType } from 'src/app/core/enum/general/blockUI-type.enum';
import { Component, NgZone, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { OpenseaService } from 'src/app/core/services/opensea.service';

@Component({
    selector: 'app-base-page',
    template: '<p>base-page works!</p>',
})
export class BasePageComponent implements OnInit {

    hasAccess: boolean = false;
    isLoggedIn: boolean = false;

    constructor(
        protected openseaService: OpenseaService,
        protected ngZone: NgZone,
        protected blockUiService: BlockUiService,
    ) { }

    ngOnInit(): void {
        if (!window.ethereum) {
            return;
        }

        window.ethereum.on('chainChanged', (chainId: string) => {
            this.ngZone.run(() => {
                if (chainId == environment.chainID) {
                    this.blockUiService.close();
                    this.afterValidNetworkOrChain();
                } else {
                    this.blockUiService.open(BlockUiType.network);
                }
            })
        });

        this.openseaService.isMainnet().then(networkID => {
            if (networkID == environment.networkID) {
                this.afterValidNetworkOrChain();
            } else {
                this.blockUiService.open(BlockUiType.network);
            }
        }).catch(err => {
            console.log(err);
        });
    }

    protected afterValidNetworkOrChain(): void {
        this.checkIsConnected();
        this.hasAccess = this.openseaService.hasAccess();
    }

    protected checkIsConnected(): void {
        this.openseaService.getAccount().then(response => {
            this.isLoggedIn = response.result.length == 0 ? false : true;
        });
    }

}
