import { BasePageComponent } from 'src/app/pages/base-page/base-page.component';
import { BlockUiService } from '../../shared/services/blockUi/block-ui.service';
import { Component, NgZone, OnInit } from '@angular/core';
import { OpenseaService } from '../../core/services/opensea.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BasePageComponent implements OnInit {

    constructor(
        protected openseaService: OpenseaService,
        private router: Router,
        protected blockUiService: BlockUiService,
        protected ngZone: NgZone
    ) {
        super(openseaService, ngZone, blockUiService);
    }

    connectMetamask() {
        this.openseaService.connectMetamask().then(result => {
            if (result)
                this.router.navigate(['landing-page']);
        });
    }
}
