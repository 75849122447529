<div class="modal-content">
    <div class="modal-header">
        <h2 class="modal-title">
            Convert wETH
        </h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
            <div class="icon icon-close"></div>
        </button>
    </div>
    <div class="modal-body">
        <div class="row mx-3 mb-3 popup-content">
            <div class="col-12 text-center title">
                Convert WETH
            </div>
            <div class="col-12 pt-2 text-center">
                Easily convert between ETH and WETH
            </div>

            <iframe [src]="uniswapSrc | trustSrc: 'resourceUrl'" width="100%" height="660px"
                    style="
                border: 0;
                margin: 0 auto;
                display: block;
                border-radius: 10px;
                z-index: 1;"
                    id="uniswapId"></iframe>
        </div>
    </div>
</div>
