<div class="container-fluid pt-4 welcome-panel">

    <!-- Desktop -->
    <div class="row d-none d-md-flex">
        <div class="col-7 row">
            <div class="col-12 mb-4 title-container">
                <span>Welcome to </span>
                <span class="gradient">NFTPangolin</span>
                <span>!</span>
            </div>

            <div class="col-12">
                <p class="description-container">NFT Pangolin is a global marketplace for regional creators to issue and sell their unique crypto secured assets to collectors. It also can fund social causes and charitable campaigns to pay it forward.</p>
                <p class="description-container">We aspire to be the leading regional marketplace driving creative, innovative collaborations and boundary pushing campaigns in the NFT space globally for issuers and collectors alike, while making a difference for those in need.</p>
            </div>
        </div>

        <div class="col-5 align-self-center text-center">
            <img class="logo" src="assets/img/svg-pangolin/illus-pangolin-aboutus@2x.png" />
        </div>
    </div>

    <!-- Mobile -->
    <div class="row d-md-none mobile">
        <div class="col-12 mb-3 title-container">
            <span>Welcome to </span>
            <span class="gradient">NFTPangolin</span>
            <span>!</span>
        </div>

        <div class="col-12 mb-3 text-center">
            <img class="logo" src="assets/img/svg-pangolin/illus-pangolin-aboutus@2x.png" />
        </div>

        <div class="col-12 description-panel">
            <div class="description-adjustment">
                <p class="description-container">NFT Pangolin is a global marketplace for regional creators to issue and sell their unique crypto secured assets to collectors. It also can fund social causes and charitable campaigns to pay it forward.</p>
                <p class="description-container mb-0">We aspire to be the leading regional marketplace driving creative, innovative collaborations and boundary pushing campaigns in the NFT space globally for issuers and collectors alike, while making a difference for those in need.</p>
            </div>
        </div>
    </div>

</div>

<div class="container-fluid what-is-panel">

    <div class="row wave-top">
        <svg viewBox="0 -20 700 50" width="100%" height="50" preserveAspectRatio="none">
            <path transform="translate(0, -20)"
                  d="M0,10 c80,-22 240,0 350,18 c90,17 260,7.5 350,-20 v50 h-700" fill="#1e2243" />
        </svg>
    </div>

    <div class="row d-flex align-items-center data-container">
        <div class="col-md-3 d-none d-md-block">
            <img class="bg-design" src="assets/img/svg-pangolin/bg-confetti-left-contact-us.svg" />
        </div>

        <div class="col-md-6 description-container text-center">
            <div class="col-12 mb-4 title-container">
                <span>What are </span>
                <span class="gradient">non-fungible tokens (NFT) collectibles</span>
                <span>?</span>
            </div>

            <div class="col-12">
                NFTs are precious, authentic items which can be digital or physical - images, visuals, audio, published works or tangible items. You can proudly share to the world that you are an owner of a valuable asset and use it in any way as your heart pleases - display it, gift it, exchange it, sell it and many more.
            </div>
        </div>

        <div class="col-md-3 d-none d-md-block">
            <img class="bg-design right" src="assets/img/svg-pangolin/bg-confetti-left-contact-us.svg" />
        </div>
    </div>

    <div class="row wave-bottom">
        <svg viewBox="0 -20 700 50" width="100%" height="50" preserveAspectRatio="none">
            <path transform="translate(0, -20)"
                  d="M0,10 c80,-22 240,0 350,18 c90,17 260,7.5 350,-20 v50 h-700" fill="#010523" />
        </svg>
    </div>

</div>

<div class="container-fluid pt-4 cool-panel">

    <div class="row justify-content-center">
        <div class="col-md-6 text-center title-container">
            <span>Cool! </span>
            <span class="gradient">How do I get one</span>
            <span>?</span>
        </div>
    </div>

    <div class="row justify-content-center">
        <div class="col-md-6 text-center">
            You'll need two things:
        </div>
    </div>

    <div class="row justify-content-center text-center thing-panel">
        <div class="col-md-4 row">
            <div class="col-12 icon-space">
                <img src="assets/img/svg-pangolin/icon-wallet.svg" />
            </div>
            <div class="col-12 title">
                Wallet
            </div>
            <div class="col-12">
                A wallet called MetaMask, to store your currencies and collections
            </div>
        </div>

        <div class="col-md-4 row">
            <div class="col-12 icon-space">
                <img src="assets/img/svg-pangolin/Icon-currency.svg" />
            </div>
            <div class="col-12 title">
                Currency
            </div>
            <div class="col-12">
                A digital crypto currency called Ethereum, to purchase and sell your NFTs.
            </div>
        </div>

        <div class="col-md-6 mt-4">
            Check out our <a class="red-text" [routerLink]="['/how-to-get-started']">handy guide</a> if you need more details!
        </div>
    </div>

</div>
