import { BlockUiType } from '../../../core/enum/general/blockUI-type.enum';
import { Component } from '@angular/core';

@Component({
    selector: 'agmo-block-ui-modal',
    templateUrl: './block-ui-modal.component.html',
    styleUrls: ['./block-ui-modal.component.scss']
})
export class BlockUiModalComponent {

    block: boolean = false;
    show: boolean = false;
    blockUiType = BlockUiType.block;
    incorrectNetworkMessage: string = '';

}
