import { ContactFormModel } from './contact-form-model';

export class ContactForm {
    email: string;
    message: string;
    name: string;
    question: string;
    supportEmail: string;
    title: string;

    constructor(model: ContactFormModel) {
        this.name = model.name;
        this.email = model.email;
        this.title = model.title;
        this.message = model.message;
    }
}

export interface ContactFormQuestion {
    email: string;
    order: number;
    question: string;
}
