<div class="container-fluid">
    <div class="row">
        <div class="col d-flex">
            <div class="gradient-text horizontal page-title">Privacy Policy</div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="m-4">
                <div>Effective: August 1, 2021</div>
                <div>Last Updated: January 4, 2022</div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="header">
                1. Definition
            </div>
            <div class="content">
                <p>NFT Pangolin (“NFT Pangolin”, “our”, “us” or “we”) is committed to protecting your privacy. We have prepared this Privacy Policy to describe to you our practices regarding the Personal Data (as defined below) we collect from users of our website, located at www.nftpangolin.com (the “Site”) and online services (collectively, the “Service”).</p>
                <p>The Personal Data Protection Act 2010, as may be amended from time to time (“Act”), which regulates the processing of personal data in commercial transactions, applies to NFT Pangolin and our related companies.</p>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="header">
                2. Types of Data We Collect
            </div>
            <div class="content">
                <p>We collect Personal Data and Anonymous Data, as described below. For this policy, the terms “Personal Data” and “processing” shall have the meaning prescribed in the Act. “Anonymous Data” means data, including aggregated and de-identified data, that is not associated with or linked to your Personal Data; Anonymous Data does not, by itself, permit the identification of individual persons.</p>
            </div>
            <div class="small-header">
                a. Information You Provide Us
            </div>
            <div class="content">
                <ol type="i">
                    <li>We may collect your first and last name, e-mail and mailing addresses, Ethereum wallet address, and NFT Pangolin password when you create an account to log in to our network (“Account”).</li>
                    <li>If you use our Services on your mobile device, we may collect your phone number and the unique device IMEI ID number.</li>
                    <li>Our Service lets you store preferences like how your content is displayed, your location, safe search settings, notification settings, and favourite widgets. We may associate these choices with your ID, browser or mobile device, and you can edit these preferences at any time.</li>
                    <li>When connecting to our Services via a service provider that uniquely identifies your mobile device, we may receive this identification and use it to offer extended services and/or functionality.</li>
                    <li>Certain Services, such as two-factor authentication, may require our collection of your phone number. We may associate that phone number with your mobile device identification information.</li>
                    <li>If you provide us feedback or contact us via e-mail, we will collect your name and e-mail address, as well as any other content included in the e-mail for reply purposes.</li>
                    <li>If you contact us for support, we may collect your operating system and version, product registration number, and other requested information.</li>
                    <li>We may also collect Personal Data at other points in our Service that state that Personal Data is being collected.</li>
                </ol>
            </div>
            <div class="small-header">
                b. Information Collected via Technology
            </div>
            <div class="content small-left-padding">
                As you navigate through and interact with our Service, we may use automatic data collection technologies to collect certain information about your equipment, browsing actions and patterns, including:
            </div>
            <div class="small-header">
                <ol type="i">
                    <li>
                        <p class="font-weight-bold">Information Collected by Our Servers</p>
                        <p class="content">
                            To make our Service more useful to you, our servers (which may be hosted by a third-party service provider) collect information from you, including your browser type, operating system, Internet Protocol (“IP”) address (a number that is automatically assigned to your computer when you use the Internet, which may vary from session to session), domain name, Ethereum wallet address, wallet type, and/or a date/time stamp for your visit.
                        </p>
                    </li>
                    <li>
                        <p class="font-weight-bold">Log Files</p>
                        <p class="content">
                            As is true of most websites, we gather certain information automatically and store it in log files. This information includes IP addresses, browser type, Internet service provider (“ISP”), referring/exit pages, operating system, date/time stamp, and clickstream data. We use this information to analyze trends, administer the Service, track users’ movements around the Service, gather demographic information about our user base as a whole, and better tailor our Services to our users’ needs. For example, some of the information may be collected so that when you visit the Service, it will recognize you and the information could then be used to serve advertisements and other information appropriate to your interests.
                        </p>
                    </li>
                    <li>
                        <p class="font-weight-bold">Cookies</p>
                        <p class="content">
                            Like many online services, we use cookies to collect information. “Cookies” are small pieces of information that a website sends to your computer’s hard drive while you are viewing the website. We may use both session Cookies (which expire once you close your web browser) and persistent Cookies (which stay on your computer until you delete them) to provide you with a more personal and interactive experience on our Service. This type of information is collected to make the Service more useful to you and to tailor the experience with us to meet your special interests and needs.
                        </p>
                    </li>
                    <li>
                        <p class="font-weight-bold">Pixel Tag</p>
                        <p class="content">
                            In addition, we may use “Pixel Tags” (also referred to as clear Gifs, Web beacons, or Web bugs). Pixel Tags are tiny graphic images with a unique identifier, similar in function to Cookies, that are used to track online movements of Web users. In contrast to Cookies, which are stored on a user’s computer hard drive, Pixel Tags are embedded invisibly in Web pages. Pixel Tags also allow us to send e-mail messages in a format user can read, and they tell us whether e-mails have been opened to ensure that we are sending only messages that are of interest to our users. We may use this information to reduce or eliminate messages sent to a user. We do not tie the information gathered by Pixel Tags to our users’ Personal Data.
                        </p>
                    </li>
                    <li>
                        <p class="font-weight-bold">Analytics Services</p>
                        <p class="content">
                            In addition to the tracking technologies we place, other companies may set their cookies or similar tools when you visit our Service. This includes third party analytics services, including but not limited to Google Analytics (“Analytics Services”), that we engage to help analyze how users use the Service. We may receive reports based on these parties’ use of these tools on an individual or aggregate basis. We use the information we get from Analytics Services only to improve our Service. The information generated by the Cookies or other technologies about your use of our Service (the “Analytics Information”) is transmitted to the Analytics Services. The Analytics Services use Analytics Information to compile reports on user activity. The Analytics Services may also transfer information to third parties where required to do so by law, or where such third parties process Analytics Information on their behalf. Each Analytics Services’ ability to use and share Analytics Information is restricted by such Analytics Services’ Terms of Use and Privacy Policy. By using our Service, you consent to the processing of data about you by Analytics Services in the manner and for the purposes set out above.
                        </p>
                    </li>
                </ol>
            </div>
            <div class="small-header">
                c. Information Collected from Third Party Companies
            </div>
            <div class="content small-left-padding">
                We may receive Personal and/or Anonymous Data about you from companies that provide our Services by way of a co-branded or private-labelled website or companies that offer their products and/or services on our Service. In particular, MetaMask and other wallets provide us with your Ethereum address and certain other information you choose to share with MetaMask and other wallets. These third-party companies may supply us with Personal Data. We may add this to the information we have already collected from you via our Service to improve it. We do not collect Personal Data automatically, but we may tie the information that we collect automatically to Personal Data about you that we collect from other sources or that you provide to us.
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="header">
                3. Use of Your Personal Data
            </div>
            <div class="small-header">
                a. General Use
            </div>
            <div class="content small-left-padding">
                In general, Personal Data you submit to us is used either to respond to requests that you make or to aid us in serving you better. We use your Personal Data in the following ways (not exhaustive):
            </div>
            <div class="content">
                <ol type="i">
                    <li>facilitate the creation of and secure your Account on our network;</li>
                    <li>identify you as a user in our system;</li>
                    <li>provide improved administration of our Service;</li>
                    <li>provide the Services you request, including but not limited to facilitating your cryptocurrency transactions through MetaMask (<a href="https://metamask.io">https://metamask.io</a>) or other wallets;</li>
                    <li>improve the quality of experience when you interact with our Service;</li>
                    <li>send you a welcome e-mail to verify ownership of the e-mail address provided when your Account was created;</li>
                    <li>protect you and other users from any conduct that violates the Terms of Use or to prevent abuse or harassment of any user;</li>
                    <li>display your username next to the digital assets you wish to sell on the Website;</li>
                    <li>send you administrative e-mail notifications, such as security or support and maintenance advisories;</li>
                    <li>send you e-mail notifications related to actions on Service, including notifications of offers on your digital assets;</li>
                    <li>respond to your inquiries related to employment opportunities or other requests;</li>
                    <li>contact you, if required, as a part of fraud protection or to solicit your feedback;</li>
                    <li>in any other way we may describe when you provide the Personal Data; and</li>
                    <li>send newsletters, surveys, offers, and other promotional materials related to our Services and for other marketing purposes of NFT Pangolin.</li>
                </ol>
            </div>
            <div class="content small-left-padding">
                We may use your Personal Data to contact you about our own and third parties’ goods and services that may be of interest to you.
            </div>
            <div class="small-header">
                b. Anonymous Data
            </div>
            <div class="content small-left-padding">
                We may create Anonymous Data records from Personal Data by excluding information (such as your name) that makes the data personally identifiable to you. We use this Anonymous Data to analyze request and usage patterns so that we may enhance the content of our Services and improve Service navigation. We reserve the right to use Anonymous Data for any purpose and to disclose Anonymous Data to third parties without restriction.
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="header">
                4. Disclosure of Your Personal Data
            </div>
            <div class="content">
                We disclose your Personal Data as described in this policy.
            </div>
            <div class="small-header">
                a. Third-Party Service Providers
            </div>
            <div class="content small-left-padding">
                We may share your Personal Data with third-party service providers to:
            </div>
            <div class="content">
                <ol type="i">
                    <li>provide you with the Services that we offer you through our Service;</li>
                    <li>to conduct quality assurance testing;</li>
                    <li>to facilitate account creation;</li>
                    <li>to provide technical support; and/or</li>
                    <li>to provide other services to NFT Pangolin.</li>
                </ol>
            </div>
            <div class="small-header">
                b. Affiliates
            </div>
            <div class="content small-left-padding">
                We may share some or all of your Personal Data with our parent company, subsidiaries, joint ventures, or other companies under common control (“Affiliates”), in which case we will require our Affiliates to honour this policy.
            </div>
            <div class="small-header">
                c. Corporate Restructuring
            </div>
            <div class="content small-left-padding">
                We may share some or all of your Personal Data in connection with or during negotiation of any merger, financing, acquisition or dissolution transaction or proceeding involving sale, transfer, divestiture, or disclosure of all or a portion of our business or assets. In the event of an insolvency, bankruptcy, or receivership, Personal Data may also be transferred as a business asset. If another company acquires our company, business, or assets, that company will possess the Personal Data collected by us and will assume the rights and obligations regarding your Personal Data as described in this Privacy Policy.
            </div>
            <div class="small-header">
                d. As Legally Required
            </div>
            <div class="content small-left-padding">
                NFT Pangolin may disclose Personal Data if it believes in good faith that such disclosure is necessary:
            </div>
            <div class="content">
                <ol type="i">
                    <li>in connection with any legal investigation;</li>
                    <li>to comply with relevant laws or to respond to subpoenas or warrants served on NFT Pangolin;</li>
                    <li>to protect or defend the rights or property of NFT Pangolin or users of the Service; and/or</li>
                    <li>to investigate or assist in preventing any violation or potential violation of the law, this Privacy Policy, or our Terms of Use.</li>
                </ol>
            </div>
            <div class="small-header">
                e. Other Disclosures
            </div>
            <div class="content small-left-padding">
                We may also disclose your Personal Data, to fulfil the purpose for which you provide it; for any other purpose disclosed by us when you provide it; or with your consent.
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="header">
                5. Third-Party Websites
            </div>
            <div class="content">
                Our Service may contain links to third party websites. When you click on a link to any other website or location, you will leave our Service and go to another site, and another entity may collect Personal Data or Anonymous Data from you. You may also find marketplaces powered by the Service on other websites. We have no control over, do not review, and cannot be responsible for, these outside websites or their content. Please be aware that the terms of this policy do not apply to these outside websites or content, or any collection of your Personal Data after you click on links to such outside websites. We encourage you to read the privacy policies of every website you visit. The links to third-party websites or locations are for your convenience and do not signify our endorsement of such third parties or their products, content or websites.
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="header">
                6. Third-Party Wallet Extensions
            </div>
            <div class="content">
                For conducting cryptocurrency transactions, we use third-party electronic wallet extensions such as (but not limited to) MetaMask; your interactions with MetaMask and/or any third-party electronic wallet extensions are governed by the applicable privacy policies.
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="header">
                7. Your Choices Regarding Information
            </div>
            <div class="content">
                You have several choices regarding the use of information on our Services:
            </div>
            <div class="content">
                <ol type="a">
                    <li><b>Email Communications.</b> We will periodically send you free, opt-in newsletters and e-mails that directly promote the use of our Service. When you receive newsletters or promotional communications from us, you may indicate a preference to stop receiving further communications from us and you will have the opportunity to “opt-out” by following the unsubscribe instructions provided in the e-mail you receive. Despite your indicated e-mail preferences, we may send you occasional service-related communications, including notices of updates to our Terms of Use or Privacy Policy.</li>
                    <li><b>If you decide at any time that you no longer wish to accept Cookies from our Service for any of the purposes described above, then you can instruct your browser, by changing its settings, to stop accepting Cookies or to prompt you before accepting a Cookie from the websites you visit. If you do not accept Cookies, however, you may not be able to use all portions of the Service or all functionality of the Service.</b></li>
                </ol>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="header">
                8. Data Access and Control
            </div>
            <div class="content">
                You can view, access, edit, or delete your Personal Data for many aspects of the Service via your account settings page. You can also make choices about NFT Pangolin’s use of your data. You can always choose whether you want to receive marketing communications from us. You can also opt-out from receiving marketing communications from us by using the opt-out link on the communication, or by visiting your account settings page. We may retain an archived copy of your records as required by law or for legitimate business purposes.
            </div>
            <div class="content">
                <ol type="a">
                    <li><b>Data Access.</b> You can access your Personal Data on your account settings page.</li>
                    <li><b>Data Erasure.</b> You can delete your Personal Data on your account settings page.</li>
                    <li><b>Data Correction.</b> You can modify your Personal Data on your account settings page.</li>
                </ol>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="header">
                9. Data Retention
            </div>
            <div class="content">
                We may retain your Personal Data as long as you continue to use the Service, have an account with us, or for as long as is necessary to fulfil the purposes outlined in this Privacy Policy.
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="header">
                10. Data Protection
            </div>
            <div class="content">
                We care about the security of your information and use physical, administrative, and technological safeguards to preserve the integrity and security of all information collected through our website. However, no security system is impenetrable and we cannot guarantee the security of our systems 100%. If any information under our control is compromised as a result of a breach of security, we will take steps to investigate the situation and, where appropriate, notify those individuals whose information may have been compromised and take other steps, under any applicable laws and regulations.
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="header">
                11. Consent
            </div>
            <div class="content">
                Consent is necessary before using the Service in the following situations:
            </div>
            <div class="content">
                <ol type="a">
                    <li><b>If you are under 18, you should ensure that you obtain the consent of your parents or legal guardian before using our Service.</b></li>
                    <li><b>If you are a firm, corporation or entity supplying personal data of your partners, directors, shareholders, employees, officers and/or other persons to us, please do ensure that you have obtained their consent and bring this Privacy Policy to their attention.</b></li>
                </ol>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="header">
                12. A Note to Users Outside of Malaysia
            </div>
            <div class="content">
                If you are a non-Malaysian user of the Service, by visiting the Service and providing us with data, you acknowledge and agree that your Personal Data may be processed for the purposes identified in the Privacy Policy. In addition, your Personal Data may be processed in the country in which it was collected and in other countries, including Malaysia, where laws regarding the processing of Personal Data may be less stringent than the laws in your country. By providing your data, you consent to such transfer.
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="header">
                13. Changes to this Privacy Policy
            </div>
            <div class="content">
                This Privacy Policy may be updated from time to time for any reason. We will notify you of any changes to our Privacy Policy by posting the new Privacy Policy on the Site. The date the Privacy Policy was last updated is identified at the beginning of this Privacy Policy. You are responsible for ensuring we have an up-to-date active and deliverable email address for you, and for periodically visiting our Service and this Privacy Policy to check for any changes.
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="header">
                14. Questions; Contacting NFT Pangolin; Reporting Violations
            </div>
            <div class="content">
                If you have any questions or concerns or complaints about our Privacy Policy or our data collection or processing practices, or if you want to report any security violations to us, please email us at <a href="mailto:info@nftpangolin.com">info@nftpangolin.com</a>
            </div>
        </div>
    </div>
    <div class="row pt-3">
        <div class="col">
            <div class="text-center">END OF POLICY</div>
        </div>
    </div>
</div>
