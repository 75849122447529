import { Order, OpenSeaFungibleToken, OpenSeaAccount, OrderSide } from 'opensea-js/lib/types';
import { Asset } from './opensea-asset';
import { Moment } from 'moment';
import { BaseClass } from '../base-class';

export class OrderObj extends BaseClass {
    asset: Asset;
    currentUnitPrice: any;
    currentDollarUnitPrice: any;
    paymentTokenContract: OpenSeaFungibleToken;
    quantity: number;
    makerAccount: OpenSeaAccount | string;
    expirationTime: number;
    expirationDuration: string;
    orderSide: OrderSide;
    createdDate: Moment;

    constructor(model: any) {
        super();
        this.asset = model.asset;
        this.paymentTokenContract = model.payment_token_contract;
        this.quantity = parseInt(model.quantity);
        this.makerAccount = model.maker_account ? model.maker_account : model.maker;
        this.expirationTime = model.expiration_time;
        this.orderSide = model.side;
        this.currentUnitPrice = (parseInt(model.current_price) / Math.pow(10, model.payment_token_contract.decimals)) / this.quantity;
        this.createdDate = model.created_date ? this.toMoment(model.created_date) : null;
    }
}
