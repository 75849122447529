<div class="container-fluid p-0" id="tab1">
    <div class="row">
        <div class="col-12">
            <div class="pb-5">
                <div class="what-mobile-panel big-description">
                    <img class="left-confetti" src="assets/img/svg-pangolin/bg-confetti-left-contact-us.svg">
                    <img class="right-confetti" src="assets/img/svg-pangolin/bg-confetti-left-contact-us.svg">
                    <div class="title mb-4">
                        What are <span class="color-text">non-fungible tokens</span>?
                    </div>
                    <div class="description">
                        <p>
                            NFTs or Nifties as some call it, are serialized digital assets that cannot be interchanged for another asset of the same type.
                        </p>
                        <p>
                            Own it, trade it, keep it, display it, sell it, the options are unlimited and it’s all recorded on the blockchain.
                        </p>
                    </div>
                </div>
                <div class="wave">
                    <svg viewBox="0 -20 700 50" width="100%" height="60" preserveAspectRatio="none">
                        <path transform="translate(0, -20)"
                              d="M0,10 c80,-22 240,0 350,18 c90,17 260,7.5 350,-20 v50 h-700" fill="#010523" />
                    </svg>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid pt-4 pb-5" id="tab2">
    <div class="row">
        <div class="col-12 text-center big-description">
            <div class="title mb-4">
                Why <span class="color-text">NFT</span> ?
            </div>
            <div class="description why-nft">
                <p>NFTs and their corresponding ownership are recorded on the blockchain,
                proving NFTs verifiable digital certification and authenticating the uniqueness of each asset.
                Because of this they become valuable assets to be collected, traded or kept.</p>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-4 text-center mt-4" *ngFor="let reason of reasons">
                <div class="celebrity-image-panel reason-image">
                    <div class="image-container">
                        <img class="celebrity-image" [src]="reason.imageUrl" />
                    </div>
                </div>
                <div class="reason">{{ reason.reason }}</div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid special-margin-top" id="tab3">
    <hr class="gradient-seperator d-md-none">

    <div class="row getting-started-step">
        <div class="col-md-6">
            <h1>
                <span class="title color-text">Getting Started</span>
            </h1>
            <div class="description">
                Getting digital collectibles is easy. Ready to roll in?
            </div>
            <div class="py-4 d-md-none">
                <ng-template *ngTemplateOutlet="videoPanel">
                </ng-template>
            </div>
            <div class="mt-4">
                <div class="mb-3 d-flex align-items-center">
                    <div class="index-no">1</div>
                    <div class="step-title">Setting up wallet</div>
                </div>
                <div class="step-description-panel">
                    First, you need a <a class="link" (click)="popupWalletDetail()">wallet</a> to store your money and
                    precious
                    collectibles safely. Watch the MetaMask
                    Introduction Video to learn more.
                </div>
            </div>
            <div class="mt-4">
                <div class="mb-3 d-flex align-items-center">
                    <div class="index-no">2</div>
                    <div class="step-title">Get some money</div>
                </div>
                <div class="step-description-panel">
                    Next, you’ll need some money. On NFTPangolin, we use a digital crypto currency called Ethereum.
                    You can easily convert your fiat money into Ethereum with MetaMask.
                </div>
            </div>
            <div class="mt-4">
                <div class="mb-3 d-flex align-items-center">
                    <div class="index-no">3</div>
                    <div class="step-title">Make an offer</div>
                </div>
                <div class="step-description-panel">
                    Check out the cool collectibles on our site. Make an offer quickly anytime something catches your eye.
                </div>
            </div>
            <div class="my-4">
                Do visit our site regularly for more new drops and arrivals!
            </div>
        </div>
        <div class="col-md-6 video-column d-none d-md-block">
            <ng-template *ngTemplateOutlet="videoPanel">
            </ng-template>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div class="btn btn-alt btn-normal-font-size" [routerLink]="['/browse']">
                Browse Collections
            </div>
        </div>
    </div>
</div>

<ng-template #videoPanel>
    <div class="video-panel">
        <div class="video-container">
            <iframe class="embed-responsive-item" width="560" height="315"
                    src="https://www.youtube.com/embed/YVgfHZMFFFQ" title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
        </div>
    </div>
</ng-template>
