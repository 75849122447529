import { Injectable } from '@angular/core';
import { MenuItem } from '../models/menu/menu-item';

@Injectable({
    providedIn: 'root',
})
export class MenuService {
    menu: MenuItem[];

    constructor() {
        this.getMenu();
    }

    getMenu() {
        this.menu = [
            { name: 'Dashboard', url: '/dashboard', icon: 'fas fa-chart-line text-primary' },
            { name: 'Maps', url: '/maps', icon: 'fas fa-map-marker-alt text-orange' },
            { name: 'User profile', url: '/user-profile', icon: 'fas fa-user text-yellow' },
            { name: 'Tables', url: '/tables', icon: 'fas fa-list text-red' },
            { name: 'Login', url: '/login', icon: 'fas fa-key text-info' },
            { name: 'Register', url: '/register', icon: 'fas fa-user-circle text-pink' },
            { name: 'Examples', url: '/example', icon: 'fas fa-rocket' }
        ];
    }
}
