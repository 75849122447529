import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PopupType } from './popup-type.enum';
import { PopupModalComponent } from './popup-modal.component';
import { PopupOptions } from './popup-options';

@Injectable({
    providedIn: 'root',
})
export class PopupService {
    defaultOptions: any = {
        size: 'sm',
        backdrop: 'static',
        centered: true,
        keyboard: false,
        windowClass: 'modal-popup',
        backdropClass: 'backdrop-popup',
    }

    constructor(private modalService: NgbModal) { }

    alert(message: string, options?: PopupOptions): Promise<any> {
        return this.open(message, PopupType.alert, options);
    }

    confirm(message: string, options?: PopupOptions): Promise<any> {
        return this.open(message, PopupType.confirm, options);
    }

    private open(message: string, popupType: PopupType, options: PopupOptions): Promise<any> {
        const modalRef = this.modalService.open(PopupModalComponent, this.defaultOptions);
        modalRef.componentInstance.type = popupType;
        modalRef.componentInstance.message = message;

        if (options) {
            modalRef.componentInstance.okLabel = options.okLabel;
            modalRef.componentInstance.cancelLabel = options.cancelLabel;
            modalRef.componentInstance.okClass = options.okClass;
            modalRef.componentInstance.cancelClass = options.cancelClass;
            modalRef.componentInstance.titleLabel = options.titleLabel;
        }
        
        return modalRef.result;
    }
}
