import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { PreviewModalComponent } from './preview-modal.component';
import { SharedModule } from '../../shared.module';

@Injectable({
    providedIn: SharedModule,
})
export class PreviewService {
    constructor(
        private modalService: NgbModal) { }

    // media: can be single file (image or photo) or array (photos only)
    // selected: used to compare and pinpoint selected item in carousel
    open(media: any, selected?: any) {
        const popupOptions = {
            size: 'lg',
            backdrop: 'static',
            centered: true,
            keyboard: false,
            windowClass: 'modal-full-screen'
        } as NgbModalOptions;

        const modalRef = this.modalService.open(PreviewModalComponent, popupOptions);
        modalRef.componentInstance.data = media;
        modalRef.componentInstance.isArray = Array.isArray(media);
        modalRef.componentInstance.activeId = selected ? selected.id : null;
    }
}
