import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'pluralize' })
export class PluralizePipe implements PipeTransform {
    transform(input: number, noun: string, append: string = 's'): string {
        if (input <= 1)
            return `${noun}`;
        else
            return `${noun}${append}`;
    }
}
