import { UserModel } from './user-model';

export class User {
    name: string;
    email: string;
    username: string;
    walletAddress: string;
    docId: string;

    constructor(model: UserModel) {
        this.name = model.name;
        this.email = model.email;
        this.username = model.username;
        this.walletAddress = model.wallet_address;
    }
}
