import { Component, NgZone, OnInit } from '@angular/core';
import * as moment from 'moment';
import { environment as env } from '../../../../environments/environment';
import { OrderObj } from '../../../core/models/opensea/opensea-orders';
import { OpenseaService } from '../../../core/services/opensea.service';
import { BlockUiService } from '../../../shared/services/blockUi/block-ui.service';
import { OrderFilter } from '../order-filter';
import { OrderFilterService } from '../order-filter.service';
import { ToastrService } from 'ngx-toastr';
import { BasePageComponent } from 'src/app/pages/base-page/base-page.component';

@Component({
    selector: 'app-user-offers',
    templateUrl: './user-offers.component.html',
    styleUrls: ['./user-offers.component.scss']
})
export class UserOffersComponent extends BasePageComponent {
    hasAccess = false;
    walletAddress = this.openseaService.getWalletAddress();
    etherscanEndpoint = env.etherscanEndpoint;
    offerParams = this.orderFilterService.filter ? this.orderFilterService.filter : new OrderFilter();

    offerTabs = [
        { name: 'Offer Made', value: 0, isSelected: true },
        { name: 'Offer Received', value: 1, isSelected: false }
    ];
    loadOffer = false;

    offers: OrderObj[] = [];
    selectedOfferTabs = 0;

    offerTableDisplayColumns = ['Item', 'UnitPrice', 'Quantity', 'From', 'Expiration'];
    constructor(
        protected openseaService: OpenseaService,
        private orderFilterService: OrderFilterService,
        protected blockUiService: BlockUiService,
        protected ngZone: NgZone,
        private toastr: ToastrService
    ) {
        super(openseaService, ngZone, blockUiService);
    }

    toggleOfferTabs(tab) {
        this.offerTabs.forEach(e => e.isSelected = false);
        tab.isSelected = true;
        this.selectedOfferTabs = tab.value;

        this.proccessOfferFilter();
    }

    getOffer(reload?: boolean) {
        this.loadOffer = true;

        if (reload) {
            this.offers = [];
        }

        this.openseaService.getUserOffers(this.offerParams).subscribe(result => {
            this.offers = this.offers.concat(result.orders.map(e => {
                let offer = new OrderObj(e);

                if (offer.expirationTime == 0) {
                    offer.expirationDuration = "";
                } else {
                    let days = 60 * 60 * 24;
                    let timeDiff = (offer.expirationTime - (moment().valueOf() / 1000)) / days;

                    if (timeDiff >= 1) {
                        offer.expirationDuration = `in ${Math.round(timeDiff).toString()} ${timeDiff == 1 ? 'day' : 'days'}`;
                    }
                    else {
                        offer.expirationDuration = `in ${Math.round(timeDiff * 24).toString()} hours`;
                    }
                }
                return offer;
            }));
        }, err => {
                console.log(err);
        }).add(() => {
            this.loadOffer = false;
        });
    }

    onOfferScroll(e) {
        const tableViewHeight = e.target.offsetHeight;
        const tableScrollHeight = e.target.scrollHeight;
        const scrollLocation = e.target.scrollTop;

        const buffer = 1;
        const limit = tableScrollHeight - tableViewHeight - buffer;
        if (scrollLocation > limit) {
            this.offerParams.offset += this.offerParams.limit;
            this.getOffer();
        }
    }

    proccessOfferFilter() {
        this.offerParams = new OrderFilter();

        if (this.selectedOfferTabs == 0) {
            this.offerParams.maker = this.walletAddress;
        } else if (this.selectedOfferTabs == 1) {
            this.offerParams.owner = this.walletAddress;
        }

        this.getOffer(true);
    }

    isVideo(src: string) {
        let extension = src.split('.').pop();

        if (extension == ('mp4' || 'webm')) {
            return true;
        }
        else return false;
    }

    truncateMiddleText(str: string) {
        return str.substr(0, 6) + '...' + str.substr(str.length - 4, str.length)
    }

    promptToaster() {
        this.toastr.success('Wallet Address is Copied');
    }

    protected afterValidNetworkOrChain(): void {
        super.afterValidNetworkOrChain();
        this.proccessOfferFilter();
    }

}
