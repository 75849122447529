import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { PopupOptions } from 'src/app/shared/services/popup/popup-options';
import { PopupService } from 'src/app/shared/services/popup/popup.service';
import { ViewportScroller } from '@angular/common';

@Component({
    selector: 'app-get-started',
    templateUrl: './get-started.component.html',
    styleUrls: ['./get-started.component.scss']
})
export class GetStartedComponent implements OnInit {
    tabs = [{
        id: 1,
        name: "What is non-fungible tokens"
    }, {
        id: 2,
            name: "Why non-fungible tokens"
    }, {
        id: 3,
        name: "Getting started"
    }];
    selectedTab: number = 1;
    reasons = [{
        imageUrl: "assets/img/svg-pangolin/illus-signed.svg",
        reason: "Immutable"
    }, {
        imageUrl: "assets/img/svg-pangolin/illus-rare.svg",
        reason: "Rare"
    }, {
        imageUrl: "assets/img/svg-pangolin/Illus-techy.svg",
        reason: "Creative"
    }];

    constructor(
        private popupService: PopupService,
        private route: ActivatedRoute,
        private viewportScroller: ViewportScroller,
    ) { }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        this.route.fragment.pipe(first()).subscribe(
            (fragment) => {
                if (!fragment) {
                    return;
                }

                this.viewportScroller.scrollToAnchor(fragment);
            }
        )
    }

    popupWalletDetail() {
        let message = "Wallets are used to send, receive, pay and store crypto currencies and your NFT purchases. Refer to our FAQ to learn more.";
        this.popupService.alert(message, { titleLabel: "What is wallet?" } as PopupOptions)
    }
}
