import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { ImageCropperModule } from 'ngx-image-cropper';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { PopupModalComponent } from './services/popup/popup-modal.component';
import { LoadingComponent } from './components/loading/loading.component';
import { BlockUiModalComponent } from './services/blockUi/block-ui-modal.component';
import { UploaderDirective } from './components/uploader/uploader.directive';
import { LinkifyPipe } from './pipes/linkify.pipe';
import { TrustSrcPipe } from './pipes/trust-src.pipe';
import { PreviewModalComponent } from './services/preview/preview-modal.component';
import { LoaderComponent } from './services/loader/loader.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { DatetimeMomentDirective } from './plugin-configs/ngb-datetime-moment/ngb-datetime-moment.directive';
import { ImageAspectRatioDirective } from './directive/image-aspect-ratio.directive';
import { PluralizePipe } from './pipes/pluralize.pipe';
import { AgmoImageCropperComponent } from './services/image-cropper/image-cropper.component';
import { PurchaseModalComponent } from './services/purchase/purchase-modal/purchase-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClipboardModule } from 'ngx-clipboard';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { ConvertCurrencyPopup } from './services/convert-currency-popup/convert-currency-popup.component';

@NgModule({
    declarations: [
        PopupModalComponent,
        LoadingComponent,
        BlockUiModalComponent,
        UploaderDirective,
        LinkifyPipe,
        TrustSrcPipe,
        PreviewModalComponent,
        BreadcrumbComponent,
        LoaderComponent,
        DatetimeMomentDirective,
        ImageAspectRatioDirective,
        PluralizePipe,
        AgmoImageCropperComponent,
        PurchaseModalComponent,
        ConvertCurrencyPopup
    ],
    imports: [
        CommonModule,
        NgbModule,
        RouterModule,
        ImageCropperModule,
        InfiniteScrollModule,
        FormsModule,
        ReactiveFormsModule,
        ClipboardModule,
        MatSelectModule,
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule 
    ],
    exports: [
        PopupModalComponent,
        LoadingComponent,
        BlockUiModalComponent,
        UploaderDirective,
        LinkifyPipe,
        TrustSrcPipe,
        BreadcrumbComponent,
        DatetimeMomentDirective,
        LoaderComponent,
        ImageAspectRatioDirective,
        PluralizePipe,
        AgmoImageCropperComponent,
        InfiniteScrollModule,
    ],
    entryComponents: [
        PopupModalComponent,
        BlockUiModalComponent,
        PreviewModalComponent,
        LoaderComponent,
        AgmoImageCropperComponent,
        PurchaseModalComponent,
        ConvertCurrencyPopup,
    ],
    providers: [
        MatDatepickerModule,
        MatNativeDateModule,
    ],
})
export class SharedModule { }
