<ng-container *ngIf="hasAccess">
    <div class="gray-container">
        <div class="container-id">
            <div class="circle-container">
                <img class="profile-img" src="/assets/img/svg-pangolin/Icon-profile-photo-64.svg" />
            </div>

            <div class="id-user" title="Copy Wallet Address">
                <div class="gradient-capsule" (click)="promptToaster()" ngxClipboard [cbContent]="walletAddress">
                    <div class="icon-wallet"></div>
                </div>
            </div>

            <div class="container-setting">
                <div [routerLink]="['/setting']" class="text-setting">
                    Settings
                </div>
            </div>
        </div>
    </div>

    <div class="collection">
        <div class="ml-4 action-wrapper d-inline" *ngFor="let menu of ProfileMenuOptions">
            <div class="btn section-title" (click)="filterListing(menu)" [ngClass]="{'gradient-seperator': selectedTab == menu.value}">
                {{menu.label}}
            </div>
        </div>
        <hr class="menu" />
        <div *ngIf="selectedTab == 1" class="asset-listing-wrapper">
            <app-asset-listing [params]="ownedParams" [filters]="assetFilters"></app-asset-listing>
        </div>
        <div *ngIf="selectedTab == 2" class="asset-listing-wrapper">
            <app-user-activity-v2></app-user-activity-v2>
        </div>
    </div>
</ng-container>
