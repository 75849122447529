<footer class="footer">
    <div class="container-fluid">
        <hr class="gradient-seperator mb-2" />
        <div class="row">
            <div class="col-md-6">
                <ul class="nav nav-footer text-sm-left d-md-flex">
                    <li class="nav-item">
                        <a [routerLink]="['/about-us']" class="nav-link">About Us</a>
                    </li>
                    <li class="nav-item">
                        <a [routerLink]="'/how-to-get-started'" fragment="tab3" class="nav-link">Get Started</a>
                    </li>
                    <li class="nav-item">
                        <a [routerLink]="'/contact-us'" class="nav-link">Contact Us</a>
                    </li>
                    <li class="nav-item">
                        <a href="https://nftpangolin.com/job-openings/" target="_blank" class="nav-link">Jobs</a>
                    </li>
                    <li class="nav-item">
                        <a [routerLink]="'/faq'" class="nav-link">FAQ</a>
                    </li>
                </ul>
            </div>
        </div>
        <hr class="my-4" />
        <div class="row align-items-center justify-content-md-between">
            <div class="col-md-8">
                <div class="d-flex align-items-center copyright-panel">
                    <div class="copyright-item">
                        &copy; 2021 NFTPangolin. All rights reserved.
                    </div>
                    <div class="copyright-item d-flex">
                        <div class="footer-link mr-3">
                            <a [routerLink]="'/terms'">Terms of Service</a>
                        </div>
                        <div class="footer-link">
                            <a [routerLink]="'/privacy-policy'">Privacy Policy</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 social-icon">
                <a class="mr-3" href="https://www.facebook.com/NFTPangolin/?ti=as" target="_blank">
                    <div class="icon-facebook"></div>
                </a>
                <a class="mr-3" href="https://twitter.com/NftPangolin?s=20" target="_blank">
                    <div class="icon-twitter"></div>
                </a>
                <a href="https://instagram.com/nftpangolin?utm_medium=copy_link_" target="_blank">
                    <div class="icon-instagram"></div>
                </a>
            </div>
        </div>
    </div>
</footer>
