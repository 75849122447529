<div *ngIf="hasAccess" class="content-wrapper py-3">
    <div class="pb-3 pl-2 text-center" #container>
        <button class="btn-clipboard" (click)="promptToaster()" ngxClipboard [cbContent]="walletAddress" [container]="container">
            {{walletAddress}}
            <span><i class="far fa-clone"></i></span>
        </button>
    </div>

    <div class="py-2 px-3">
        <div class="table-container">
            <div class="p-3 table-header">Trading History</div>
            <div class="py-2 px-3">
                <app-dropdown [options]="tradingFilterOption"
                              [(value)]="selectedOption"
                              (callback)="proccessTradingFilter()">
                </app-dropdown>
            </div>
            <div class="table-holder">
                <mat-table #table [dataSource]="tradingHistory" class="table-list" (scroll)="onTradingHistoryScroll($event)">
                    <ng-container>
                        <ng-container matColumnDef="Event">
                            <mat-header-cell *matHeaderCellDef>Event</mat-header-cell>
                            <mat-cell *matCellDef="let item">{{getTradingEventName(item.eventType)}}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="Item">
                            <mat-header-cell *matHeaderCellDef>Item</mat-header-cell>
                            <mat-cell *matCellDef="let item">
                                <div class="c-pointer d-flex" [routerLink]="['/browse', item.asset.asset_contract.address, item.asset.token_id]">
                                    <div class="asset-image-wrapper">
                                        <div class="asset-preview">
                                            <ng-container *ngIf="item.asset.image_url">
                                                <img *ngIf="!isVideo(item.asset.image_url)" src="{{item.asset.image_url}}" />
                                                <video *ngIf="isVideo(item.asset.image_url)" controlsList="nodownload" playsinline autoplay muted loop src="{{data.asset.image_url}}"></video>
                                            </ng-container>
                                            <ng-container *ngIf="!item.asset.image_url">
                                                <img src="/assets/img/image-placeholder.png" />
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="asset-name">
                                        {{item.asset.name}}
                                    </div>
                                </div>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="UnitPrice">
                            <mat-header-cell *matHeaderCellDef>Unit Price</mat-header-cell>
                            <mat-cell *matCellDef="let item">
                                <div *ngIf="item.currentUnitPrice">{{item.currentUnitPrice}} ETH</div>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="Quantity">
                            <mat-header-cell *matHeaderCellDef>Quantity</mat-header-cell>
                            <mat-cell *matCellDef="let item">{{item.quantity}}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="From">
                            <mat-header-cell *matHeaderCellDef>From</mat-header-cell>
                            <mat-cell *matCellDef="let item">
                                <div *ngIf="item.fromAccount" class="d-flex">
                                    <div class="profile-picture-wrapper">
                                        <div class="user-profile-picture">
                                            <img src="{{item.fromAccount.profile_img_url}}" />
                                        </div>
                                    </div>
                                    <div *ngIf="item.fromAccount.user" class="contract-address">
                                        {{item.fromAccount.user.username || truncateMiddleText(item.fromAccount.address)}}
                                    </div>
                                    <div *ngIf="!item.fromAccount.user" class="contract-address">
                                        {{truncateMiddleText(item.fromAccount.address)}}
                                    </div>
                                </div>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="To">
                            <mat-header-cell *matHeaderCellDef>To</mat-header-cell>
                            <mat-cell *matCellDef="let item">
                                <div *ngIf="item.toAccount" class="d-flex">
                                    <div class="profile-picture-wrapper">
                                        <div class="user-profile-picture">
                                            <img src="{{item.toAccount.profile_img_url}}" />
                                        </div>
                                    </div>
                                    <div *ngIf="item.toAccount.user" class="contract-address">
                                        {{item.toAccount.user.username || truncateMiddleText(item.toAccount.address)}}
                                    </div>
                                    <div *ngIf="!item.toAccount.user" class="contract-address">
                                        {{truncateMiddleText(item.toAccount.address)}}
                                    </div>
                                </div>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="Date">
                            <mat-header-cell *matHeaderCellDef>Date</mat-header-cell>
                            <mat-cell *matCellDef="let item">
                                <div *ngIf="item.transaction">
                                    <a href="{{etherscanEndpoint}}tx/{{item.transaction.transaction_hash}}">
                                        {{item.createdDate | amDateFormat: 'DD/MM/YYYY'}}, {{item.createdDate | amDateFormat: 'h:mma'}}
                                    </a>
                                </div>
                                <div *ngIf="!item.transaction">
                                    {{item.createdDate | amDateFormat: 'DD/MM/YYYY'}}, {{item.createdDate | amDateFormat: 'h:mma'}}
                                </div>
                            </mat-cell>
                        </ng-container>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="tradingTableDisplayColumns"></mat-header-row>
                    <mat-row *matRowDef="let item; let i = index; columns: tradingTableDisplayColumns;"></mat-row>
                </mat-table>
                <div *ngIf="!loadTrading && tradingHistory.length == 0" class="no-data">
                    No data available
                </div>
            </div>
            <div *ngIf="loadTrading">
                <agmo-loading></agmo-loading>
            </div>
        </div>
    </div>
</div>
