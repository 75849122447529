import { environment as env } from '../../../environments/environment';

export class OrderFilter {
    offset: number;
    limit: number;
    side: number;
    maker: string;
    owner: string;
    collection_slug: string;

    constructor() {
        this.offset = 0;
        this.limit = 10;
        this.side = 0;
        this.collection_slug = env.openSeaCollection;
    }
}
