import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Network, OpenSeaPort } from 'opensea-js';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import Web3 from 'web3';
import { environment as env } from '../../../environments/environment';
import { OpenSeaEvent } from '../models/opensea/opensea-event';
import { StorageService } from './storage.service';
import { Order, OrderSide } from 'opensea-js/lib/types';

declare global {
    interface Window { web3: any, ethereum: any }
}

@Injectable({
    providedIn: 'root'
})
export class OpenseaService {
    private apiRoute = env.openSeaApiRoute;
    private wyvernRoute = env.openSeaWyvernRoute;
    private web3: any;
    private seaport: OpenSeaPort;
    private referrerAddress = env.referrerAddress;
    /**
     * Use to fake logout user from NFT Pangolin website.
     * The trick is similar to OpenSea logout behaviour.
     */
    private isFakeLogout: boolean = false;

    constructor(
        private http: HttpClient,
        private localStorage: StorageService,
    ) {
        if (window.ethereum) {
            this.web3 = new Web3(window.ethereum);
            this.seaport = new OpenSeaPort(this.web3.currentProvider, {
                networkName: env.production ? Network.Main : Network.Rinkeby,
                apiKey: env.openSeaApiKey,
            });

            window.ethereum.on('accountsChanged',
                accounts => {
                    this.localStorage.walletAddress = null;
                    if (accounts.length > 0) {
                        this.localStorage.walletAddress = accounts[0];
                    }
                    window.location.reload();
                }
            );
        }
    }

    fakeLogout(): void {
        window.ethereum = null;
        this.isFakeLogout = true;
    }

    getETHPrice(): Observable<any> {
        return this.http.get<any>("https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD")
            .pipe(map(result => {
                return result;
            }));
    }

    async connectMetamask() {
        if (window.ethereum) {
            await window.ethereum.send('eth_requestAccounts');
            this.web3 = new Web3(window.ethereum);
            this.localStorage.walletAddress = this.web3.eth.accounts[0];
            return true;
        }
        else {
            if (this.isFakeLogout === true)  {
                alert('Please refresh the page to connect your wallet.');
                return;
            }

            alert('You need an Ethereum wallet to interact with this marketplace. Unlock your wallet, get MetaMask.io');

            return false;
        }
    }

    hasAccess(): any {
        return this.getAccount().then(response => {
            if (response.result.length == 0) {
                return false;
            }
            else {
                return true;
            }
        })
    }

    async getAccount() {
        if (window.ethereum) {
            return await window.ethereum.send('eth_accounts');
        }
    }

    async isMainnet() {
        if (window.ethereum) {
            return await window.ethereum.request({ method: 'net_version' });
        }
    }

    getWalletAddress() {
        return this.localStorage.walletAddress;
    }

    async getAssets(params: any) {
        return await this.seaport.api.getAssets(params);
    }

    async getAsset(params: any) {
        return await this.seaport.api.getAsset(params);
    }

    getAssetsHttp(params: any): Observable<any> {
        const headers = {
            'X-API-KEY': env.openSeaApiKey,
        };

        return this.http.get<any>(`${this.apiRoute}assets`, { headers: headers, params: params })
            .pipe(map(result => {
                return result;
            }));
    }

    getAssetHttp(params: any): Observable<any> {
        const headers = {
            'X-API-KEY': env.openSeaApiKey,
        };

        return this.http.get<any>(`${this.apiRoute}asset/${params.tokenAddress}/${params.tokenId}`, { headers: headers })
            .pipe(map(result => {
                return result;
            }));
    }

    getAssetListingsHttp(params: any): Observable<any> {
        const headers = {
            'X-API-KEY': env.openSeaApiKey,
        };

        return this.http.get<any>(`${this.apiRoute}asset/${params.tokenAddress}/${params.tokenId}/listings`, { headers: headers })
            .pipe(map(result => {
                return result;
            }));
    }

    getAssetOffersHttp(params: any): Observable<any> {
        const headers = {
            'X-API-KEY': env.openSeaApiKey,
        };

        return this.http.get<any>(`${this.apiRoute}asset/${params.tokenAddress}/${params.tokenId}/offers`, { headers: headers })
            .pipe(map(result => {
                return result;
            }));
    }

    getEvents(params: any): Observable<any> {
        const headers = {
            'X-API-KEY': env.openSeaApiKey,
        };

        return this.http.get<any>(this.apiRoute + 'events', { headers: headers, params: params })
            .pipe(map(result => {
                if (result.asset_events) {
                    let assetEvents = result.asset_events;

                    assetEvents = result.asset_events.filter(e => e.auction_type != 'dutch');

                    return {
                        data: assetEvents.map(model => new OpenSeaEvent(model)),
                        next_page: result.next_page,
                        previous_page: result.previous_page
                    }
                } else {
                    return {
                        data: []
                    }
                }
            }));
    }

    getUserOffers(params: any): Observable<any> {
        return this.http.get(this.wyvernRoute + 'orders', { params: params })
            .pipe(map(result => {
                return result;
            }));
    }

    getBalance(): Promise<number> {
        return new Promise((resolve, reject) => {
            this.seaport.web3.eth.getBalance(this.localStorage.walletAddress, (err, balance) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(this.seaport.web3.fromWei(balance.toNumber(), "ether"))
                }
            });
        })
    }

    getWethBalance(): Promise<string> {
        let minABI = [
            {
                "constant": true,
                "inputs": [{ "name": "_owner", "type": "address" }],
                "name": "balanceOf",
                "outputs": [{ "name": "balance", "type": "uint256" }],
                "type": "function"
            },
            {
                "constant": true,
                "inputs": [],
                "name": "decimals",
                "outputs": [{ "name": "", "type": "uint8" }],
                "type": "function"
            }
        ];
        return new Promise((resolve, reject) => {
            let contract = this.seaport.web3.eth.contract(minABI).at(env.mainnWETHAddress);
            contract.balanceOf(this.localStorage.walletAddress, (error, balance) => {
                if (error) {
                    reject(error);
                }
                else {
                    contract.decimals((err, decimals) => {
                        // calculate a balance
                        if (err) {
                            reject(err);
                        } else {
                            balance = balance.div(10 ** decimals);
                            resolve(balance.toString());
                        }
                    });
                }
            });
        })
    }

    async createBuyOrder(param: any) {
        return await this.seaport.createBuyOrder(param);
    }

    async checkout(assetContractAddress: string, tokenId: string, side: OrderSide, order?: Order) {
        let targetOrder: Order;

        if (order)
            targetOrder = order;
        else {
            await this._getOrder(assetContractAddress, tokenId, side).then(result => {
                targetOrder = result;
            }).catch(err => {
                console.log(err);
            })
        }

        return await this.seaport.fulfillOrder({ order: targetOrder, accountAddress: this.localStorage.walletAddress, referrerAddress: this.referrerAddress });
    }

    async cancelOrAcceptOrder(assetContractAddress: string, tokenId: string, side: OrderSide, offerOrder: any, isCancel: boolean) {
        let isGetOrdersError: boolean = false;
        let targetOrder: Order[];

        await this._getOrders(assetContractAddress, tokenId, side).then(result => {
            targetOrder = result.orders;
        }).catch(err => {
            console.error(err);
            isGetOrdersError = true;
        });

        if (isGetOrdersError) {
            throw Error('Connection too busy, please try again in few minutes.');
        }

        let foundOrder = targetOrder.find(order => {
            if (offerOrder.r !== order.r || offerOrder.s !== order.s) {
                return false;
            }

            if (offerOrder.maker.address !== order.maker) {
                return false;
            }

            return true;
        });
        if (!foundOrder) {
            throw Error('Order not found, please contact support.');
        }

        let param: any = {
            accountAddress: this.localStorage.walletAddress,
            order: foundOrder,
        }

        if (isCancel)
            return await this.seaport.cancelOrder(param);
        else {
            param.referrerAddress = this.referrerAddress;
            return await this.seaport.fulfillOrder(param);
        }

    }

    async _getOrder(assetContractAddress: string, tokenId: string, side: OrderSide) {
        return await this.seaport.api.getOrder({
            asset_contract_address: assetContractAddress,
            token_id: tokenId,
            side: side
        });
    }

    async _getOrders(assetContractAddress: string, tokenId: string, side: OrderSide) {
        return await this.seaport.api.getOrders({
            asset_contract_address: assetContractAddress,
            token_id: tokenId,
            side: side
        });
    }
}
