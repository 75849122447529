import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment as env } from '../../../../environments/environment';

@Component({
    selector: 'agmo-convert-currency-popup-modal',
    templateUrl: './convert-currency-popup.component.html',
    styleUrls: ['./convert-currency-popup.component.scss']
})
export class ConvertCurrencyPopup implements OnInit {
    consoleList: string[];
    uniswapSrc: string;

    constructor(
        public activeModal: NgbActiveModal,
        public modalService: NgbModal,
    ) { }

    ngOnInit(): void {
        let uniswapURL = 'https://app.uniswap.org/#/swap?use=v1?';
        this.uniswapSrc = `${uniswapURL}outputCurrency=${env.mainnWETHAddress}&theme=light&exactField=output`;
    }

    close() {
        this.activeModal.close();
    }
}
