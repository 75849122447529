import { Celebrity, FeaturedCelebrity } from '../../core/models/celebrity';
import { Component, OnInit } from '@angular/core';
import { Dropdown } from '../../core/models/dropdown';
import { FILTER_ALL_LABEL, FILTER_FEATURED_LABEL } from 'src/app/core/constants';
import { FirebaseService } from '../../core/services/firebase.service';
import { SwiperConfigInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';

@Component({
    selector: 'app-landing-page',
    templateUrl: './landing-page.component.html',
    styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
    categories: Dropdown[] = [];
    celebrities: Celebrity[] = [];
    featuredCelebrities: FeaturedCelebrity[] = [];
    isWelcomeDescriptionShrink: boolean = true;
    selectedCategory = FILTER_ALL_LABEL;
    selectedCategoryName = FILTER_ALL_LABEL;

    private pagination: SwiperPaginationInterface = {
        el: '.swiper-pagination',
        clickable: true
    };

    celebrityConfig: SwiperConfigInterface = {
        direction: 'horizontal',
        keyboard: true,
        loop: true,
        slidesPerView: 1,
        pagination: this.pagination
    };

    constructor(
        private firebaseService: FirebaseService,
    ) { }

    ngOnInit(): void {
        this.getFeaturedCelebrityListing();
        this.getCelebrityListing();
        this.getCelebrityCategory();
    }

    filterListing(categoryValue: string) {
        this.selectedCategory = categoryValue;

        this.onMobileFilterChange();
    }

    getFeaturedCelebrityListing() {
        this.firebaseService.getFeaturedCelebrities().subscribe(response => {
            this.featuredCelebrities = response.filter(feaCel => feaCel.featuredNftAsset);

            this.featuredCelebrities.forEach(celebrity => {
                this.firebaseService.getImageUrl(celebrity.profilePicture).subscribe(url => {
                    celebrity.profilePictureUrl = url;
                });
            })
        });
    }

    onMobileFilterChange(): void {
        this.selectedCategoryName = this.categories.find(category => category.value == this.selectedCategory)?.name || '-';

        if (this.selectedCategory === FILTER_FEATURED_LABEL) {
            this.getFeaturedCelebrities();
            return;
        }

        this.getCelebrityListing();
    }

    onReadMoreClick(): void {
        this.isWelcomeDescriptionShrink = !this.isWelcomeDescriptionShrink;
    }

    private getCelebrityCategory(): void {
        this.firebaseService.getCelebrityCategory().subscribe(
            response => {
                this.categories = response;
                this.categories.unshift({ name: FILTER_FEATURED_LABEL, value: FILTER_FEATURED_LABEL });
                this.categories.unshift({ name: FILTER_ALL_LABEL, value: FILTER_ALL_LABEL });
            }
        );
    }

    private getCelebrityListing(): void {
        this.firebaseService.getCelebrityListingByCategoryNew(this.selectedCategory).subscribe(response => {
            this.celebrities = response.filter(celebrity => celebrity.assets?.length > 0);

            this.celebrities.forEach(celebrity => {
                this.firebaseService.getImageUrl(celebrity.profilePicture).subscribe(url => {
                    celebrity.profilePictureUrl = url;
                });
            })
        });
    }

    private getFeaturedCelebrities(): void {
        this.firebaseService.getFeaturedCelebrities().subscribe(
            response => {
                this.celebrities = response.filter(feaCel => feaCel.assets.length > 0);

                this.celebrities.forEach(
                    celebrity => {
                        this.firebaseService.getImageUrl(celebrity.profilePicture).subscribe(
                            url => {
                                celebrity.profilePictureUrl = url;
                            }
                        );
                    }
                )
            }
        );
    }
}
