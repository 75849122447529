export enum OpenSeaAssetEventType {
    All = 'all',
    List = "created",
    Sale = "successful",
    Cancel = "cancelled",
    Offer = "offer_entered",
    Bid = "bid_entered",
    BidCancelled = "bid_withdrawn",
    Transfer = "transfer",
}

export enum NftSaleMode {
    Bidding,
    FixedPrice,
}
