<div class="modal-dialog">
    <div class="modal-content">
        <div class="modal-header">
            <h2 class="modal-title">
                <span *ngIf="modalType == 0">Make an Offer</span>
                <span *ngIf="modalType == 1">Place a bid</span>
                <span *ngIf="modalType == 2">Checkout</span>
            </h2>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
                <div class="icon icon-close"></div>
            </button>
        </div>
        <div class="modal-body">
            <ng-container *ngIf="!transactionLoading && !transactionComplete">
                <ng-container *ngIf="!isConvertPage && !isDepositPage">
                    <ng-container *ngIf="modalType != 2">
                        <form [formGroup]="form" [ngClass]="{'show-form-error': showFormError}">
                            <div class="row my-2 mx-1 popup-content">
                                <div class="col-12">
                                    You are about to {{modalType == 0 ? 'make an offer' : 'place a bid'}} on <span class="white-text">{{asset.name}}</span> by <span class="white-text">{{celebrityName}}</span>
                                </div>
                                <div class="col-12 pt-2">
                                    <div class="d-flex justify-content-between">
                                        <div class="white-text">Price</div>
                                        <div *ngIf="!isLoading" class="balance">${{price.value * usdPerETH | number}}</div>
                                    </div>
                                    <div>
                                        <input class="form-control" formControlName="Price" type="number" placeholder="Amount (WETH)" />
                                    </div>
                                </div>
                                <div class="col-12 pt-3">
                                    <div class="white-text">Offer Expiration</div>
                                    <div class="d-flex">
                                        <mat-form-field class="form-control px-2 expiration-dropdown">
                                            <mat-select disableOptionCentering msInfiniteScroll
                                                        #select="matSelect" panelClass="app-dropdown-panel"
                                                        [(ngModel)]="selectedOption"
                                                        [ngModelOptions]="{standalone: true}">
                                                <mat-option *ngFor="let option of expirationOption"
                                                            [value]="option.value">
                                                    {{option.name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <div *ngIf="selectedOption == 3">
                                            <mat-form-field class="form-control">
                                                <mat-label></mat-label>
                                                <input [min]="curDate" [(ngModel)]="expirationDate"
                                                       [ngModelOptions]="{standalone: true}" matInput readonly
                                                       [matDatepicker]="datePicker">
                                                <mat-datepicker-toggle matPrefix [for]="datePicker">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #datePicker disabled="false">
                                                </mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                        <input class="ml-2 form-control" type="time" [(ngModel)]="offerEndTime" [ngModelOptions]="{standalone: true}" />
                                    </div>
                                </div>
                                <div class="col-12 pt-3">
                                    <div class="d-flex justify-content-between">
                                        <div>Offer balance</div>
                                        <div *ngIf="!isLoading" class="white-text">{{availableWETH | number}} wETH</div>
                                    </div>
                                </div>
                                <div class="col-12 pt-2">
                                    <div class="d-flex justify-content-between">
                                        <div>Balance</div>
                                        <div *ngIf="!isLoading" class="white-text">{{availableETH | number}} ETH</div>
                                    </div>
                                </div>

                                <div class="col-12 py-4">
                                    <div class="w-100">
                                        <button (click)="processExpirationTime()" *ngIf="modalType == 0" type="button"
                                                [disabled]="availableWETH < currentUnitPrice || form.controls['Price'].value < currentUnitPrice || form.controls['Price'].value > availableWETH || form.controls['Price'].value == 0"
                                                class="btn btn-solid w-100">
                                            Make Offer
                                        </button>
                                        <button (click)="processExpirationTime()" *ngIf="modalType == 1" type="button"
                                                [disabled]="availableWETH < currentUnitPrice || form.controls['Price'].value < currentUnitPrice || form.controls['Price'].value > availableWETH || form.controls['Price'].value == 0"
                                                class="btn btn-solid w-100">
                                            Place Bid
                                        </button>
                                    </div>
                                    <div class="pt-2">
                                        <button (click)="toggleConvert()" class="btn btn-alt w-100">Convert ETH</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </ng-container>
                    <ng-container *ngIf="modalType == 2">
                        <div class="row my-2 mx-1 popup-content">
                            <div class="col-12">
                                You are about to purchase <span class="white-text">{{asset.name}}</span> by <span class="white-text">{{celebrityName}}</span>
                            </div>
                            <div class="col-12 py-2">
                                <div class="text-sm">Price</div>
                                <div class="gradient-text horizontal-two-color gradient-price">{{orderObject[0].currentUnitPrice | number}} ETH</div>
                                <div class="text-sm">
                                    ${{orderObject[0].currentUnitPrice * usdPerETH | number}}
                                </div>
                            </div>
                            <div class="col-12 pt-2">
                                <div class="d-flex justify-content-between">
                                    <div>Balance</div>
                                    <div *ngIf="!isLoading" class="white-text">{{availableETH | number}} ETH</div>
                                </div>
                            </div>
                            <div class="col-12 my-3">
                                <button [disabled]="availableETH < orderObject[0].currentUnitPrice" class="btn btn-solid w-100" (click)="checkout()">
                                    Proceed to payment
                                </button>
                                <button *ngIf="availableETH < orderObject[0].currentUnitPrice" class="btn btn-alt w-100 mt-3" (click)="isDepositPage = !isDepositPage">
                                    Add Funds
                                </button>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="isConvertPage">
                    <div class="row mx-3 mb-3 popup-content">
                        <div (click)="isConvertPage = !isConvertPage" class="c-pointer">&larr; Back</div>
                        <div class="col-12 text-center title">
                            Convert WETH
                        </div>
                        <div class="col-12 pt-2 text-center">
                            Easily convert between ETH and WETH
                        </div>

                        <iframe [src]="uniswapSrc | trustSrc: 'resourceUrl'" width="100%" height="660px"
                                style="
                border: 0;
                margin: 0 auto;
                display: block;
                border-radius: 10px;
                z-index: 1;"
                                id="uniswapId"></iframe>
                    </div>
                </ng-container>
                <ng-container *ngIf="isDepositPage">
                    <div class="row mx-3 mb-3 popup-content">
                        <div (click)="isDepositPage = !isDepositPage" class="c-pointer">&larr; Back</div>
                        <div class="col-12 text-center title">
                            Deposit WETH
                        </div>
                        <div class="col-12 pt-2 text-center">
                            Send ETH from your exchange or wallet to the following address.
                        </div>
                        <div class="col-12 pt-3">
                            <div class="pb-2">Your Wallet Address</div>
                            <div class="d-flex">
                                <input class="form-control input-copy" [ngModel]="walletAddress" type="text" readonly #formInputText />
                                <button [ngxClipboard]="formInputText" class="btn btn-copy">Copy</button>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="transactionLoading && !transactionComplete">
                <div class="row mx-3 processing popup-content">
                    <div class="app-loading">
                        <svg class="spinner" viewBox="0 0 100 100">
                            <defs>
                                <linearGradient id="Gradient" x1="50%" y1="0%" x2="50%" y2="100%">
                                    <stop offset="0%" stop-color="#FE1080">
                                        <animate attributeName="stop-color" values="#FE1080; #6B40FF; #FE1080" dur="4s" repeatCount="indefinite"></animate>
                                    </stop>

                                    <stop offset="100%" stop-color="#6B40FF">
                                        <animate attributeName="stop-color" values="#6B40FF; #FE1080; #6B40FF" dur="4s" repeatCount="indefinite"></animate>
                                    </stop>

                                </linearGradient>
                            </defs>
                            <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" />
                        </svg>
                    </div>
                    <div class="gray">To make an offer on this item for <span class="white"> wETH</span>, please <span class="white">"Confirm"</span> the transaction.</div>
                </div>
            </ng-container>
            <ng-container *ngIf="!transactionLoading && transactionComplete">
                <div class="row mx-3 processing popup-content">
                    <div class="row">
                        <img class="col-6 p-0" src="assets/img/svg-pangolin/FAQ-bg-left.svg" />
                        <img class="col-6 p-0" src="assets/img/svg-pangolin/FAQ-bg-right.svg" />
                    </div>
                    <div class="asset-image-wrapper">
                        <img [src]="asset.imageUrl" />
                    </div>
                    <div class="mt-4 mb-3">You've successfully {{modalType == 2 ? 'purchased' : 'made an offer on'}} <span class="text-white">{{asset.name}}</span> by <span class="text-white">{{celebrityName}}</span> for {{modalType == 2 ? orderObject[0].currentUnitPrice : price.value}} wETH</div>
                    <button *ngIf="modalType != 2" (click)="routePage(2)" class="w-100 btn btn-solid">View my offers</button>
                    <button *ngIf="modalType == 2" (click)="routePage(1)" class="w-100 btn btn-solid">View my collectibles</button>
                    <a class="pt-3" *ngIf="modalType == 2" target="_blank" href="{{etherscanEndpoint}}tx/{{transactionHash}}">
                        View on EtherScan
                        <div class="icon-etherscan"></div>
                    </a>
                </div>
            </ng-container>
        </div>
    </div>
</div>
