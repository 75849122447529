import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router, Route } from '@angular/router';

@Component({
    selector: 'agmo-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
    @Input() replace: { [key: string]: string };

    routes: Route[] = [];

    constructor(
        private route: ActivatedRoute) { }

    ngOnInit() {
        this.getRoutes();
    }

    getRoutes() {
        const routeConfig = this.route.snapshot.parent.routeConfig as any;
        const routes = routeConfig._loadedConfig.routes;
        let url = this.route.routeConfig.path;

        while (url) {
            const route = routes.find(r => r.path == url);

            if (route)
                this.routes.unshift(route);

            url = url.split('/').slice(0, -1).join('/');
        }
    }

    getRouteTitle(route: Route) {
        let title = route.data && route.data.title ? route.data.title : 'Page';

        if (this.replace) {
            Object.keys(this.replace).forEach(key => {
                title = title.replace(`{{${key}}}`, this.replace[key]);
            });
        }

        return title;
    }

    getRouteUrl(route: Route): string {
        const layers = this.route.routeConfig.path.split('/').length - route.path.split('/').length;
        return Array(layers).fill('..').join('/');
    }
}
