import { Routes } from '@angular/router';
import { LoginComponent } from '../../pages/login/login.component';
import { BrowseComponent } from '../../pages/browse/browse.component';
import { AssetDetailComponent } from '../../pages/asset-detail/asset-detail.component';
import { UserWalletComponent } from '../../pages/user/user-wallet/user-wallet.component';
import { UserActivityComponent } from '../../pages/user/user-activity/user-activity.component';
import { UserOffersComponent } from '../../pages/user/user-offers/user-offers.component';
import { CelebritiesComponent } from '../../pages/celebrities/celebrities.component';
import { CelebrityDetailComponent } from '../../pages/celebrity-detail/celebrity-detail.component';
import { LandingPageComponent } from '../../pages/landing-page/landing-page.component';
import { ProfileComponent } from '../../pages/profile/profile.component';
import { ContactUsComponent } from '../../pages/contact-us/contact-us.component';
import { GetStartedComponent } from 'src/app/pages/get-started/get-started.component';
import { SettingComponent } from '../../pages/setting/setting.component';
import { AboutUsComponent } from '../../pages/about-us/about-us.component';
import { FaqComponent } from '../../pages/faq/faq.component';
import { PrivacyPolicyComponent } from '../../pages/privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from '../../pages/terms-of-service/terms-of-service.component';

export const AdminLayoutRoutes: Routes = [
    { path: 'login', component: LoginComponent, data: { title: 'Login' } },
    { path: 'landing-page', component: LandingPageComponent, data: { title: 'Landing Page' } },
    { path: 'browse', component: BrowseComponent, data: { title: 'Browse' } },
    { path: 'browse/:tokenAddress/:tokenId', component: AssetDetailComponent, data: { title: 'Asset Detail' } },
    { path: 'user-wallet', component: UserWalletComponent, data: { title: 'User Wallet' } },
    { path: 'user-activity', component: UserActivityComponent, data: { title: 'User Activity' } },
    { path: 'user-offers', component: UserOffersComponent, data: { title: 'User Offers' } },
    { path: 'celebrities', component: CelebritiesComponent, data: { title: 'Celebrities' } },
    { path: 'celebrities/:categoryId', component: CelebritiesComponent, data: { title: 'Celebrities' } },
    { path: 'celebrities/celebrity-detail/:id', component: CelebrityDetailComponent, data: { title: 'Celebrity Detail' } },
    { path: 'profile', component: ProfileComponent, data: { title: 'Profile' } },
    { path: 'profile/:modalType', component: ProfileComponent, data: { title: 'Profile' } },
    { path: 'setting', component: SettingComponent, data: { title: 'Settings' } },
    { path: 'about-us', component: AboutUsComponent, data: { title: 'About Us' } },
    { path: 'contact-us', component: ContactUsComponent, data: { title: 'Contact Us' } },
    { path: 'how-to-get-started', component: GetStartedComponent, data: { title: 'How to Get Started' } },
    { path: 'faq', component: FaqComponent, data: { title: 'FAQ' } },
    { path: 'privacy-policy', component: PrivacyPolicyComponent, data: { title: 'Privacy Policy' } },
    { path: 'terms', component: TermsOfServiceComponent, data: { title: 'Terms of Service' } },
];

