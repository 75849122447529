import { Injectable } from "@angular/core";
import { NgbDateAdapter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { Moment } from "moment";
import * as moment from "moment";

@Injectable()
export class NgbDateMomentAdapter extends NgbDateAdapter<Moment> {
    private now = moment().hour(0).minute(0).second(0);
    private hour = this.now.hour();
    private minute = this.now.minute();
    private second = this.now.second();

    fromModel(momentDate: Moment): NgbDateStruct {
        if (!momentDate || !momentDate.isValid())
            return null;

        this.hour = momentDate.hour();
        this.minute = momentDate.minute();
        this.second = momentDate.second();

        return {
            day: momentDate.date(),
            month: momentDate.month() + 1,
            year: momentDate.year()
        }
    }

    toModel(ngbDate: NgbDateStruct): Moment {
        if (!ngbDate)
            return null;

        return moment(`${ngbDate.year}-${ngbDate.month}-${ngbDate.day} ${this.hour}:${this.minute}:${this.second}`, 'YYYY-M-D H:m:s');
    }
}
