import { Injectable } from '@angular/core';
import { AssetFilter } from './asset-filter';

@Injectable({
    providedIn: 'root',
})

export class AssetFilterService {
    filter: AssetFilter;
}
