import { Component, OnInit, ViewChild } from '@angular/core';
import { ImageCropperComponent, ImageCroppedEvent } from 'ngx-image-cropper';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'agmo-image-cropper',
    templateUrl: './image-cropper.component.html',
    styleUrls: ['./image-cropper.component.scss']
})
export class AgmoImageCropperComponent implements OnInit {
    @ViewChild(ImageCropperComponent, { static: false }) imageCropper: ImageCropperComponent;
    imageFileChanged: Blob;
    message: string;

    croppedImage: any = '';
    
    constructor(
        private activeModal: NgbActiveModal) { }

    ngOnInit() { }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
    }

    ok() {
        const promise = <Promise<ImageCroppedEvent>>this.imageCropper.crop();

        promise.then(event => {
            this.activeModal.close(event.file);
        }, () => { });
    }

    cancel() {
        this.activeModal.dismiss();
    }

    
}
