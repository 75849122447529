import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";


@Pipe({ name: 'trustSrc' })
export class TrustSrcPipe implements PipeTransform {
    constructor(private domSanitizer: DomSanitizer) { }

    transform(src: string, type = 'html'): SafeHtml {
        if (!src)
            return null;

        console.log(src);
        switch (type) {
            case 'html': return this.domSanitizer.bypassSecurityTrustHtml(src);
            case 'style': return this.domSanitizer.bypassSecurityTrustStyle(src);
            case 'script': return this.domSanitizer.bypassSecurityTrustScript(src);
            case 'url': return this.domSanitizer.bypassSecurityTrustUrl(src);
            case 'resourceUrl': return this.domSanitizer.bypassSecurityTrustResourceUrl(src);
            default: throw new Error(`Invalid safe type specified: ${type}`);
        }
    }
}
