import { Pipe, PipeTransform } from "@angular/core";
import * as linkifyStr from 'linkifyjs/string';

@Pipe({ name: 'linkify' })
export class LinkifyPipe implements PipeTransform {
    transform(input: string): string {
        if (!input)
            return "";

        return linkifyStr(input, { target: '_blank', validate: this.validate });
    }

    // used to add exceptions
    validate(value: string) {
        return value.toLowerCase() != "d.app";
    }
}
