import { Component, NgZone, Input } from '@angular/core';
import { environment as env } from '../../../../environments/environment';
import { OpenSeaAssetEventType } from '../../../core/enum/asset/opensea-asset-event-enum';
import { OpenSeaEvent } from '../../../core/models/opensea/opensea-event';
import { OpenseaService } from '../../../core/services/opensea.service';
import { BlockUiService } from '../../../shared/services/blockUi/block-ui.service';
import { AssetEventsFilter } from '../../asset-detail/asset-events-filter';
import { ToastrService } from 'ngx-toastr';
import { isNullOrUndefined } from 'util';
import { AssetFilter } from '../../browse/asset-filter';
import { BasePageComponent } from 'src/app/pages/base-page/base-page.component';

@Component({
    selector: 'app-user-activity-v2',
    templateUrl: './user-activity-v2.component.html',
    styleUrls: ['./user-activity-v2.component.scss']
})
export class UserActivityV2Component extends BasePageComponent {
    hasAccess = false;
    walletAddress = this.openseaService.getWalletAddress();
    etherscanEndpoint = env.etherscanEndpoint;

    loadTrading = false;
    isOpenFilter = false;

    tradingHistory: OpenSeaEvent[] = [];
    tradingHistoryFilter: AssetEventsFilter;
    tradingFilterOption = [
        { name: 'All', value: OpenSeaAssetEventType.All },
        { name: 'Purchases', value: OpenSeaAssetEventType.Sale },
        { name: 'Offers', value: OpenSeaAssetEventType.Offer },
        { name: 'Transfers', value: OpenSeaAssetEventType.Transfer },
    ];
    selectedOption = OpenSeaAssetEventType.All;
    selectedTab = 0;
    selectedFilter = 'All';
    haveMore = true;
    @Input() params: AssetFilter;

    constructor(
        protected openseaService: OpenseaService,
        protected blockUiService: BlockUiService,
        protected ngZone: NgZone,
        private toastr: ToastrService
    ) {
        super(openseaService, ngZone, blockUiService);
    }

    getTradingHistory(reload?: boolean) {
        this.loadTrading = true;

        if (reload) {
            this.tradingHistory = [];
            this.haveMore = true;
        }

        this.openseaService.getEvents(this.tradingHistoryFilter).subscribe(response => {
            this.tradingHistory = this.tradingHistory.concat(response.data);

            if (!response.next_page) {
                this.haveMore = false;
            }

        }, err => {
            console.log(err)
            if (err) {
                if (err.detail) {
                    console.log(err.detail);
                }
                else {
                    console.log(err);
                }
            }
        }).add(() => {
            this.loadTrading = false;
        });
    }

    getMore() {
        this.getTradingHistory(false);
    }

    proccessTradingFilter() {
        if (this.selectedOption == OpenSeaAssetEventType.All) {
            this.tradingHistoryFilter = new AssetEventsFilter();
            this.tradingHistoryFilter.account_address = this.walletAddress;
        }
        else {
            this.tradingHistoryFilter.event_type = this.selectedOption;
        }

        this.getTradingHistory(true);
    }

    getTradingEventName(value: any) {
        for (let enumMember in OpenSeaAssetEventType) {
            if (value == OpenSeaAssetEventType[enumMember])
                return enumMember;
        }
    }

    isVideo(src: string) {
        let extension = src.split('.').pop();

        if (extension == ('mp4' || 'webm')) {
            return true;
        }
        else return false;
    }

    truncateMiddleText(str: string) {
        return str.substr(0, 6) + '...' + str.substr(str.length - 4, str.length)
    }

    promptToaster() {
        this.toastr.success('Wallet Address is Copied');
    }

    filterListing(selectedTab?: number) {
        if (!isNullOrUndefined(selectedTab))
            this.selectedTab = selectedTab;

        if (selectedTab === 1) {
            this.selectedOption = OpenSeaAssetEventType.Sale;

        } else if (selectedTab === 2) {
            this.selectedOption = OpenSeaAssetEventType.Offer;
        }
        else if (selectedTab === 3) {
            this.selectedOption = OpenSeaAssetEventType.Transfer;
        }
        else {
            this.selectedOption = OpenSeaAssetEventType.All;
        }

        if (this.isOpenFilter) {
            this.isOpenFilter = false;
        }

        this.proccessTradingFilter();
    }

    protected afterValidNetworkOrChain(): void {
        super.afterValidNetworkOrChain();
        this.proccessTradingFilter();
    }

}
