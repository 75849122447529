import { FaqCategoryModel } from './faq-category-model';

export class FaqCategory {
    id: string;
    name: string;
    priority: number;

    constructor(model: FaqCategoryModel) {
        this.name = model.name;
        this.priority = model.priority;
    }
}
