import { OpenSeaAssetEventType } from '../../core/enum/asset/opensea-asset-event-enum';
import { environment as env } from '../../../environments/environment';

export class AssetEventsFilter {
    
    asset_contract_address: string;
    token_id: any;
    occurred_before: number; 
    occurred_after: number;
    event_type: OpenSeaAssetEventType;
    account_address: string;
    collection_slug: string;

    constructor() {
        this.collection_slug = env.openSeaCollection;
    }
}
