import { Injectable } from '@angular/core';
import { OrderFilter } from './order-filter';

@Injectable({
    providedIn: 'root',
})

export class OrderFilterService {
    filter: OrderFilter;
}
