import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SelectModel } from './select-model';
import { MatSelectChange } from '@angular/material/select';

@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent<T> implements OnInit {

    @Input() dropdownTitle: string;
    @Input() options: SelectModel<T>[];
    @Input() value: any;
    @Input() isGradient: boolean = false;
    @Output() callback = new EventEmitter<T>();
    @Output() valueChange = new EventEmitter<T>();

    constructor() { }

    ngOnInit(): void { }

    update(event: MatSelectChange) {
        this.valueChange.emit(event.value);
        this.callback.emit(event.value);
    }
}
