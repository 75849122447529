import { Moment } from "moment";
import * as moment from "moment";

export class BaseClass {
    toMoment(date: string | Date): Moment {
        return (date && moment(date).isValid())
            ? moment(date)
            : null;
    }
}
