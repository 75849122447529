import { Directive, Input, ElementRef } from '@angular/core';

@Directive({
    selector: '[agmoAspectRatio]',
    host: {
        '[style.height]': 'calculateHeight()'
    }
})
export class ImageAspectRatioDirective {
    @Input() agmoAspectRatio: number;

    constructor(
        private el: ElementRef) { }

    calculateHeight() {
        const width = this.el.nativeElement.clientWidth;
        this.el.nativeElement.style.height = width / this.agmoAspectRatio + "px";
    }
}
