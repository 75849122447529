import { OpenSeaAssetContract, Order, OpenSeaCollection, AssetEvent, OpenSeaAccount, OpenSeaAsset, OrderSide } from 'opensea-js/lib/types';
import { NftSaleMode } from 'src/app/core/enum/asset/opensea-asset-event-enum';

// Note: this is a custom asset type, exluded transferFee and transferFeePaymentToken
export class Asset {
    animationOriginalUrl: string;
    id: number;
    assetContract: any;
    backgroundColor: string;
    buyOrders: Array<any>;
    collection: OpenSeaCollection;
    creator?: OpenSeaAccount;
    description: string;
    externalLink: string;
    imagePreviewUrl: string;
    imageUrl: string;
    imageUrlOriginal: string;
    imageUrlThumbnail: string;
    isPresale: boolean;
    lastSale: AssetEvent;
    name: string;
    numSales: number;
    openseaLink: string;
    orders: Order[];
    owner: OpenSeaAccount;
    topOwner?: OpenSeaAccount;
    sellOrders: Array<any>;
    tokenAddress: string;
    tokenId: string;
    traits: Array<any>;
    nftSaleMode: NftSaleMode;
    is3dModel: boolean = false;

    constructor(model: any) {
        this.animationOriginalUrl = model.animation_original_url;
        this.id = model.id;
        this.assetContract = model.asset_contract;
        this.backgroundColor = model.background_color;
        this.buyOrders = model.orders? model.orders.filter(e => e.side == OrderSide.Buy) : [];
        this.collection = model.collection;
        this.creator = model.creator;
        this.description = model.description;
        this.externalLink = model.external_link;
        this.imagePreviewUrl = model.image_preview_url;
        this.imageUrl = model.image_url;
        this.imageUrlOriginal = model.image_original_url;
        this.imageUrlThumbnail = model.image_thumbnail_url;
        this.isPresale = model.is_presale;
        this.lastSale = model.last_sale;
        this.name = model.name;
        this.numSales = model.num_sales;
        this.openseaLink = model.opensea_link;
        this.orders = model.orders;
        this.owner = model.owner;
        this.topOwner = model.top_ownerships ? model.top_ownerships[0].owner : null;
        this.tokenAddress = model.asset_contract.address;
        this.tokenId = model.token_id;
        this.traits = model.traits;

        if (model.sell_orders) {
            this.sellOrders = model.sell_orders;
        } else if (model.orders) {
            this.sellOrders = model.orders.filter(e => e.side == OrderSide.Sell);
        } else {
            this.sellOrders = [];
        }

        this.processSaleMode();
        this.process3dModel();
    }

    private processSaleMode(): void {
        this.nftSaleMode = NftSaleMode.Bidding;

        this.traits.forEach(
            trait => {
                if (trait.trait_type.toLowerCase() === 'sale_mode') {
                    if (trait.value.toLowerCase() === 'fixed') {
                        this.nftSaleMode = NftSaleMode.FixedPrice;
                    }
                }
            }
        );
    }

    private process3dModel(): void {
        if (!this.animationOriginalUrl) {
            return;
        }

        const animationUrl: string = this.animationOriginalUrl.toLocaleLowerCase();

        if (animationUrl.indexOf('.glb') > -1 || animationUrl.indexOf('.gltf') > -1) {
            this.is3dModel = true;
        }
    }
}
