<div class="float-left d-none d-md-block">
    <img src="assets/img/svg-pangolin/Contact-us-bg-design-left.svg"/>
</div>
<div class="float-right d-none d-md-block">
    <img src="assets/img/svg-pangolin/Contact-us-bg-design-right.svg"/>
</div>

<div class="container-fluid">
    <div class="row form-wrapper">
        <div class="text-center">
            <div class="gradient-text horizontal contact-title">Contact Us</div>
            <div>Please feel up this form and we'll get rolling with you soon!</div>
        </div>

        <form [formGroup]="form" [class.show-form-error]="showFormError">
            <div class="row">
                <div class="col-12 pt-3">
                    <div class="text-white pb-1">Name</div>
                    <input class="form-control" placeholder="Enter your name" type="text" formControlName="name" maxlength="100" />
                    <div class="error-msg" *ngIf="name.invalid && name.touched">Only allowed Alphanumeric, Space and symbol ` @ ( ) / ' , . -</div>
                </div>
                <div class="col-12 pt-3">
                    <div class="text-white pb-1">Email</div>
                    <input class="form-control" placeholder="Enter your email" type="text" formControlName="email" maxlength="50" />
                    <div *ngIf="email.invalid && email.touched">
                        <div class="error-msg">
                            {{email.value ? 'This is not a valid email address' : 'Email is required'}}
                        </div>
                    </div>
                </div>
                <div class="col-12 pt-3">
                    <div class="text-white pb-1">I'm interested for</div>
                    <mat-form-field class="form-control px-2">
                        <mat-select disableOptionCentering msInfiniteScroll panelClass="app-dropdown-panel" formControlName="question">
                            <mat-option *ngFor="let cfQuestion of contactFormQuestions; let i = index" [value]="i">
                                {{cfQuestion.question}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 pt-3">
                    <div class="text-white pb-1">Title</div>
                    <input class="form-control" placeholder="Enter title" type="text" formControlName="title" maxlength="50" />
                    <div class="error-msg" *ngIf="title.invalid && title.touched">6 to 50 characters, only allowed Alphabets.</div>
                </div>
                <div class="col-12 pt-3">
                    <div class="text-white pb-1">Message</div>
                    <textarea rows="5" class="form-control" placeholder="Enter message" formControlName="message" maxlength="999"></textarea>
                    <div class="error-msg" *ngIf="message.invalid && showFormError">Message is required</div>
                </div>
            </div>
        </form>
        <button class="btn btn-solid w-100 mt-3" [disabled]="form.invalid" (click)="submit()">Send message</button>
    </div>
</div>
