<div class="r2icon-close" (click)="close()">
        <i class="fas fa-times-circle"></i>
</div>

<ngb-carousel *ngIf="isArray" [activeId]="activeId"
              [showNavigationArrows]="data.length > 1"
              [showNavigationIndicators]="data.length > 1">

    <ng-template ngbSlide *ngFor="let photo of data" [id]="photo.id">
        <div class="photo">
            <img [src]="photo.imageUrl" />
        </div>
    </ng-template>
</ngb-carousel>

<img [src]="data.imageUrl" *ngIf="data.imageUrl" />
<video [src]="data.mp4Url"  controlsList="nodownload" playsinline autoplay muted loop controls *ngIf="data.mp4Url"></video>
