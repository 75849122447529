import { Injectable } from '@angular/core';
import { AngularFirestore, CollectionReference } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { Celebrity, FeaturedCelebrity } from '../models/celebrity';
import { CelebrityCategory } from '../models/celebrity-category';
import { CelebrityCategoryModel } from '../models/celebrity-category-model';
import { CelebrityModel, FeaturedCelebrityModel } from '../models/celebrity-model';
import { Dropdown } from '../models/dropdown';
import * as firebase from 'firebase/app';
import { FaqCategory } from '../models/faq-category';
import { FaqCategoryModel } from '../models/faq-category-model';
import { Faq } from '../models/faq';
import { FaqModel } from '../models/faq-model';
import { ContactForm, ContactFormQuestion } from '../models/contact-form';
import { User } from '../models/user';
import { UserModel } from '../models/user-model';
import { AngularFireAuth } from '@angular/fire/auth';
import { Campaign, CampaignUser } from 'src/app/core/models/heyjom/heyjom.model';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class FirebaseService {

    constructor(
        private firestore: AngularFirestore,
        private fireStorage: AngularFireStorage,
        private afAuth: AngularFireAuth
    ) {
        this.afAuth.authState.subscribe(
            (user) => {
                if (user) {
                    return;
                }

                this.afAuth.signInWithEmailAndPassword(environment.firebaseAccount, environment.firebasePassword);
            }
        );
    }

    getFeaturedCelebrities(): Observable<FeaturedCelebrity[]> {
        let query = (ref: CollectionReference) => ref.where('is_featured', '==', true).orderBy('featured_order');

        return this.firestore.collection('/celebrities', query).get().pipe(
            map(
                result => {
                    let featuredCelebrities: FeaturedCelebrity[] = [];
                    result.forEach(
                        featuredCelebritySnapshot => {
                            let featuredCelebrity = new FeaturedCelebrity(featuredCelebritySnapshot.data() as FeaturedCelebrityModel);
                            featuredCelebrity.id = featuredCelebritySnapshot.id;
                            featuredCelebrities.push(featuredCelebrity);
                        }
                    )

                    return featuredCelebrities;
                }
            )
        );
    }

    /**
     * @deprecated
     * @returns
     */
    getFeaturedCelebrityListing(): Observable<Celebrity[]> {
        let query = (ref: CollectionReference) => ref.where('is_featured', '==', true);

        return this.firestore.collection('/celebrities', query)
            .snapshotChanges().pipe(map(result => {
                return result.map(snap => {
                    let model = snap.payload.doc.data() as CelebrityModel;
                    let celebrity = new Celebrity(model);
                    celebrity.id = snap.payload.doc.id;
                    return celebrity;
                })
            }))
    }

    getCelebrityListingByCategoryNew(filterBy: string): Observable<Celebrity[]> {
        let query = filterBy == "All" ? undefined :
            (ref: CollectionReference) => ref.where('category', "array-contains", this.firestore.collection('celebrity_categories').doc(filterBy).ref);

        return this.firestore.collection('/celebrities', query).get().pipe(
            map(
                result => {
                    let celebrities: Celebrity[] = [];
                    result.forEach(
                        celebritySnapshot => {
                            let celebrity = new Celebrity(celebritySnapshot.data() as CelebrityModel);
                            celebrity.id = celebritySnapshot.id;
                            celebrities.push(celebrity);
                        }
                    );

                    return celebrities;
                }
            )
        );
    }

    /**
     * @deprecated Use getCelebrityListingByCategoryNew instead.
     * @param filterBy
     * @returns
     */
    getCelebrityListingByCategory(filterBy: string): Observable<Celebrity[]> {
        let query = filterBy == "All" ? undefined :
            (ref: CollectionReference) => ref.where('category', "array-contains", this.firestore.collection('celebrity_categories').doc(filterBy).ref);

        return this.firestore.collection('/celebrities', query)
            .snapshotChanges().pipe(map(result => {
                return result.map(snap => {
                    let model = snap.payload.doc.data() as CelebrityModel;
                    let celebrity = new Celebrity(model);
                    celebrity.id = snap.payload.doc.id;
                    return celebrity;
                })
            }))
    }

    getCelebrityListingBySearch(searchText: string): Observable<Celebrity[]> {
        return this.firestore.collection('/celebrities',
            ref => ref.where('name', ">=", searchText).where('name', "<", searchText.substring(0, searchText.length - 1) + "\uf8ff")
        ).snapshotChanges().pipe(map(result => {
            return result.map(snap => {
                let model = snap.payload.doc.data() as CelebrityModel;
                let celebrity = new Celebrity(model);
                celebrity.id = snap.payload.doc.id;
                return celebrity;
            })
        }))
    }

    getCelebrityCategory(): Observable<Dropdown[]> {
        return this.firestore.collection('/celebrity_categories').snapshotChanges().pipe(map(result => {
            return result.map(snap => {
                let data = snap.payload.doc.data() as CelebrityCategoryModel;
                let category = new CelebrityCategory(data);
                category.id = snap.payload.doc.id;

                return {
                    name: category.name,
                    value: category.id
                }
            })
        }))
    }

    getCelebrity(celebrityId: string): Promise<Celebrity> {
        return this.firestore.collection('/celebrities').doc(celebrityId).ref.get().then(doc => {
            let celebrity = new Celebrity(doc.data() as CelebrityModel);
            let docData = doc.data() as any;
            let categoryCollections = docData.category as Array<any>;
            let categories = [];

            categoryCollections.map(category => {
                this.firestore.collection('/celebrity_categories').doc(category.id).ref.get().then(result => {
                    let category = result.data() as any;
                    categories.push(category.name);
                })
            });

            celebrity.category = categories;
            return celebrity;
        })
    }

    getCelebrityByAddress(walletAddress: string): Observable<Celebrity[]> {
        return this.firestore.collection('/celebrities',
            ref => ref.where('wallet_address', "==", walletAddress))
            .snapshotChanges().pipe(map(result => {
                return result.map(snap => {
                    let celebrity = new Celebrity(snap.payload.doc.data() as CelebrityModel);
                    let docData = snap.payload.doc.data() as any;
                    let categoryCollections = docData.category as Array<any>;
                    let categories = [];

                    categoryCollections.map(category => {
                        this.firestore.collection('/celebrity_categories').doc(category.id).ref.get().then(result => {
                            let category = result.data() as any;
                            categories.push(category.name);
                        })
                    });

                    celebrity.id = snap.payload.doc.id;
                    celebrity.category = categories;
                    return celebrity;
                })
            }))
    }

    createContactForm(body: ContactForm) {
        return new Promise<any>((resolve, reject) => {
            const newId = this.firestore.createId();
            this.firestore.collection('contact_forms').doc(newId).set({
                created_at: firebase.default.firestore.FieldValue.serverTimestamp(),
                email: body.email,
                message: body.message,
                name: body.name,
                subject: body.title,
                question: body.question,
            }).then(res => resolve(res), err => reject(err))
        })
    }

    getFaqCategory(): Observable<FaqCategory[]> {
        return this.firestore.collection('/faq_categories', ref => ref.orderBy('priority')).snapshotChanges().pipe(map(result => {
            return result.map(snap => {
                let data = snap.payload.doc.data() as FaqCategoryModel;
                let category = new FaqCategory(data);
                category.id = snap.payload.doc.id;

                return category;
            })
        }))
    }

    getFaqByCategory(category: string): Observable<Faq[]> {
        return this.firestore.collection('/faqs',
            ref => ref.where('category', "array-contains", this.firestore.collection('faq_categories').doc(category).ref).orderBy('order'))
            .snapshotChanges().pipe(map(result => {
                return result.map(snap => {
                    let model = snap.payload.doc.data() as FaqModel;
                    let faq = new Faq(model);
                    faq.id = snap.payload.doc.id;
                    return faq;
                })
            }))
    }

    getUser(walletAddress: string) {
        return this.firestore.collection('/users',
            ref => ref.where('wallet_address', "==", walletAddress))
            .get().pipe(map(result => {
                return result.docs.map(doc => {
                    let user = new User(doc.data() as UserModel);
                    user.docId = doc.id;
                    return user;
                })
            }))
    }

    checkIsUserExist(body: User) {
        const $one = this.firestore.collection('/users', ref => ref.where("wallet_address", "==", body.walletAddress))
            .get().pipe(map(result => {
                return result.docs.map(doc => {
                    let user = new User(doc.data() as UserModel);
                    user.docId = doc.id;
                    return user;
                })
            }));
        const $two = this.firestore.collection('/users', ref => ref.where("username", "==", body.username))
            .get().pipe(map(result => {
                return result.docs.map(doc => {
                    let user = new User(doc.data() as UserModel);
                    user.docId = doc.id;
                    return user;
                })
            }));
        const $three = this.firestore.collection('/users', ref => ref.where("email", "==", body.email))
            .get().pipe(map(result => {
                return result.docs.map(doc => {
                    let user = new User(doc.data() as UserModel);
                    user.docId = doc.id;
                    return user;
                })
            }));

        return combineLatest($one, $two, $three).pipe(
            map(([one, two, three]) => {
                return {
                    walletCheck: [...one],
                    usernameCheck: [...two],
                    emailCheck: [...three]
                }
            })
        )
    }

    updateUser(body: User): Promise<any> {
        const userRef = this.firestore.collection('users');
        return userRef.doc(body.docId).update({
            email: body.email,
            name: body.name,
            username: body.username
        });
    }

    async createUser(body: User): Promise<any> {
        try {
            const newUserCredential: firebase.default.auth.UserCredential = await this.afAuth.createUserWithEmailAndPassword(body.email, "abcd1234")

            const database = this.firestore.firestore;
            const batch = database.batch();

            const Collection = database.collection('users');
            const ref = Collection.doc(newUserCredential.user.uid)
            batch.set(ref, {
                uid: newUserCredential.user.uid,
                created_at: firebase.default.firestore.FieldValue.serverTimestamp(),
                email: body.email,
                name: body.name,
                username: body.username,
                wallet_address: body.walletAddress,
            });

            //const index = database.collection('index')
            //const usernameIndexRef = index.doc(`user/username/${body.username}`)
            //batch.set(usernameIndexRef, {
            //    value: ref.id
            //})

            //const emailIndexIndexRef = index.doc(`user/email/${body.email}`)
            //batch.set(emailIndexIndexRef, {
            //    value: ref.id
            //})

            //const walletIndexIndexRef = index.doc(`user/wallet_address/${body.walletAddress}`)
            //batch.set(walletIndexIndexRef, {
            //    value: ref.id
            //})

            await batch.commit();
        } catch (error) {
            throw error;
        }
    }

    //async updateUser(body: User) {
    //    try {
    //        const database = this.firestore.firestore;
    //        const batch = database.batch();

    //        const Collection = database.collection('users');
    //        const ref = Collection.doc(body.docId);
    //        const refDoc = await ref.get();
    //        const prevData = refDoc.data();
    //        batch.update(ref, {
    //            email: body.email,
    //            name: body.name,
    //            username: body.username
    //        })

    //        const index = database.collection('index')
    //        const prevIndexRef = index.doc(`users/username/${prevData.username}`)
    //        const indexRef = index.doc(`users/username/${body.username}`)
    //        batch.delete(prevIndexRef)
    //        batch.set(indexRef, {
    //            value: ref.id
    //        })

    //        const prevEmailIndexRef = index.doc(`users/email/${prevData.email}`)
    //        const emailIndexRef = index.doc(`users/email/${body.email}`)
    //        batch.delete(prevEmailIndexRef)
    //        batch.set(emailIndexRef, {
    //            value: ref.id
    //        })

    //        const prevWalletIndexRef = index.doc(`users/wallet_address/${prevData.wallet_address}`)
    //        const walletIndexRef = index.doc(`users/wallet_address/${body.walletAddress}`)
    //        batch.delete(prevWalletIndexRef)
    //        batch.set(walletIndexRef, {
    //            value: ref.id
    //        })
    //        await batch.commit();
    //    } catch (err) {
    //        throw err;
    //    }
    //}

    getImageUrl(locationUrl): Observable<any> {
        return this.fireStorage.ref(locationUrl).getDownloadURL();
    }

    getContactFormQuestion(): Observable<ContactFormQuestion[]> {
        return this.firestore.collection('/contact_form_questions', ref => ref.orderBy('order')).get().pipe(
            map(
                querySnapshot => {
                    return querySnapshot.docs.map(
                        doc => doc.data() as ContactFormQuestion
                    );
                }
            )
        );
    }

    sendEmailToDepartment(contactUsForm: ContactForm): Promise<void> {
        let newID: string = this.firestore.createId();
        let textBody: string = `${contactUsForm.message}\n\nfrom ${contactUsForm.name}\n[${contactUsForm.email}]`;

        return this.firestore.collection('contact_form_email_to_departments').doc(newID).set(
            {
                to: contactUsForm.supportEmail,
                message: {
                    subject: `${contactUsForm.question} - ${contactUsForm.title}`,
                    text: textBody,
                }
            }
        );
    }

    getCampaignLogo(): Observable<Campaign> {
        return this.firestore.collection('/campaign_details').get().pipe(
            map(
                result => {
                    return result.docs[0].data() as Campaign;
                }
            )
        );
    }

    createCampaignUser(user: CampaignUser): Promise<void> {
        return this.firestore.collection('campaign_users').doc(this.firestore.createId()).set(
            {
                accountID: user.accountID,
                email: user.email,
                fullname: user.fullname,
                walletAddress: user.walletAddress,
            }
        );
    }
}
