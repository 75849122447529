<div class="navigation-wrapper container-fluid">
    <nav class="nav-main">
        <ng-container *ngIf="!isSearch">
            <div class="nav-left">
                <a class="nav-brand c-pointer" (click)="routeToPage('/landing-page')">
                    <img class="logo d-none d-md-flex" src="/assets/img/svg-pangolin/Logo-NFTPangolin-horizontal.svg" />
                    <img class="logo d-md-none" src="/assets/img/svg-pangolin/Logo-NFTPangolin-vertical.svg" />
                    <span class="version-number">{{APP_VERSION}}</span>
                </a>
            </div>
            <div class="nav-search-wrapper d-none d-md-inline-block">
                <div class="input-group input-group-alternative">
                    <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fas fa-search"></i></span>
                    </div>
                    <input class="nav-input-class form-control" placeholder="Search by creator or collectible"
                           type="text" [formControl]="control" [matAutocomplete]="auto" #autoCompleteInput />
                    <mat-autocomplete #auto="matAutocomplete" class="autocomplete-drop" classList="autocomplete-drop">
                        <div class="option-body" *ngIf="celebritiesResults?.length > 0">
                            <div class="px-3 pt-3">Creators</div>
                            <mat-option *ngFor="let result of celebritiesResults" (click)="control.value == ''"
                                        [routerLink]="['/celebrities/celebrity-detail', result.id]">
                                <div class="d-flex">
                                    <div class="mr-3">
                                        <img class="celebrity-img" [src]="result.profilePicture" />
                                    </div>
                                    <div class="option-detail-column">
                                        <div class="asset-name">{{result.name}}</div>
                                        <div class="asset-creator">{{result.name}}</div>
                                    </div>
                                </div>
                            </mat-option>
                        </div>
                        <div class="option-body" *ngIf="collectiblesResults?.length > 0">
                            <div class="px-3 pt-3">Collectibles</div>
                            <mat-option *ngFor="let result of collectiblesResults" (click)="control.value == ''"
                                        [routerLink]="['/browse', result.tokenAddress, result.tokenId]">
                                <div class="d-flex">
                                    <div class="mr-3">
                                        <img class="collectible-img" [src]="result.imageUrl" />
                                    </div>
                                    <div class="option-detail-column">
                                        <div class="asset-name">{{result.name}}</div>
                                        <div class="asset-creator">by {{result.creator.user.username}}</div>
                                    </div>
                                </div>
                            </mat-option>
                        </div>
                        <div class="option-footer mx-3">
                            <a class="w-100 my-2 btn btn-alt" (click)="autoComplete.closePanel()"
                               [routerLink]="['/browse']" [queryParams]="{ searchText: control.value}">All results</a>
                        </div>
                    </mat-autocomplete>
                </div>
            </div>
            <ul class="nav-items d-none d-md-flex">
                <li>
                    <a class="item c-pointer" [routerLink]="['/celebrities/All']">Featured Talents</a>
                </li>
                <li>
                    <a class="item c-pointer" (click)="routeToPage('/browse')">Explore</a>
                </li>
                <li *ngIf="!isConnected" class="align-self-center">
                    <button class="btn btn-solid" (click)="connectWallet()">Connect Wallet</button>
                </li>
                <li *ngIf="isConnected" class="c-pointer nav-item align-self-center" ngbDropdown
                    placement="bottom-right">
                    <a class="nav-link pr-0" role="button" ngbDropdownToggle>
                        <div class="gradient-capsule">
                            <div>{{walletAddress}}</div>
                            <img class="right-img" src="/assets/img/svg-pangolin/Icon-profile-photo-64.svg" />
                        </div>
                    </a>
                    <div class="dropdown-menu-arrow dropdown-menu-right" ngbDropdownMenu>
                        <div class="dropdown-header noti-title">
                            <h2 class="header-text m-0">{{walletAddress}}</h2>
                        </div>
                        <div class="balance-title">
                            <div>Balance</div>
                            <span class="balance-amount">{{walletBalance | number}} ETH</span>
                        </div>
                        <div class="dropdown-divider"></div>
                        <a routerLinkActive="active"  (click)="routeToPage('/profile')" class="dropdown-item">
                            <span>Profile</span>
                        </a>
                        <a routerLinkActive="active" (click)="routeToPage('/setting')" class="dropdown-item">
                            <span>Settings</span>
                        </a>
                        <a routerLinkActive="active" (click)="logout()" class="dropdown-item">
                            <span>Disconnect</span>
                        </a>
                    </div>
                </li>
            </ul>
            <ul class="nav-items d-md-none">
                <li class="align-self-center">
                    <div class="navbar-action" (click)="isSearch = true">
                        <span class="nav-icon rounded-circle">
                            <span class="icon-search"></span>
                        </span>
                        <span class="item">
                            Search
                        </span>
                    </div>
                </li>
                <li class="align-self-center">
                    <div class="navbar-action" (click)="isDrop = !isDrop">
                        <span class="nav-icon rounded-circle">
                            <span *ngIf="!isDrop" class="icon-menu"></span>
                            <span *ngIf="isDrop" class="icon-gradient-close"></span>
                        </span>
                        <span class="item">
                            {{isDrop ? 'Close' : 'Menu'}}
                        </span>
                    </div>
                </li>
            </ul>
        </ng-container>
        <ng-container *ngIf="isSearch">
            <div class="nav-search-wrapper">
                <div class="gradient-search-wrapper">
                    <div class="icon-search-gradient"></div>
                    <input class="gradient-input form-control pl-0" placeholder="Search by creator or collectible"
                           type="text" [formControl]="control" />
                </div>
            </div>
            <ul class="nav-items d-md-none pt-3">
                <li class="align-self-center">
                    <div class="navbar-action" (click)="isSearch = false">
                        <span class="nav-icon rounded-circle">
                            <span class="icon-gradient-close"></span>
                        </span>
                        <span class="item">
                            Close
                        </span>
                    </div>
                </li>
            </ul>
        </ng-container>
    </nav>
</div>
<ng-container *ngIf="isDrop && !isSearch">
    <div class="container-fluid menu-drop-container">
        <div class="menu-holder">
            <div class="wallet-info-holder" *ngIf="isConnected">
                <div class="dropdown-header noti-title">
                    <h2 class="header-text m-0">{{walletAddress}}</h2>
                </div>
                <div class="balance-title">
                    <div>Balance</div>
                    <span class="balance-amount">{{walletBalance | number}} ETH</span>
                </div>
            </div>
            <ul class="nav-items drop-items">
                <li class="item" *ngIf="walletBalance">
                    <a class="menu-item" routerLinkActive="active" (click)="checkIsConnected('/profile')">Profile</a>
                </li>
                <li class="item" *ngIf="walletBalance">
                    <a class="menu-item" routerLinkActive="active" (click)="checkIsConnected('/setting')">Settings</a>
                </li>
                <li class="item" *ngIf="walletBalance">
                    <a class="menu-item" routerLinkActive="active" (click)="logout()">Disconnect</a>
                </li>
                <li class="item">
                    <a class="menu-item" routerLinkActive="active" (click)="routeToPage('/celebrities/All')">Featured Talents</a>
                </li>
                <li class="item">
                    <a class="menu-item" routerLinkActive="active" (click)="routeToPage('/browse')">Explore</a>
                </li>
                <li class="item">
                    <a class="menu-item" routerLinkActive="active" (click)="routeToPage('/about-us')">About us</a>
                </li>
                <li class="item">
                    <a class="menu-item" routerLinkActive="active" (click)="routeToPage('/contact-us')">Contact us</a>
                </li>
                <li class="item">
                    <a class="menu-item" routerLinkActive="active" (click)="routeToPage('/faq')">FAQ</a>
                </li>
            </ul>

        </div>
    </div>
    <div class="menu-footer container-fluid">
        <div class="social-icon justify-content-center pt-2 pb-2 ">
            <a href="https://www.agmostudio.com/" target="_blank">
                <div class="icon-facebook"></div>
            </a>
            <a href="https://www.agmostudio.com/#183" target="_blank">
                <div class="icon-twitter"></div>
            </a>
            <a href="https://www.agmostudio.com/blog/" target="_blank">
                <div class="icon-instagram"></div>
            </a>
        </div>
        <div *ngIf="!isConnected" class="w-100">
            <button class="btn btn-solid w-100" (click)="connectWallet()">Connect Wallet</button>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="isSearch">
    <div class="container-fluid menu-drop-container search-container">
        <div class="menu-holder">
            <div class="text-center" *ngIf="!control.value && !collectiblesResults">
                Search by creator or collectible
            </div>
            <div class="text-center" *ngIf="control.value != '' && collectiblesResults?.length == 0">
                <div class="text-white">No results found</div>
                <div>We couldn't find what you're looking for</div>
            </div>
            <div *ngIf="control.value != '' && celebritiesResults?.length > 0">
                <div class="text-white font-weight-bold pl-2 pb-3">Creators</div>
                <div>
                    <ul class="nav-items drop-items">
                        <li class="item" *ngFor="let result of celebritiesResults">
                            <a class="menu-item w-100 d-flex" (click)="mobileCloseDrop()"
                               [routerLink]="['/celebrities/celebrity-detail', result.id]">
                                <img class="mobile-celebrity-image mr-3" [src]="result.profilePicture" />
                                <div class="option-detail-column">
                                    <div class="asset-name">{{result.name}}</div>
                                    <div class="asset-creator">by {{result.name}}</div>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div *ngIf="control.value != '' && collectiblesResults?.length > 0">
                <div class="text-white font-weight-bold pl-2 pb-3">Collectibles</div>
                <div>
                    <ul class="nav-items drop-items">
                        <li class="item" *ngFor="let result of collectiblesResults">
                            <a class="menu-item w-100 d-flex" (click)="mobileCloseDrop()"
                               [routerLink]="['/browse', result.tokenAddress, result.tokenId]">
                                <img class="mobile-collectible-image mr-3" [src]="result.imageUrl" />
                                <div class="option-detail-column">
                                    <div class="asset-name">{{result.name}}</div>
                                    <div class="asset-creator">by {{result.creator.user.username}}</div>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="control.value" class="menu-footer container-fluid">
        <div class="w-100">
            <a class="w-100 btn btn-alt" (click)="mobileCloseDrop()" [routerLink]="['/browse']"
               [queryParams]="{ searchText: control.value}">All results</a>
        </div>
    </div>
</ng-container>
