<div *ngIf="hasAccess" class="content-wrapper py-3">
    <div class="pb-3 pl-2 text-center" #container>
        <button class="btn-clipboard" (click)="promptToaster()" ngxClipboard [cbContent]="walletAddress" [container]="container">
            {{walletAddress}}
            <span><i class="far fa-clone"></i></span>
        </button>
    </div>

    <div class="py-2 px-3">
        <div class="table-container">
            <div class="p-3 table-header">
                <ng-container *ngFor="let tab of offerTabs">
                    <div [ngClass]="tab.isSelected ? 'btn-category-selected' : 'btn-category-deselected'" class="btn" (click)="toggleOfferTabs(tab)">
                        {{tab.name}}
                    </div>
                </ng-container>
            </div>
            <div class="table-holder">
                <mat-table #table [dataSource]="offers" class="table-list" (scroll)="onOfferScroll($event)">
                    <ng-container>
                        <ng-container matColumnDef="Item">
                            <mat-header-cell *matHeaderCellDef>Item</mat-header-cell>
                            <mat-cell *matCellDef="let item">
                                <div class="c-pointer d-flex" [routerLink]="['/browse', item.asset.asset_contract.address, item.asset.token_id]">
                                    <div class="asset-image-wrapper">
                                        <div class="asset-preview">
                                            <ng-container *ngIf="item.asset.image_url">
                                                <img *ngIf="!isVideo(item.asset.image_url)" src="{{item.asset.image_url}}" />
                                                <video *ngIf="isVideo(item.asset.image_url)" controlsList="nodownload" playsinline autoplay muted loop src="{{data.asset.imageUrl}}"></video>
                                            </ng-container>
                                            <ng-container *ngIf="!item.asset.image_url">
                                                <img src="/assets/img/image-placeholder.png" />
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="asset-name">
                                        {{item.asset.name}}
                                    </div>
                                </div>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="UnitPrice">
                            <mat-header-cell *matHeaderCellDef>Unit Price</mat-header-cell>
                            <mat-cell *matCellDef="let item">
                                <div *ngIf="item.currentUnitPrice">{{item.currentUnitPrice}} ETH</div>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="Quantity">
                            <mat-header-cell *matHeaderCellDef>Quantity</mat-header-cell>
                            <mat-cell *matCellDef="let item">{{item.quantity}}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="From">
                            <mat-header-cell *matHeaderCellDef>From</mat-header-cell>
                            <mat-cell *matCellDef="let item">
                                <div *ngIf="item.makerAccount" class="d-flex">
                                    <div class="profile-picture-wrapper">
                                        <div class="user-profile-picture">
                                            <img src="{{item.makerAccount.profile_img_url}}" />
                                        </div>
                                    </div>
                                    <div *ngIf="item.makerAccount.user" class="contract-address">
                                        {{item.makerAccount.user.username || truncateMiddleText(item.makerAccount.address)}}
                                    </div>
                                    <div *ngIf="!item.makerAccount.user" class="contract-address">
                                        {{truncateMiddleText(item.makerAccount.address)}}
                                    </div>
                                </div>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="Expiration">
                            <mat-header-cell *matHeaderCellDef>Expiration</mat-header-cell>
                            <mat-cell *matCellDef="let item">
                                {{item.expirationDuration}}
                            </mat-cell>
                        </ng-container>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="offerTableDisplayColumns"></mat-header-row>
                    <mat-row *matRowDef="let item; let i = index; columns: offerTableDisplayColumns;"></mat-row>
                </mat-table>
                <div *ngIf="!loadOffer && offers.length == 0" class="no-data">
                    {{offerTabs[0].isSelected ? 'You have not made any offer' : 'You have not received any offer'}}
                </div>
            </div>
            <div *ngIf="loadOffer">
                <agmo-loading></agmo-loading>
            </div>
        </div>
    </div>
</div>
