export const environment = {
    production: false,
    apiServer: '',
    openSeaApiRoute: 'https://rinkeby-api.opensea.io/api/v1/',
    openSeaApiKey: '',
    openSeaWyvernRoute: 'https://rinkeby-api.opensea.io/wyvern/v1/',
    etherscanEndpoint: 'https://rinkeby.etherscan.io/',
    mainnWETHAddress: '0xc778417e063141139fce010982780140aa0cd5ab',
    openSeaCollection: 'nft-myeg',
    referrerAddress: '0xC18F3d31f362FB11C7F2b8B090d8BA95eF8aE137',
    chainID: '0x4',
    networkID: 4,
    firebase: {
        apiKey: 'AIzaSyC0-f4BHMFBk9cy7wzkOM07ZjSL6_-iwAg',
        authDomain: 'pangolin-nft.firebaseapp.com',
        projectId: 'pangolin-nft',
        storageBucket: 'pangolin-nft.appspot.com',
        messagingSenderId: '575082821810',
        appId: '1:575082821810:web:3dc9874633d002063d31ab',
        measurementId: 'G-S8LJ281YTC',
    },
    firebaseAccount: 'website.master@nftpangolin.com',
    firebasePassword: 'pangolin996633',
};

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
