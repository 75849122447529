<nav class="navbar navbar-vertical navbar-expand-md navbar-light bg-white" id="sidenav-main">
    <div class="container-fluid">
        <!-- Toggler -->
        <button class="navbar-toggler" type="button" (click)="isCollapsed=!isCollapsed"
                aria-controls="sidenav-collapse-main">
            <span class="navbar-toggler-icon"></span>
        </button>
        <!-- Brand -->
        <a class="navbar-brand pt-0" routerLinkActive="active" [routerLink]="['/dashboard']">
            <img src="assets/img/brand/agmologo.png" class="navbar-brand-img" alt="...">
        </a>
        <!-- User -->
        <ul class="nav align-items-center d-md-none">
            <li class="nav-item" ngbDropdown placement="bottom-right">
                <a class="nav-link nav-link-icon" role="button" ngbDropdownToggle>
                    <i class="fas fa-bell"></i>
                </a>
                <div class="dropdown-menu-arrow dropdown-menu-right" ngbDropdownMenu>
                    <a class="dropdown-item" href="javascript:void(0)">Action</a>
                    <a class="dropdown-item" href="javascript:void(0)">Another action</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="javascript:void(0)">Something else here</a>
                </div>
            </li>
            <li class="nav-item" ngbDropdown placement="bottom-right">
                <a class="nav-link" role="button" ngbDropdownToggle>
                    <div class="media align-items-center">
                        <span class="avatar avatar-sm rounded-circle">
                            <img alt="Image placeholder" src="assets/img/theme/team-1-800x800.jpg">
                        </span>
                    </div>
                </a>
                <div class="dropdown-menu-arrow dropdown-menu-right" ngbDropdownMenu>
                    <div class=" dropdown-header noti-title">
                        <h6 class="text-overflow m-0">Welcome!</h6>
                    </div>
                    <a routerLinkActive="active" [routerLink]="['/user-profile']" class="dropdown-item">
                        <i class="fas fa-user"></i>
                        <span>My profile</span>
                    </a>
                    <a routerLinkActive="active" [routerLink]="['/user-profile']" class="dropdown-item">
                        <i class="fas fa-cog"></i>
                        <span>Settings</span>
                    </a>
                    <a routerLinkActive="active" [routerLink]="['/user-profile']" class="dropdown-item">
                        <i class="fas fa-calendar"></i>
                        <span>Activity</span>
                    </a>
                    <a routerLinkActive="active" [routerLink]="['/user-profile']" class="dropdown-item">
                        <i class="fas fa-question"></i>
                        <span>Support</span>
                    </a>
                    <div class="dropdown-divider"></div>
                    <a href="#!" class="dropdown-item">
                        <i class="fas fa-sign-out-alt"></i>
                        <span>Logout</span>
                    </a>
                </div>
            </li>
        </ul>
        <!-- Collapse -->
        <div class="collapse navbar-collapse" [ngbCollapse]="isCollapsed" id="sidenav-collapse-main">
            <!-- Collapse header -->
            <div class="navbar-collapse-header d-md-none">
                <div class="row">
                    <div class="col-6 collapse-brand">
                        <a routerLinkActive="active" [routerLink]="['/dashboard']">
                            <img src="assets/img/brand/agmo-logo.png">
                        </a>
                    </div>
                    <div class="col-6 collapse-close">
                        <button type="button" class="navbar-toggler" (click)="isCollapsed=!isCollapsed">
                            <span></span>
                            <span></span>
                        </button>
                    </div>
                </div>
            </div>
            <!-- Form -->
            <form class="mt-4 mb-3 d-md-none">
                <div class="input-group input-group-rounded input-group-merge">
                    <input type="search" class="form-control form-control-rounded form-control-prepended" placeholder="Search" aria-label="Search">
                    <div class="input-group-prepend">
                        <div class="input-group-text">
                            <span class="fa fa-search"></span>
                        </div>
                    </div>
                </div>
            </form>
            <!-- Navigation -->
            <ul class="navbar-nav">
                <li *ngFor="let menuItem of menuService.menu" class="{{menuItem.class}} nav-item">
                    <a [class.active]="isActiveMenu(menuItem)" routerLinkActive="active" [routerLink]="menuItem.url" class="nav-link">
                        <i class="ni {{menuItem.icon}}"></i>
                        {{menuItem.name}}
                    </a>
                </li>
            </ul>
            <!-- Divider -->
            <hr class="my-3">
            <!-- Heading -->
            <h6 class="navbar-heading text-muted">Documentation</h6>
            <!-- Navigation -->
            <ul class="navbar-nav mb-md-3">
                    <li class="nav-item">
                      <a class="nav-link" href="https://demos.creative-tim.com/argon-dashboard-angular/documentation/tutorial">
                        <i class="fas fa-rocket"></i> Getting started
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="https://demos.creative-tim.com/argon-dashboard-angular/documentation/colors">
                        <i class="fas fa-palette"></i> Foundation
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="https://demos.creative-tim.com/argon-dashboard-angular/documentation/alerts">
                        <i class="fas fa-suitcase"></i> Components
                      </a>
                    </li>
                  </ul>
        </div>
    </div>
</nav>
