import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from '../../core/models/menu/menu-item';
import { MenuService } from '../../core/services/menu.service';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
    menu: any[];
    isCollapsed = true;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        public menuService: MenuService) { }

    ngOnInit() {
        this.router.events.subscribe((event) => {
            this.isCollapsed = true;
        });
    }

    isActiveMenu(menu: MenuItem) {
        if (!this.route.snapshot) return false;

        const path = "/" + this.route.snapshot.url.join("/");

        if (path.indexOf(menu.url) != 0)
            return false;

        // if active belongs to any submenu, return false
        if (menu.submenus && menu.submenus.length > 0 && menu.submenus.some(m => this.isActiveMenu(m))) {
            return false;
        }

        return true;
    }
}
