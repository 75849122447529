import { Component, NgZone, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Order, OrderSide } from 'opensea-js/lib/types';
import { environment as env } from '../../../environments/environment';
import { OpenSeaAssetEventType, NftSaleMode } from '../../core/enum/asset/opensea-asset-event-enum';
import { ModalType } from '../../core/enum/modal/modal.enum';
import { Asset } from '../../core/models/opensea/opensea-asset';
import { OpenSeaEvent } from '../../core/models/opensea/opensea-event';
import { OrderObj } from '../../core/models/opensea/opensea-orders';
import { OpenseaService } from '../../core/services/opensea.service';
import { BlockUiService } from '../../shared/services/blockUi/block-ui.service';
import { PopupService } from '../../shared/services/popup/popup.service';
import { PreviewService } from '../../shared/services/preview/preview.service';
import { PurchaseService } from '../../shared/services/purchase/purchase.service';
import { AssetEventsFilter } from './asset-events-filter';
import { FirebaseService } from '../../core/services/firebase.service';
import { Celebrity } from '../../core/models/celebrity';
import { AssetFilter } from '../browse/asset-filter';
import { Subscription, interval } from 'rxjs';
import { TimeService } from '../../core/services/time.service';
import { TimeRemaining } from '../../core/models/time-remaining';
import { BasePageComponent } from 'src/app/pages/base-page/base-page.component';
import { BlockUiType } from 'src/app/core/enum/general/blockUI-type.enum';
import { ClientSideFilter } from 'src/app/pages/asset-listing/client-side-filter';

@Component({
    selector: 'app-asset-detail',
    templateUrl: './asset-detail.component.html',
    styleUrls: ['./asset-detail.component.scss']
})
export class AssetDetailComponent extends BasePageComponent implements OnInit, OnDestroy {

    isLoading = true;
    loadTrading = false;
    walletAddress = this.openseaService.getWalletAddress();
    tokenAddress = this.route.snapshot.paramMap.get('tokenAddress');
    tokenId = this.route.snapshot.paramMap.get('tokenId');;
    tradingHistoryFilter: AssetEventsFilter;
    data: Asset;
    etherscanEndpoint = env.etherscanEndpoint;
    currentPrice: number = 0;
    lastSalePrice: number = 0;

    listingOrder: Order[];
    offerOrder: Order[];
    listing: OrderObj[];
    offers: OrderObj[];
    tradingHistory: OpenSeaEvent[] = [];

    isOwnedByUser = false;
    itemIsBidding = false;

    celebrity: Celebrity;
    ownerId: string;
    selectedTab = 0;

    tabs = [
        { name: 'Offers' },
        { name: 'Details' },
        { name: 'History' }
    ];

    historyType = OpenSeaAssetEventType;
    assetParams = new AssetFilter();
    isShowCollection = false;
    usdPerETH: number;
    isShare = false;
    assetFilters: ClientSideFilter;
    NftSaleMode = NftSaleMode;
    isHistoryHasMore: boolean = true;

    private subscription: Subscription;
    timeRemaining: TimeRemaining;

    constructor(
        protected openseaService: OpenseaService,
        private route: ActivatedRoute,
        private router: Router,
        private popupService: PopupService,
        private previewService: PreviewService,
        private purchaseService: PurchaseService,
        protected blockUiService: BlockUiService,
        protected ngZone: NgZone,
        private firebaseService: FirebaseService,
        private timeService: TimeService,
    ) {
        super(openseaService, ngZone, blockUiService);
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.initFunctions();
    }

    ngOnDestroy() {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    initFunctions() {
        this.assetParams.limit = 4;
        this.constructTradingHistoryFilter();
        this.getAssetDetail();
        this.getUsdPerETH();
    }

    getAssetDetail() {
        this.isLoading = true;
        let param: any = {
            tokenAddress: this.tokenAddress,
            tokenId: this.tokenId
        }

        this.openseaService.getAssetHttp(param).subscribe(result => {
            this.data = new Asset(result);

            if (this.data.nftSaleMode === NftSaleMode.Bidding) {
                this.itemIsBidding = true;
            }

            this.isOwnedByUser = this.data.topOwner.address == this.walletAddress;

            this.openseaService.getAssetOffersHttp(param).subscribe(offers => {
                if (offers.offers.length > 0) {
                    this.data.orders = offers.offers;
                    this.data.buyOrders = offers.offers;
                    this.offerOrder = this.data.buyOrders;
                    this.offers = this.data.orders.map(e => {
                        return new OrderObj(e);
                    }).filter(e => e.orderSide == OrderSide.Buy);

                    if (this.itemIsBidding) {
                        this.currentPrice = this.offers[0].currentUnitPrice;
                    }
                }
            });
            
           
            this.openseaService.getAssetListingsHttp(param).subscribe(listingOrders => {
                if (listingOrders.listings.length > 0) {
                    this.data.sellOrders = listingOrders.listings
                    this.listingOrder = this.data.sellOrders;
                    this.listing = this.data.sellOrders.map(e => {
                        return new OrderObj(e)
                    });
                    this.currentPrice = this.listing[0].currentUnitPrice;
                    let endDateMoment = moment(this.data.sellOrders[0].closing_date).add(8, 'hours').toString();
                    this.subscription = interval(1000)
                        .subscribe(x => {
                            this.timeRemaining = this.timeService.getTimeDifference(new Date(endDateMoment));
                        });
                }
            });
            
            

            if (this.data.lastSale) {
                let lastSale: any = this.data.lastSale;
                this.lastSalePrice = (parseInt(lastSale.total_price) / Math.pow(10, lastSale.payment_token.decimals));
            }

            if (this.data.description) {
                let regex = /\*\*([^*]+)\*\*/ig;
                this.data.description = this.data.description.replace(regex, "<b>$1</b>")
            }

            this.firebaseService.getCelebrityByAddress(this.data.creator.address).subscribe(response => {
                this.celebrity = response[0];
                this.ownerId = this.celebrity.id;
                this.assetFilters = { creators: [this.celebrity.walletAddress], excludeAssetID: this.data.id };
                this.firebaseService.getImageUrl(response[0].profilePicture).subscribe(url => {
                    this.celebrity.profilePicture = url;
                });
            })
        }, err => {
            console.log(err);
            setTimeout(() => {
                this.getAssetDetail();
            }, 1500);
        }).add(() => {
            this.isLoading = false;
            this.loadTrading = true;
            this.isShowCollection = true;
            setTimeout(() => this.getTradingHistory(), 2000);
        });
    }

    setPriceFilterPeriod(days: number) {
        let currentDateTime = moment();
        let startDay = moment().subtract(days, 'days');
    }

    getTradingHistory() {
        this.loadTrading = true;

        this.openseaService.getEvents(this.tradingHistoryFilter).subscribe(response => {
            if (!response.next_page) {
                this.isHistoryHasMore = false;
            }

            this.tradingHistory = this.tradingHistory.concat(response.data);
        }, err => {
            if (err) {
                if (err.detail) {
                    console.log(err.detail);
                }
                else {
                    console.log(err);
                }
            }
        }).add(() => {
            this.loadTrading = false;
        });
    }

    loadMoreHistory() {
        
        this.getTradingHistory();
    }

    constructTradingHistoryFilter() {
        this.tradingHistoryFilter = new AssetEventsFilter();
        
        this.tradingHistoryFilter.asset_contract_address = this.tokenAddress;
        this.tradingHistoryFilter.token_id = this.tokenId;
    }

    getTradingEventName(value: any) {
        for (let enumMember in OpenSeaAssetEventType) {
            if (value == OpenSeaAssetEventType[enumMember])
                return enumMember;
        }
    }

    switchTab(index: number) {
        this.selectedTab = index;
    }

    acceptOffer(index: number) {
        this.openseaService.isMainnet().then(result => {
             if (result == env.networkID) {
                this.popupService.confirm('Are you sure you want to accept the offer?\nNote: Upon successful accepting offer, it might take some time to reflect.').then(() => {
                    this.blockUiService.open();
                    this.openseaService.cancelOrAcceptOrder(this.data.assetContract.address, this.data.tokenId, OrderSide.Buy, this.offerOrder[index], false).then(result => {
                        this.popupService.alert('Item has successfully transfered!').then(() => {
                            location.reload();
                        });
                    }).catch(err => {
                        if (err.message)
                            this.popupService.alert(err.message);
                        else
                            this.popupService.alert('Transaction failed. Please try again');
                    }).finally(() => {
                        this.blockUiService.close();
                    });
                });
            }
            else {
                this.popupService.alert('Your wallet is connected to a test network, please change to Rinkeby Test Network.');
            }
        }).catch(err => {
            console.log(err);
        });
    }

    cancelOrder(index: number, isListing: boolean) {
        this.openseaService.isMainnet().then(result => {
            if (result == env.networkID) {
                this.popupService.confirm('Are you sure you want to cancel?\nNote: Upon successful cancellation, it might take some time to reflect.').then(() => {
                    let order = isListing ? this.listingOrder[index] : this.offerOrder[index];
                    this.blockUiService.open();

                    this.openseaService.cancelOrAcceptOrder(this.data.tokenAddress, this.data.tokenId, order.side, this.offerOrder[index], true).then(() => {
                        this.popupService.alert(isListing ? 'Listing has been cancelled.' : 'Offer has been cancelled.').then(() => {
                            this.getAssetDetail();
                        });
                    }).catch(err => {
                        if (err.message)
                            this.popupService.alert(err.message);
                        else
                            this.popupService.alert('Failed to cancel offer.');
                    }).finally(() => {
                        this.blockUiService.close();
                    });
                });
            }
            else {
                this.popupService.alert('Your wallet is connected to a test network, please change to Rinkeby Test Network.');
            }
        }).catch(err => {
            console.log(err);
        });
    }

    openBidOfferPopup() {
        if (this.hasAccess) {
            this.openseaService.isMainnet().then(result => {
                if (result == env.networkID) {
                    if (this.itemIsBidding) {
                        this.purchaseService.open(ModalType.Bid, this.data, this.celebrity.name, this.listing);
                    }
                    else {
                        this.purchaseService.open(ModalType.Offer, this.data, this.celebrity.name, this.listing);
                    }
                }
                else {
                    this.popupService.alert('Your wallet is connected to a test network, please change to Rinkeby Test Network.');
                }
            }).catch(err => {
                console.log(err);
            });
        }
        else {
            this.popupService.alert('Please connect to metamask to continue');
        }
    }

    openBuyPopup() {
        if (this.hasAccess) {
            this.openseaService.isMainnet().then(result => {
                if (result == env.networkID) {
                    this.purchaseService.open(ModalType.Checkout, this.data, this.celebrity.name, this.listing);
                }
                else {
                    this.popupService.alert('Your wallet is connected to a test network, please change to Rinkeby Test Network.');
                }
            }).catch(err => {
                console.log(err);
            });
        }
        else {
            this.popupService.alert('Please connect to metamask to continue');
        }
    }

    getUsdPerETH() {
        this.openseaService.getETHPrice().subscribe(response => {
            this.usdPerETH = response.USD;
        });
    }

    viewPhoto(photo: any, selected?: any) {
        let media = { imageUrl: photo };
        this.previewService.open(media, selected);
    }

    viewVideo(video: any, selected?: any) {
        let media = { mp4Url: video };
        this.previewService.open(media, selected);
    }

    isVideo(src: string) {
        let extension = src.split('.').pop();

        if (extension == ('mp4' || 'webm')) {
            return true;
        }
        else return false;
    }

    truncateMiddleText(str: string) {
        return str.substr(0, 6) + '...' + str.substr(str.length - 4, str.length)
    }

}
