import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardModule } from 'ngx-clipboard';

import { AppComponent } from './app.component';
import { httpInterceptorProviders } from './shared/http-interceptors/http-interceptors';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire/';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule  } from "@angular/fire/auth";
import { HeyjomLayoutComponent } from 'src/app/layouts/heyjom-layout/heyjom-layout.component';
import { HeyjomModule } from 'src/app/components/heyjom/heyjom.module';

@NgModule({
    imports: [
        BrowserAnimationsModule,
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        FormsModule,
        HttpClientModule,
        ComponentsModule,
        NgbModule,
        RouterModule,
        AppRoutingModule,
        ClipboardModule,
        ToastrModule.forRoot({
            timeOut: 1500,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true,
        }),
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule,
        AngularFireStorageModule,
        AngularFireAuthModule,
        HeyjomModule,
    ],
    declarations: [
        AdminLayoutComponent,
        AppComponent,
        AuthLayoutComponent,
        HeyjomLayoutComponent,
    ],
    providers: [ httpInterceptorProviders ],
    bootstrap: [ AppComponent ]
})
export class AppModule { }
