import { CelebrityAsset, CelebrityModel, FeaturedCelebrityModel } from 'src/app/core/models/celebrity-model';
import { SocialMedia } from './social-media';

export class Celebrity {
    id: string;
    description: string;
    isFeatured: boolean;
    isVerified: boolean;
    name: string;
    profilePicture: string;
    profilePictureUrl: string;
    socialMedia: SocialMedia;
    thumbnailPicture: string;
    walletAddress: string;
    category: string[];
    isReady: boolean;
    imgWidth: number;
    imgHeight: number;
    uiIsHorizontal: boolean;
    assets: CelebrityAsset[];

    constructor(model: CelebrityModel) {
        this.description = model.description;
        this.isFeatured = model.is_featured;
        this.isVerified = model.is_verified;
        this.name = model.name;
        this.profilePicture = model.profile_picture;
        this.socialMedia = model.social_media ? new SocialMedia(model.social_media) : null;
        this.thumbnailPicture = model.thumbnail_picture;
        this.walletAddress = model.wallet_address;
        this.assets = model.assets;
    }
}

export class FeaturedCelebrity extends Celebrity {
    featuredNftAsset: FeaturedNftAsset;

    constructor(model: FeaturedCelebrityModel) {
        super(model);

        if (!model.featured_nft_asset) {
            return;
        }

        this.featuredNftAsset = {
            assetAddress: model.featured_nft_asset.asset_address,
            imageUrl: model.featured_nft_asset.image_url,
            name: model.featured_nft_asset.name,
            tokenID: model.featured_nft_asset.token_id,
        };
    }
}

export interface FeaturedNftAsset {
    assetAddress: string;
    imageUrl: string;
    name: string;
    tokenID: string;
}
