import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Celebrity, FeaturedCelebrity } from '../../core/models/celebrity';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, ViewChildren, QueryList, OnDestroy } from '@angular/core';
import { Dropdown } from '../../core/models/dropdown';
import { FILTER_ALL_LABEL, FILTER_FEATURED_LABEL } from 'src/app/core/constants';
import { FirebaseService } from '../../core/services/firebase.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-celebrities',
    templateUrl: './featured-talents.component.html',
    styleUrls: ['./featured-talents.component.scss']
})
export class CelebritiesComponent implements OnInit, AfterViewInit, OnDestroy {

    categories: Dropdown[] = [];
    celebrities: Celebrity[] = [];
    leftColumnCelebrities: Celebrity[] = [];
    rightColumnCelebrities: Celebrity[] = [];
    selectedCategory: string = FILTER_ALL_LABEL;
    selectedCategoryName: string = FILTER_ALL_LABEL;

    @ViewChild('gallery') elementView: ElementRef;
    @ViewChild('sideContainer') leftSidebar: ElementRef;
    @ViewChildren('imgContainer') imgContainers: QueryList<ElementRef>;

    private destroy$ = new Subject();
    private observer: IntersectionObserver;

    constructor(
        private firebaseService: FirebaseService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.retrieveSelectedCategory();
        this.getCelebrityCategory();
        this.getCelebrityListing();
        this.urlParamChangeHandler();
    }

    ngAfterViewInit() {
        this.observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                let target = (<HTMLElement>entry.target);

                if (entry.isIntersecting) {
                    target.style.width = target.dataset.width;
                }
                else {
                    target.dataset.width = target.style.width;

                    if (target.clientWidth > 450)
                        target.style.width = '450px';
                }
            });
        }, {
            root: null,
            rootMargin: '0px',
            threshold: 0
        });

        this.imgContainers.changes.pipe(takeUntil(this.destroy$)).subscribe((elements: QueryList<ElementRef>) => {
            elements.forEach(e => this.observer.observe(e.nativeElement));
        });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    getCelebrityCategory() {
        this.firebaseService.getCelebrityCategory().subscribe(response => {
            this.categories = response;
            this.categories.unshift({ name: FILTER_FEATURED_LABEL, value: FILTER_FEATURED_LABEL });
            this.categories.unshift({ name: FILTER_ALL_LABEL, value: FILTER_ALL_LABEL });

            let categoryFound: Dropdown = this.categories.find(category => category.value === this.selectedCategory);
            if (!categoryFound) {
                this.selectedCategory = FILTER_ALL_LABEL;
            }
        });
    }

    getCelebrityListing(reload?: boolean) {
        if (reload) {
            this.leftColumnCelebrities = [];
            this.rightColumnCelebrities = [];
        }

        if (this.selectedCategory === FILTER_FEATURED_LABEL) {
            return this.getFeaturedCelebrityListing();
        }

        this.firebaseService.getCelebrityListingByCategoryNew(this.selectedCategory).subscribe(response => {
            this.celebrities = response;
            this.celebrities.filter(celebrity => celebrity.assets?.length > 0).map(celebrity => {
                this.firebaseService.getImageUrl(celebrity.profilePicture).subscribe(url => {
                    this.loadProfileImage(celebrity, url);
                });

                this.determineMainImage(celebrity);
                this.fillUntilFourAssets(celebrity);
            });

            this.divideToLeftRight(this.celebrities);
        });
    }

    private determineMainImage(celebrity: Celebrity): void {
        if (celebrity.assets.length === 0) {
            return;
        }

        let assetImage = new Image();
        assetImage.onload = () => {
            if (assetImage.width >= assetImage.height) {
                return celebrity.uiIsHorizontal = true;
            }

            celebrity.uiIsHorizontal = false;
        };
        assetImage.src = celebrity.assets[0].image_url;
    }

    private divideToLeftRight(celebrities: Celebrity[]): void {
        this.leftColumnCelebrities = celebrities.slice(0, Math.round(celebrities.length / 2));
        this.rightColumnCelebrities = celebrities.slice(Math.round(celebrities.length / 2), celebrities.length);
    }

    private fillUntilFourAssets(celebrity: Celebrity): void {
        if (celebrity.assets.length >= 4) {
            return;
        }

        let remaining: number = 4 - celebrity.assets.length;
        for (let i = 0; i < remaining; i++) {
            celebrity.assets.push({
                id: -1, // Default asset ID set to -1 for HTML to generate unclickable asset.
                asset_address: '',
                image_url: '/assets/img/default/default_asset.png',
                name: '',
                token_id: '',
            });
        }
    }

    private getFeaturedCelebrityListing(): void {
        this.firebaseService.getFeaturedCelebrities().subscribe(response => {
            let featuredCelebrities: FeaturedCelebrity[] = response;

            featuredCelebrities.forEach(celebrity => {
                this.firebaseService.getImageUrl(celebrity.profilePicture).subscribe(url => {
                    this.loadProfileImage(celebrity, url);
                });

                this.determineMainImage(celebrity);
                this.fillUntilFourAssets(celebrity);
            })

            this.divideToLeftRight(featuredCelebrities);
        });
    }

    private loadProfileImage(celebrity: Celebrity, url: string): void {
        let img = new Image();
        img.src = url;
        img.onload = () => {
            celebrity.profilePicture = url;
            celebrity.isReady = true;

            let aspectRatio = img.width / img.height;
            let targetAspectRatio = 500 / 450;

            if (aspectRatio > targetAspectRatio) {
                celebrity.imgWidth = 500;
                celebrity.imgHeight = img.height / img.width * 500;
            }
            else {
                celebrity.imgHeight = 450;
                celebrity.imgWidth = img.width / img.height * 450;
            }
        }
    }

    private retrieveSelectedCategory(): void {
        let urlParam: string = this.activatedRoute.snapshot.paramMap.get('categoryId');
        if (!urlParam) {
            return;
        }

        this.selectedCategory = urlParam;
        let categoryFound: Dropdown = this.categories.find(category => category.value === this.selectedCategory);
        if (!categoryFound) {
            return;
        }

        this.selectedCategoryName = categoryFound.name;
    }

    /**
     * Clicking on tab will change the URL with parameter.
     * We will get the new parameter from URL & determine the selected category.
     */
    private urlParamChangeHandler(): void {
        this.router.events.pipe(takeUntil(this.destroy$)).subscribe(
            (val) => {
                if (val instanceof NavigationEnd) {
                    this.retrieveSelectedCategory();
                    this.getCelebrityListing();
                }
            }
        );
    }

}
