import { Component, NgZone, OnInit } from '@angular/core';
import { OpenseaService } from '../../core/services/opensea.service';
import { BlockUiService } from '../../shared/services/blockUi/block-ui.service';
import { SettingMenu } from '../../core/enum/asset/setting-menu.enum';
import { Router } from '@angular/router';
import { PopupService } from '../../shared/services/popup/popup.service';
import { BasePageComponent } from 'src/app/pages/base-page/base-page.component';

@Component({
    selector: 'app-setting',
    templateUrl: './setting.component.html',
    styleUrls: ['./setting.component.scss']
})
export class SettingComponent extends BasePageComponent implements OnInit {

    walletAddress;
    SettingMenu = SettingMenu;
    SettingMenuSelection: SettingMenu;
    SettingMenuOptions = [
        { label: SettingMenu[SettingMenu.General], value: 1 },
        { label: SettingMenu[SettingMenu['My Wallet']], value: 2 },
    ];
    selectedTab: number = 1;

    constructor(
        protected openseaService: OpenseaService,
        protected blockUiService: BlockUiService,
        protected ngZone: NgZone,
        private router: Router,
        private popupService: PopupService
    ) {
        super(openseaService, ngZone, blockUiService);
    }

    ngOnInit(): void {
        super.ngOnInit();

        if (window.ethereum) {
            this.walletAddress = this.truncateAddressText();
        } else {
            this.popupService.alert('Please connect to metamask to view profile').then(() => {
                this.router.navigate(['/landing-page']);
            });
        }
    }

    truncateAddressText() {
        let str = this.openseaService.getWalletAddress();
        return str.substr(0, 6) + '...' + str.substr(str.length - 4, str.length)
    }

    filterListing(menu: any) {
        this.selectedTab = menu.value;

    }
}
