import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { TimeRemaining } from '../models/time-remaining';

@Injectable({
    providedIn: 'root'
})

export class TimeService {
    milliSecondsInASecond = 1000;
    hoursInADay = 24;
    minutesInAnHour = 60;
    secondsInAMinute = 60;

    getTimeDifference(closingDate): TimeRemaining {
        return this.allocateTimeUnits(closingDate.getTime() - new Date().getTime());
    }

    private allocateTimeUnits(timeDifference): TimeRemaining {
        let timeRemaining = new TimeRemaining();

        timeRemaining.secondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.secondsInAMinute);
        timeRemaining.minutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.secondsInAMinute);
        timeRemaining.hoursToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.secondsInAMinute) % this.hoursInADay);
        timeRemaining.daysToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.secondsInAMinute * this.hoursInADay));

        return timeRemaining;
    }
}
