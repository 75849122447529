<div class="form-container">
    <form [formGroup]="form" [class.show-form-error]="showFormError">
        <div class="row">
            <div class="col-12 pt-3">
                <div class="text-white pb-1">Name</div>
                <input class="form-control" placeholder="Enter name" type="text" formControlName="name" maxlength="100" />
                <div class="error-msg" *ngIf="showFormError && name.invalid">
                    Only allowed Alphanumeric, Space and symbol ` @ ( ) / ' , . -
                </div>
            </div>
            <div class="col-12 pt-3">
                <div class="text-white pb-1">Username</div>
                <div class="position-relative">
                    <input class="form-control username-field" placeholder="Enter name" type="text" formControlName="username" minlength="6" maxlength="50" />
                    <div class="username-prefix">@</div>
                </div>
                <div class="error-msg" *ngIf="showFormError && username.invalid">
                    6 to 50 characters, only allowed Alphanumeric.
                </div>
            </div>
            <div class="col-12 pt-3">
                <div class="text-white pb-1">Email</div>
                <input class="form-control" placeholder="Enter email address" type="email" formControlName="email" minlength="6" maxlength="50" />
                <div *ngIf="email.invalid">
                    <div class="error-msg" *ngIf="showFormError">
                        {{email.value ? 'This is not a valid email address' : 'Email is required'}}
                    </div>
                </div>
            </div>
            <div class="col-12 pt-1">
                <div class="text-left mt-2">
                    <img src="/assets/img/svg-pangolin/Icon-info-gradient-24.svg" />
                    <span class="ml-2">Add your email address to receive notifications about your activity on NFTPangolin. This will not be shown on your profile.</span>
                </div>
            </div>
        </div>
    </form>
    <button class="btn btn-solid w-100 mt-3" [disabled]="!name.value || !email.value || !username.value" (click)="submit()">{{user? 'Save Changes': 'Save'}}</button>
</div>


