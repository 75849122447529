import { Component, NgZone, OnInit } from '@angular/core';
import { OpenseaService } from '../../core/services/opensea.service';
import { BlockUiService } from '../../shared/services/blockUi/block-ui.service';
import { ProfileMenu } from '../../core/enum/asset/profile-menu.enum';
import { AssetFilter } from '../browse/asset-filter';
import { ActivatedRoute, Router } from '@angular/router';
import { PopupService } from '../../shared/services/popup/popup.service';
import { BasePageComponent } from 'src/app/pages/base-page/base-page.component';
import { ToastrService } from 'ngx-toastr';
import { ClientSideFilter } from '../asset-listing/client-side-filter';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent extends BasePageComponent implements OnInit {

    walletAddress;
    ProfileMenu = ProfileMenu;
    ProfileMenuSelection: ProfileMenu;
    ProfileMenuOptions = [
        { label: ProfileMenu[ProfileMenu.Collected], value: 1 },
        { label: ProfileMenu[ProfileMenu.Activity], value: 2 },
    ];
    selectedTab: number = 1;
    ownedParams: AssetFilter;
    params = new AssetFilter();
    assetFilters: ClientSideFilter;
    modalType = this.activatedRoute.snapshot.params.modalType;

    constructor(
        protected openseaService: OpenseaService,
        protected blockUiService: BlockUiService,
        protected ngZone: NgZone,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private popupService: PopupService,
        private toastr: ToastrService,
    ) {
        super(openseaService, ngZone, blockUiService);
    }

    ngOnInit(): void {
        super.ngOnInit();

        if (this.modalType) {
            this.selectedTab = this.modalType;
        }

        if (window.ethereum) {
            this.walletAddress = this.openseaService.getWalletAddress();
            // owner is not the creator
            this.assetFilters = { creators: [] };

            this.ownedParams = new AssetFilter();
            this.ownedParams.owner = this.openseaService.getWalletAddress();
        }
        else {
            this.popupService.alert('Please connect to metamask to view profile').then(() => {
                this.router.navigate(['/landing-page']);
            });
        }

    }

    filterListing(menu: any): void {
        this.selectedTab = menu.value;
    }

    promptToaster(): void {
        this.toastr.success('Wallet Address is Copied');
    }
}
