import { Asset } from 'src/app/core/models/opensea/opensea-asset';
import { Celebrity } from 'src/app/core/models/celebrity';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FirebaseService } from 'src/app/core/services/firebase.service';
import { FlexDataObservable, FlexNftAsset } from './flex-gallery.model';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-flex-gallery',
    templateUrl: './flex-gallery.component.html',
    styleUrls: ['./flex-gallery.component.scss']
})
export class FlexGalleryComponent implements OnInit {

    @Input('nftDataObs') dataObs: Observable<FlexDataObservable>;

    @ViewChild('colLeft') colLeft: ElementRef<HTMLDivElement>
    @ViewChild('colMiddle') colMiddle: ElementRef<HTMLDivElement>
    @ViewChild('colRight') colRight: ElementRef<HTMLDivElement>

    allCreators: {[walletAddress: string]: Celebrity} = {};
    flexColLeftNft: FlexNftAsset[] = [];
    flexColMiddleNft: FlexNftAsset[] = [];
    flexColRightNft: FlexNftAsset[] = [];
    mobileColNft: FlexNftAsset[] = [];

    private currentColIndex: number = 0;
    private galleryData: Asset[] = [];
    private isAsyncReady = {
        celebrity: false,
        nftAsset: false,
    };

    constructor(
        private firebaseService: FirebaseService,
    ) { }

    ngOnInit(): void {
        this.dataReceiveHandler();
        this.getFullCelebrity();
    }

    private dataReceiveHandler(): void {
        this.dataObs.subscribe(
            (flexData) => {
                if (flexData.isReload) {
                    this.flexColLeftNft = [];
                    this.flexColMiddleNft = [];
                    this.flexColRightNft = [];
                    this.mobileColNft = [];
                }

                this.galleryData = flexData.nfts;
                this.isAsyncReady.nftAsset = true;
                this.mergeData();
            },
            (err) => {},
        );
    }

    private findShortestColumnIndex(): void {
        if (this.flexColLeftNft.length === 0) {
            this.currentColIndex = 0;
            return;
        }

        let shortestCol: number[] = [this.colLeft.nativeElement.clientHeight, this.colMiddle.nativeElement.clientHeight, this.colRight.nativeElement.clientHeight];
        let smallest: number = Math.min(...shortestCol);
        this.currentColIndex = shortestCol.findIndex(clientHeight => clientHeight === smallest);
    }

    private getFullCelebrity(): void {
        this.firebaseService.getCelebrityListingByCategoryNew('All').subscribe(
            (celebrities) => {
                celebrities.forEach(
                    celebrity => {
                        this.loadProfilePictire(celebrity);
                        this.allCreators[celebrity.walletAddress] = celebrity;
                    }
                );

                this.isAsyncReady.celebrity = true;
                this.mergeData();
            }
        );
    }

    private insertToColumn(nftAsset: FlexNftAsset) {
        switch (this.currentColIndex) {
            case 0:
                this.flexColLeftNft.push(nftAsset);
                break;
            case 1:
                this.flexColMiddleNft.push(nftAsset);
                break;
            case 2:
                this.flexColRightNft.push(nftAsset);
                break;
            default:
                break;
        }

        this.currentColIndex++;
        if (this.currentColIndex >= 3) {
            this.currentColIndex = 0;
        }
    }

    private loadProfilePictire(celebrity: Celebrity): void {
        this.firebaseService.getImageUrl(celebrity.profilePicture).subscribe(
            url => {
                let img = new Image();
                img.onload = () => {
                    celebrity.profilePicture = url;
                    celebrity.isReady = true;
                }
                img.src = url;
            }
        );
    }

    private mergeData(): void {
        if (this.isAsyncReady.nftAsset !== true || this.isAsyncReady.celebrity !== true) {
            return;
        }

        this.findShortestColumnIndex();

        this.galleryData.forEach(
            nft => {
                let foundCreator: Celebrity = this.allCreators[nft.creator?.address];
                if (!foundCreator) {
                    return;
                }

                let flexNft: FlexNftAsset = {
                    assetAddress: nft.tokenAddress,
                    creator: foundCreator,
                    imageUrl: nft.imageUrl,
                    name: nft.name,
                    tokenID: nft.tokenId,
                };

                this.insertToColumn(flexNft);
                this.mobileColNft.push(flexNft);
            }
        );
    }

}
