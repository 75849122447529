<div class="container-fluid featured-talents">

    <div class="row">
        <div class="col-12">
            <h1 class="color-header">Featured Talents</h1>
        </div>
    </div>

    <div class="row justify-content-md-center">
        <div class="col-sm-12 col-md-8 small-desciption">
            <div>Own authentic nifty assets from regional creators</div>
        </div>
    </div>

    <!-- Desktop Filter -->
    <div class="d-none d-md-flex row mt-4 small-bottom-border">
        <div class="col-12">
            <nav class="navbar navbar-expand-md navbar-light justify-content-center">
                <ul class="navbar-nav">
                    <li class="nav-item" *ngFor="let category of categories; let firstCategory = first"
                        [class.active]="selectedCategory === category.value" [routerLink]="['/celebrities', category.value]">

                        <div class="nav-link">{{category.name}}</div>
                    </li>
                </ul>
            </nav>
        </div>
    </div>

    <!-- Mobile Filter -->
    <div class="row mt-4 d-md-none mobile-filter-container">
        <div class="col-12">
            <mat-select class="btn btn-alt category-select" panelClass="app-dropdown-panel" [(ngModel)]="selectedCategory">
                <mat-select-trigger>
                    <div class="d-flex align-items-center">
                        <div *ngIf="selectedCategory=='All'" class="icon-all-category"></div>
                        <div class="category-name">{{ selectedCategoryName }}</div>
                    </div>
                </mat-select-trigger>
                <mat-option *ngFor="let category of categories;" [value]="category.value" [routerLink]="['/celebrities', category.value]">
                    {{category.name}}
                </mat-option>
            </mat-select>
        </div>
    </div>

    <div class="row mt-3 justify-content-md-center">
        <div class="col-md-6 col-sm-12 left-panel">

            <ng-container *ngFor="let celebrity of leftColumnCelebrities">
                <ng-container *ngTemplateOutlet="nftOfCelebrity; context: {templateCerebrity: celebrity}"></ng-container>
            </ng-container>

        </div>

        <div class="col-md-6 col-sm-12">

            <ng-container *ngFor="let celebrity of rightColumnCelebrities">
                <ng-container *ngTemplateOutlet="nftOfCelebrity; context: {templateCerebrity: celebrity}"></ng-container>
            </ng-container>

        </div>

        <div class="col-12" *ngIf="rightColumnCelebrities.length === 0 && leftColumnCelebrities.length === 0">
            <agmo-loading></agmo-loading>
        </div>

        <ng-template #nftOfCelebrity let-celebrity="templateCerebrity">
            <div *ngIf="celebrity.isReady" class="row mb-4" [ngClass]="celebrity.uiIsHorizontal ? 'horizontal' : 'vertical'">
                <div class="col-9 main-image no-padding-lr" [routerLink]="['/browse', celebrity.assets[0].asset_address, celebrity.assets[0].token_id]">
                    <img src="{{celebrity.assets[0].image_url}}" alt="Main Image" draggable="false">
                </div>

                <div class="col-3 row no-padding-lr sub-image-container">
                    <ng-container *ngFor="let nftAsset of celebrity.assets | slice:1:4">

                        <!-- Default Image for Talent without 4 NFTs, not clickable NFT. -->
                        <ng-container *ngIf="nftAsset.id === -1">
                            <div class="col-12 square-sub-image not-clickable">
                                <img src="{{nftAsset.image_url}}" alt="Sub Image" draggable="false">
                            </div>
                        </ng-container>

                        <ng-container *ngIf="nftAsset.id !== -1">
                            <div class="col-12 square-sub-image" [routerLink]="['/browse', nftAsset.asset_address, nftAsset.token_id]">
                                <img src="{{nftAsset.image_url}}" alt="Sub Image" draggable="false">
                            </div>
                        </ng-container>

                    </ng-container>
                </div>

                <div class="col-12 text-left mt-3 no-padding-lr profile-container" [routerLink]="['/celebrities/celebrity-detail', celebrity.id]">
                    <div class="profile-picture">
                        <img class="profile-image" src="{{celebrity.profilePicture}}" alt="Profile Picture" draggable="false">
                        <img *ngIf="celebrity.isVerified" class="verified-check" src="assets/img/svg-pangolin/Icon-verified-24.svg" alt="Verified" draggable="false">
                    </div>
                    <div class="profile-name">{{celebrity.name}}</div>
                </div>
            </div>
        </ng-template>
    </div>

</div>
