import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { HeyjomLayoutComponent } from 'src/app/layouts/heyjom-layout/heyjom-layout.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'landing-page',
        pathMatch: 'full',
    },
    {
        path: '', // HeyJom is campaign module.
        component: HeyjomLayoutComponent,
        children: [
            {
                path: 'campaign',
                loadChildren: () => import('./layouts/heyjom-layout/heyjom-layout.module').then(m => m.HeyjomLayoutModule)
            }
        ]
    },
    {
        path: '',
        component: AdminLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('./layouts/admin-layout/admin-layout.module').then(m => m.AdminLayoutModule)
            }
        ]
    },
    {
        path: '',
        component: AuthLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('./layouts/auth-layout/auth-layout.module').then(m => m.AuthLayoutModule)
            }
        ]
    },
    {
        path: '**',
        redirectTo: 'landing-page'
    }
];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes, { useHash: true, anchorScrolling: 'enabled' })
    ],
    exports: [
        RouterModule
    ],
})
export class AppRoutingModule { }
