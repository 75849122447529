import { Component, NgZone, OnInit } from '@angular/core';
import { BlockUiType } from '../../../core/enum/general/blockUI-type.enum';
import { OpenseaService } from '../../../core/services/opensea.service';
import { BlockUiService } from '../../../shared/services/blockUi/block-ui.service';
import { AssetFilter } from '../../browse/asset-filter';
import { AssetFilterService } from '../../browse/asset-filter.service';
import { ToastrService } from 'ngx-toastr';
import { BasePageComponent } from 'src/app/pages/base-page/base-page.component';

@Component({
    selector: 'app-user-wallet',
    templateUrl: './user-wallet.component.html',
    styleUrls: ['./user-wallet.component.scss']
})
export class UserWalletComponent extends BasePageComponent implements OnInit {
    hasAccess = false;
    walletAddress = this.openseaService.getWalletAddress();
    assetParams = this.assetFilterService.filter ? this.assetFilterService.filter : new AssetFilter();

    constructor(
        protected openseaService: OpenseaService,
        private assetFilterService: AssetFilterService,
        protected blockUiService: BlockUiService,
        protected ngZone: NgZone,
        private toastr: ToastrService,
    ) {
        super(openseaService, ngZone, blockUiService);
    }

    promptToaster() {
        this.toastr.success('Wallet Address is Copied');
    }

    protected afterValidNetworkOrChain(): void {
        super.afterValidNetworkOrChain();
        this.assetParams.owner = this.walletAddress;
    }

}
