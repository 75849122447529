import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    // accessToken: string;
    // deviceId: string;
    // registrationId: string;

    get accessToken(): string {
        return localStorage.getItem("accessToken");
    }

    set accessToken(accessToken: string) {
        if (accessToken === null)
            localStorage.setItem("accessToken", "");
        else
            localStorage.setItem("accessToken", accessToken);
    }

    get deviceId(): string {
        return localStorage.getItem("deviceId");
    }

    set deviceId(id: string) {
        if (id === null)
            localStorage.removeItem("deviceId");
        else
            localStorage.setItem("deviceId", id);
    }

    get registrationId(): string {
        return localStorage.getItem("registrationId") || "";
    }

    set registrationId(registrationId: string) {
        localStorage.setItem("registrationId", registrationId ? registrationId : "");
    }

    get walletAddress(): string {
        return localStorage.getItem("walletAddress");
    }

    set walletAddress(address: string) {
        localStorage.setItem("walletAddress", address);
    }

    clear(): void {
        this.accessToken = null;
    }
}
