<div class="asset-wrapper" *ngIf="!isLoading && celebrity">
    <div class="asset-container">
        <div class="detail-top row m-0">
            <div class="asset-summary col-12 col-md-5 p-0">
                <div class="item-frame">
                    <div class="media-wrapper">
                        <div class="item-media">
                            <ng-container *ngIf="data.is3dModel === true">
                                <model-viewer autoplay auto-rotate src="{{data.animationOriginalUrl}}"
                                    camera-controls
                                    poster="{{data.imageUrl}}">
                                </model-viewer>
                            </ng-container>

                            <ng-container *ngIf="data.is3dModel === false">

                                <ng-container *ngIf="data.animationOriginalUrl">
                                    <video controlsList="nodownload" controls playsinline autoplay loop
                                        [muted]="'muted'"
                                        src="{{data.animationOriginalUrl}}"
                                        poster="{{data.imageUrl}}"
                                        (click)="viewVideo(data.animationOriginalUrl)">
                                    </video>
                                </ng-container>

                                <ng-container *ngIf="!data.animationOriginalUrl">
                                    <ng-container *ngIf="data.imageUrlOriginal">
                                        <img src="{{data.imageUrlOriginal}}" (click)="viewPhoto(data.imageUrlOriginal)" />
                                    </ng-container>

                                    <ng-container *ngIf="!data.imageUrlOriginal">
                                        <img src="{{data.imageUrl}}" (click)="viewPhoto(data.imageUrl)" />
                                    </ng-container>
                                </ng-container>

                            </ng-container>

                            <ng-container *ngIf="!data.animationOriginalUrl && !data.imageUrl && !data.imageUrlOriginal">
                                <img src="/assets/img/image-placeholder.png" />
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="item-frame dark d-md-flex d-none p-3">
                    <div class="col-4">
                        <img class="celebrity-profile c-pointer" [src]="celebrity.profilePicture" [routerLink]="['/celebrities/celebrity-detail', celebrity.id]" />
                    </div>
                    <div class="col-8 pl-2">
                        <div class="celebrity-name verfied-name c-pointer" [routerLink]="['/celebrities/celebrity-detail', celebrity.id]">
                            {{celebrity.name}}
                            <div class="ml-2 icon-verified"></div>
                        </div>
                        <div class="asset-description">{{celebrity.description}}</div>
                    </div>
                </div>
            </div>
            <div class="asset-main col-12 col-md-7 p-0">
                <div class="row m-0">
                    <div class="col-12 p-0">
                        <div class="item-name">
                            <span>{{data.name}}</span>
                        </div>
                    </div>
                    <div class="capsule-group">
                        <div class="mt-3 ml-3 gradient-capsule">
                            <div class="pl-4">{{celebrity.name}}</div>
                            <img [src]="celebrity.profilePicture" class="left-img" />
                            <div class="icon-verified"></div>
                        </div>
                        <ng-container *ngFor="let category of celebrity.category">
                            <div class="mt-3 ml-3 pl-3 pr-3 gradient-capsule">
                                <div [ngClass]="category">{{category}}</div>
                            </div>
                        </ng-container>
                        <div class="mt-3 ml-3 btn st-custom-button" [ngClass]="{'share-active' : isShare}" (click)="isShare = !isShare">
                            <div class="icon-share"></div>
                            <div>Share</div>
                        </div>
                        <div class="px-3 pt-3" *ngIf="isShare">
                            <share-buttons show="5"></share-buttons>
                        </div>
                    </div>
                    <div class="col-12 py-3 asset-description">
                        {{data.description}}
                    </div>

                    <!-- Owner not listed. -->
                    <div class="col-12 p-0 pb-3 text-center" *ngIf="!data.lastSale && data.sellOrders.length == 0">
                        <button (click)="openBidOfferPopup()" [disabled]="isOwnedByUser" class="w-75 mt-2 btn btn-alt">{{isOwnedByUser ? 'You own this' : 'Make an offer'}}</button>
                    </div>
                    <!-- Owner not listed, but have previous transaction. -->
                    <div class="col-12 p-0" *ngIf="data.lastSale && data.sellOrders.length == 0">
                        <div class="gradient-border current-price">
                            <div class="d-flex pb-3">
                                <div class="w-25">
                                    <div class="label">Sold for</div>
                                    <div class="d-flex">
                                        <div class="price gradient-text horizontal-two-color">{{lastSalePrice | number}} ETH</div>
                                    </div>
                                    <div class="label m-0">${{usdPerETH * lastSalePrice | number}}</div>
                                </div>
                                <div class="w-75 ml-3 pl-3 price-right-wrapper">
                                    <button (click)="openBidOfferPopup()" [disabled]="isOwnedByUser" class="w-100 mt-2 btn btn-alt">{{isOwnedByUser ? 'You own this' : 'Make an offer'}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Listed. -->
                    <div class="col-12 p-0" *ngIf="data.sellOrders.length > 0">
                        <div class="gradient-border current-price">
                            <div class="d-flex pb-3">
                                <div class="w-25">
                                    <div class="label">{{itemIsBidding? 'Current offer' : 'Price'}}</div>
                                    <div class="d-flex">
                                        <div class="price gradient-text horizontal-two-color">{{currentPrice | number}} ETH</div>
                                    </div>
                                    <div class="label m-0">${{usdPerETH * currentPrice | number}}</div>
                                </div>

                                <div class="w-75 ml-3 pl-3 price-right-wrapper">
                                    <!--fixed price-->
                                    <div *ngIf="!itemIsBidding">
                                        <button (click)="openBuyPopup()" [disabled]="isOwnedByUser" class="w-100 btn btn-solid">{{isOwnedByUser ? 'You own this' : 'Buy Now'}}</button>
                                        <button (click)="openBidOfferPopup()" [disabled]="isOwnedByUser" class="w-100 mt-2 btn btn-alt">{{isOwnedByUser ? 'You own this' : 'Make an offer'}}</button>
                                    </div>
                                    <div *ngIf="itemIsBidding">
                                        <div class="label">Auction ending in</div>
                                        <div class="row" *ngIf="timeRemaining">
                                            <div class="col-3">
                                                <div class="price gradient-text horizontal-two-color">{{timeRemaining.daysToDday}}</div>
                                                <div class="label m-0">Day(s)</div>
                                            </div>
                                            <div class="col-3">
                                                <div class="price gradient-text horizontal-two-color">{{timeRemaining.hoursToDday}}</div>
                                                <div class="label m-0">Hours</div>
                                            </div>
                                            <div class="col-3">
                                                <div class="price gradient-text horizontal-two-color">{{timeRemaining.minutesToDday}}</div>
                                                <div class="label m-0">Minutes</div>
                                            </div>
                                            <div class="col-3">
                                                <div class="price gradient-text horizontal-two-color">{{timeRemaining.secondsToDday}}</div>
                                                <div class="label m-0">Seconds</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button (click)="openBidOfferPopup()" *ngIf="itemIsBidding" [disabled]="isOwnedByUser" class="w-100 btn btn-solid">{{isOwnedByUser ? 'You own this' : 'Place a bid'}}</button>
                        </div>
                    </div>

                    <div class="item-frame dark d-flex d-md-none p-3">
                        <div class="col-4">
                            <img class="celebrity-profile" [src]="celebrity.profilePicture" />
                        </div>
                        <div class="col-8 pl-2">
                            <div class="celebrity-name">{{celebrity.name}}</div>
                            <div class="asset-description">{{celebrity.description}}</div>
                        </div>
                    </div>
                    <div class="pb-4 col-menu col-12 horizontal-menu">
                        <div class="menu">
                            <ng-container *ngFor="let tab of tabs; let i = index">
                                <div class="menu-item" [ngClass]="{'active': selectedTab == i}" (click)="selectedTab = i">
                                    {{tab.name}}
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <!-- Offer Tab -->
                    <ng-container *ngIf="selectedTab == 0">
                        <div class="col-12" *ngIf="offers">
                            <div *ngFor="let offer of offers; let i = index">
                                <div class="item-frame dark d-flex p-3 mx-0 align-items-center">
                                    <div class="pl-2">
                                        <div>
                                            Offer {{offer.currentUnitPrice}} wETH by {{truncateMiddleText(offer.makerAccount.address)}}
                                        </div>
                                        <div>
                                            at {{offer.createdDate | amDateFormat: 'D/M/YYYY, h:mm A'}}
                                        </div>
                                    </div>
                                    <div *ngIf="isOwnedByUser" class="offer-accept-container">
                                        <button class="w-50 btn btn-solid" (click)="acceptOffer(i)">Accept</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12" *ngIf="!offers">
                            <div class="item-frame dark text-center p-3 mx-0">
                                <img src="/assets/img/svg-pangolin/Icon-ghost-56.svg" />
                                <div class="pt-2">oOoO no offers yet. Be the first</div>
                                <div>to make an offer!</div>
                            </div>
                        </div>
                    </ng-container>
                    <!-- Offer Tab -->
                    <ng-container *ngIf="selectedTab == 1">
                        <div class="pt-3 pl-3 py-1">
                            <div class="detail-title">
                                Contract Address
                            </div>
                            <div class="magenta-text">
                                {{truncateMiddleText(data.assetContract.address)}}
                                <a target="_blank" href="{{etherscanEndpoint}}address/{{walletAddress}}">
                                    <div class="icon-etherscan"></div>
                                </a>
                            </div>
                        </div>
                        <div class="pl-3 py-1">
                            <div class="detail-title">
                                Token ID
                            </div>
                            <div class="asset-details text-break">
                                {{data.tokenId}}
                            </div>
                        </div>
                        <div class="pl-3 py-1">
                            <div class="detail-title">
                                Blockchain
                            </div>
                            <div class="asset-details">
                                Ethereum
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="selectedTab == 2">
                        <div class="col-12" *ngIf="tradingHistory">
                            <div class="item-frame dark d-flex p-3 mx-0" *ngFor="let item of tradingHistory">
                                <div class="pl-2">
                                    <div *ngIf="item.eventType == historyType.List">
                                        Listed by {{truncateMiddleText(item.fromAccount.address)}}
                                    </div>
                                    <div *ngIf="item.eventType == historyType.Cancel">
                                        Cancelled listing {{truncateMiddleText(item.transaction.from_account.address)}}
                                    </div>
                                    <div *ngIf="item.eventType == historyType.Offer">
                                        Offer {{item.currentUnitPrice}} wETH by {{truncateMiddleText(item.fromAccount.address)}}
                                    </div>
                                    <div *ngIf="item.eventType == historyType.Bid">
                                        Bid {{item.currentUnitPrice}} wETH by {{truncateMiddleText(item.fromAccount.address)}}
                                    </div>
                                    <div *ngIf="item.eventType == historyType.BidCancelled">
                                        Cancelled bid by {{truncateMiddleText(item.fromAccount.address)}}
                                    </div>
                                    <div *ngIf="item.eventType == historyType.Transfer">
                                        Transferred to {{truncateMiddleText(item.toAccount.address)}}
                                    </div>
                                    <div *ngIf="item.eventType == historyType.Sale">
                                        Purchased for {{item.currentUnitPrice}} ETH by {{truncateMiddleText(item.transaction.to_account.address)}}
                                    </div>
                                    <div>
                                        at {{item.createdDate | amDateFormat: 'D/M/YYYY, h:mm A'}}
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isHistoryHasMore" class="text-center">
                                <button class="btn btn-alt" (click)="loadMoreHistory()">Load More</button>
                            </div>
                        </div>
                        <div class="col-12" *ngIf="!tradingHistory">
                            <div class="item-frame dark text-center p-3 mx-0">
                                <img src="/assets/img/svg-pangolin/Icon-ghost-56.svg" />
                                <div class="pt-2">oOoO no trading history yet.</div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="mx-3 d-flex justify-content-between">
            <div class="more-title">
                More from this creator
            </div>
            <div class="view-all-container">
                <a class="btn btn-magenta" [routerLink]="['/celebrities', 'celebrity-detail', ownerId]">
                    View all
                </a>
            </div>
        </div>
        <div *ngIf="isShowCollection">
            <app-asset-listing [params]="assetParams" [filters]="assetFilters"></app-asset-listing>
        </div>
    </div>
</div>

<div *ngIf="isLoading">
    <agmo-loading></agmo-loading>
</div>
