import { Component, OnInit } from '@angular/core';
import { FirebaseService } from '../../core/services/firebase.service';
import { FaqCategory } from '../../core/models/faq-category';
import { Faq } from '../../core/models/faq';
import { Dropdown } from '../../core/models/dropdown';

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
    faqCategories: FaqCategory[];
    faqDropdown: Dropdown[];
    faqs: Faq[];
    selectedCategory: string;
    selectedCategoryName: string;

    constructor(
        private firebaseService: FirebaseService
    ) { }

    ngOnInit(): void {
        this.getFaqCategory();
    }

    getFaqCategory(reload?: boolean) {
        this.firebaseService.getFaqCategory().subscribe(response => {
            this.faqCategories = response;
            this.faqDropdown = response.map(item => {
                return {
                    name: item.name,
                    value: item.id
                }
            });

            if (!reload) {
                this.selectedCategory = this.faqCategories[0].id;
                this.selectedCategoryName = this.faqCategories[0].name;
                this.getFaqListing();
            }
        }, err => {
            console.log(err);
        })
    }

    getFaqListing() {
        this.firebaseService.getFaqByCategory(this.selectedCategory).subscribe(response => {
            this.faqs = response.filter(faq => faq.isPublished);
        })
    }

    filterListing(categoryId: string) {
        this.selectedCategory = categoryId;
        this.selectedCategoryName =  this.faqCategories.find(category => category.id == categoryId).name;
        this.getFaqListing();
    }
}
