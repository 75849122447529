<div class="login-container">
    <div class="login-wrapper">
        <p class="message">{{isLoggedIn ? 'You have logged in to your wallet' : 'Sign in to your wallet'}}</p>
        <div class="login-logo-container">
            <div class="logo-wrapper">
                <img class="logo" src="assets/img/metamask-logo.webp" />
            </div>
        </div>
        <div class="action-wrapper">
            <div class="btn btn-alt" (click)="connectMetamask()">
                {{isLoggedIn ? 'Browse' : 'Sign In'}}
            </div>
        </div>
    </div>
</div>

