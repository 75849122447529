<div class="navigation-wrapper container-fluid">
    <nav class="nav-main">
        <div class="nav-left">
            <a class="nav-brand c-pointer" (click)="routeToPage('/landing-page')">
                <img class="logo d-none d-md-flex" src="/assets/img/svg-pangolin/Logo-NFTPangolin-horizontal.svg" />
                <img class="logo d-md-none" src="/assets/img/svg-pangolin/Logo-NFTPangolin-vertical.svg" />
            </a>
            <div class="nav-brand" [routerLink]="['/campaign']">
                <img class="logo d-none d-md-flex heyjom-logo"  *ngIf="heyJomDesktopLogo" src="{{heyJomDesktopLogo}}" alt="HeyJom Desktop Logo">
                <img class="logo d-md-none heyjom-logo" *ngIf="heyJomMobileLogo" src="{{heyJomMobileLogo}}" alt="HeyJom Mobile Logo">
            </div>
        </div>
        <ul class="nav-items d-none d-md-flex">
            <li>
                <a class="item c-pointer" [routerLink]="['/celebrities']">Celebrities</a>
            </li>
            <li>
                <a class="item c-pointer" (click)="routeToPage('how-to-get-started')">Explore</a>
            </li>
            <li *ngIf="!isConnected" class="align-self-center">
                <button class="btn btn-solid" (click)="connectWallet()">Connect Wallet</button>
            </li>
            <li *ngIf="isConnected" class="c-pointer nav-item align-self-center" ngbDropdown
                placement="bottom-right">
                <a class="nav-link pr-0" role="button" ngbDropdownToggle>
                    <div class="gradient-capsule">
                        <div>{{walletAddress}}</div>
                        <img class="right-img" src="/assets/img/svg-pangolin/Icon-profile-photo-64.svg" />
                    </div>
                </a>
                <div class="dropdown-menu-arrow dropdown-menu-right" ngbDropdownMenu>
                    <div class="dropdown-header noti-title">
                        <h2 class="header-text m-0">{{walletAddress}}</h2>
                    </div>
                    <div class="balance-title">
                        <div>Balance</div>
                        <span class="balance-amount">{{walletBalance | number}} ETH</span>
                    </div>
                    <div class="dropdown-divider"></div>
                    <a routerLinkActive="active"  (click)="routeToPage('/profile')" class="dropdown-item">
                        <span>Profile</span>
                    </a>
                    <a routerLinkActive="active" (click)="routeToPage('/setting')" class="dropdown-item">
                        <span>Settings</span>
                    </a>
                </div>
            </li>
        </ul>
        <ul class="nav-items d-md-none">
            <li class="align-self-center">
                <div class="navbar-action" (click)="isDrop = !isDrop">
                    <span class="nav-icon rounded-circle">
                        <span *ngIf="!isDrop" class="icon-menu"></span>
                        <span *ngIf="isDrop" class="icon-gradient-close"></span>
                    </span>
                    <span class="item">
                        {{isDrop ? 'Close' : 'Menu'}}
                    </span>
                </div>
            </li>
        </ul>
    </nav>
</div>

<ng-container *ngIf="isDrop && !isSearch">
    <div class="container-fluid menu-drop-container">
        <div class="menu-holder">
            <div class="wallet-info-holder" *ngIf="isConnected">
                <div class="dropdown-header noti-title">
                    <h2 class="header-text m-0">{{walletAddress}}</h2>
                </div>
                <div class="balance-title">
                    <div>Balance</div>
                    <span class="balance-amount">{{walletBalance | number}} ETH</span>
                </div>
            </div>
            <ul class="nav-items drop-items">
                <li class="item">
                    <a class="menu-item" routerLinkActive="active" (click)="routeToPage('celebrities')">Celebrities</a>
                </li>
                <li class="item">
                    <a class="menu-item" routerLinkActive="active" (click)="routeToPage('/how-to-get-started')">Explore</a>
                </li>
            </ul>

        </div>
    </div>
    <div class="menu-footer container-fluid">
        <div class="social-icon justify-content-center pt-2 pb-2 ">
            <a href="https://www.agmostudio.com/" target="_blank">
                <div class="icon-facebook"></div>
            </a>
            <a href="https://www.agmostudio.com/#183" target="_blank">
                <div class="icon-twitter"></div>
            </a>
            <a href="https://www.agmostudio.com/blog/" target="_blank">
                <div class="icon-instagram"></div>
            </a>
        </div>
        <div *ngIf="!isConnected" class="w-100">
            <button class="btn btn-solid w-100" (click)="connectWallet()">Connect Wallet</button>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="isSearch">
    <div class="container-fluid menu-drop-container search-container">
        <div class="menu-holder">
            <div class="text-center" *ngIf="!control.value && !collectiblesResults">
                Search by creator or collectible
            </div>
            <div class="text-center" *ngIf="control.value != '' && collectiblesResults?.length == 0">
                <div class="text-white">No results found</div>
                <div>We couldn't find what you're looking for</div>
            </div>
            <div *ngIf="control.value != '' && celebritiesResults?.length > 0">
                <div class="text-white font-weight-bold pl-2 pb-3">Creators</div>
                <div>
                    <ul class="nav-items drop-items">
                        <li class="item" *ngFor="let result of celebritiesResults">
                            <a class="menu-item w-100 d-flex" (click)="mobileCloseDrop()"
                               [routerLink]="['/celebrities/celebrity-detail', result.id]">
                                <img class="mobile-celebrity-image mr-3" [src]="result.profilePicture" />
                                <div class="option-detail-column">
                                    <div class="asset-name">{{result.name}}</div>
                                    <div class="asset-creator">by {{result.name}}</div>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div *ngIf="control.value != '' && collectiblesResults?.length > 0">
                <div class="text-white font-weight-bold pl-2 pb-3">Collectibles</div>
                <div>
                    <ul class="nav-items drop-items">
                        <li class="item" *ngFor="let result of collectiblesResults">
                            <a class="menu-item w-100 d-flex" (click)="mobileCloseDrop()"
                               [routerLink]="['/browse', result.tokenAddress, result.tokenId]">
                                <img class="mobile-collectible-image mr-3" [src]="result.imageUrl" />
                                <div class="option-detail-column">
                                    <div class="asset-name">{{result.name}}</div>
                                    <div class="asset-creator">by {{result.creator.user.username}}</div>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="control.value" class="menu-footer container-fluid">
        <div class="w-100">
            <a class="w-100 btn btn-alt" (click)="mobileCloseDrop()" [routerLink]="['/browse']"
               [queryParams]="{ searchText: control.value}">All results</a>
        </div>
    </div>
</ng-container>
