import { Component, OnInit, Input } from '@angular/core';
import { AssetFilter } from '../browse/asset-filter';
import { AssetSort } from '../../core/enum/asset/asset-sort.enum';
import { SortDirection } from '../../core/enum/general/sort-direction.enum';
import { OpenseaService } from '../../core/services/opensea.service';
import { Asset } from '../../core/models/opensea/opensea-asset'
import * as moment from 'moment';
import { TimeService } from '../../core/services/time.service';
import { FirebaseService } from '../../core/services/firebase.service';
import { ClientSideFilter } from './client-side-filter';
import { Subject } from 'rxjs';
import { FlexDataObservable } from 'src/app/pages/asset-listing/flex-gallery/flex-gallery.model';
import { Celebrity } from 'src/app/core/models/celebrity';

@Component({
    selector: 'app-asset-listing',
    templateUrl: './asset-listing.component.html',
    styleUrls: ['./asset-listing.component.scss']
})
export class AssetListingComponent implements OnInit {
    @Input() params: AssetFilter;
    @Input() currentPage: string;
    @Input() filters: ClientSideFilter;
    isLoading = false;
    data = [];
    isExpanded = false;
    haveMore = true;
    isOpenFilter = false;
    orderByOptions = [
        {
            name: 'All',
            value: {
                orderBy: AssetSort.saleDate,
                sortBy: SortDirection.desc
            }
        },
        {
            name: 'Recently sold',
            value: {
                orderBy: AssetSort.saleDate,
                sortBy: SortDirection.desc
            }
        },
        {
            name: 'Highest price',
            value: {
                orderBy: AssetSort.salePrice,
                sortBy: SortDirection.desc
            }
        },
        {
            name: 'Lowest price',
            value: {
                orderBy: AssetSort.salePrice,
                sortBy: SortDirection.asc
            }
        },
    ];
    selectedOption = 0;
    selectedPriceFilter = 'ETH';

    creatorFilter: string[] = [];
    categoryFilter: string[] = [];
    creators = [];
    categories = [];
    isCreatorExpand = false;
    isCategoryExpand = false;
    minPrice: number;
    maxPrice: number;
    usdPerETH: number;

    nftData$: Subject<FlexDataObservable> = new Subject();

    private filterCategoryCelebrities: {[key: string]: Celebrity[]} = {};

    constructor(
        private openseaService: OpenseaService,
        private firebaseService: FirebaseService,
        private timeService: TimeService
    ) { }

    ngOnInit(): void {
        this.initFilters();
        this.getUsdPerEth();
        this.getAssets(false);
    }

    initFilters() {
        this.firebaseService.getCelebrityListingByCategoryNew('All').subscribe(response => {
            this.creators = response.map(e => {
                return {
                    name: e.name,
                    value: e.walletAddress,
                    isSelected: false,
                }
            });
        });

        this.firebaseService.getCelebrityCategory().subscribe(response => {
            this.categories = response.map(e => {
                return {
                    name: e.name,
                    value: e.value,
                    isSelected: false,
                }
            });

            this.categories.forEach(
                categoryDropdown => {
                    this.firebaseService.getCelebrityListingByCategoryNew(categoryDropdown.value).subscribe(
                        response => {
                            this.filterCategoryCelebrities[categoryDropdown.value] = response;
                        }
                    );
                }
            );
        });

        if (this.filters) {
            if (this.filters.creators)
                this.creatorFilter = this.filters.creators;
        }
    }

    getUsdPerEth() {
        this.openseaService.getETHPrice().subscribe(response => {
            this.usdPerETH = response.USD;
        })
    }

    getAssets(isReload?: boolean) {
        this.isLoading = true;
        if (isReload) {
            this.data = [];
            this.params.offset = 0;
            this.haveMore = true;
        }

        if(this.creatorFilter.length === 1) {
            this.params.owner = this.creatorFilter[0];
        }
        

        this.openseaService.getAssetsHttp(this.params).subscribe(response => {
            if (this.params.limit != response.assets.length) {
                this.haveMore = false;
            }

            let assetList = response.assets.map(e => { return new Asset(e) });
            let filteredList = assetList;

            if (this.minPrice >= 0 && this.maxPrice >= 0) {
                filteredList = filteredList.filter(e => e.sellOrders != null);

                if (this.selectedPriceFilter == 'ETH') {
                    filteredList = filteredList.filter(e => e.sellOrders.some(order => this.getCurrentPrice(order) >= this.minPrice && this.getCurrentPrice(order) <= this.maxPrice))
                } else {
                    filteredList = filteredList.filter(e => e.sellOrders.some(order => this.getCurrentUsdPrice(order) >= this.minPrice && this.getCurrentUsdPrice(order) <= this.maxPrice))
                }
            }

            let includedCelebrityWallets: string[] = this.getSelectedCelebrityWallets();
            if (includedCelebrityWallets.length > 0) {
                filteredList = filteredList.filter(e => includedCelebrityWallets.includes(e.creator.address));
            }

            if (this.filters?.excludeAssetID) {
                filteredList = filteredList.filter(e => this.filters.excludeAssetID !== e.id);
            }

            this.data = this.data.concat(filteredList);
            if (this.data.length == 0 && this.haveMore) {
                setTimeout(() => this.getMore(), 1000);
            }

            this.nftData$.next({ nfts: filteredList, isReload: isReload });
        }, err => {
            console.log(err);
        }).add(() => {
            this.isLoading = false;
        })
    }

    selectCategory(index: number, event: any) {
        if (this.categories[index].isSelected) {
            this.categoryFilter.push(this.categories[index].value);
        } else {
            this.categoryFilter = this.categoryFilter.filter(e => e != this.categories[index].value);
        }

        this.getAssets(true);
    }

    selectCreator(index: number, event: any) {

        this.creators.forEach((element, i) => {
            if(i == index) {
                if (this.creators[index].isSelected) {
                    this.creatorFilter = [this.creators[index].value];
                    this.params.owner = this.creators[index].value
                    
                } else {
                    this.creatorFilter = [];
                    delete this.params.owner;
                }
            }
            else {
                element.isSelected = false;
            }
        });

        

        this.getAssets(true);
    }

    isVideo(src: string) {
        let extension = src.split('.').pop();

        if (extension == ('mp4' || 'webm')) {
            return true;
        }
        else return false;
    }

    getCurrentPrice(sellOrder: any) {
        return (parseFloat(sellOrder.current_price) / Math.pow(10, sellOrder.payment_token_contract.decimals)) / parseInt(sellOrder.quantity);
    }

    getCurrentUsdPrice(sellOrder: any) {
        return this.getCurrentPrice(sellOrder) * this.usdPerETH;
    }

    getLastSale(lastSale: any) {
        return parseInt(lastSale.total_price) / Math.pow(10, lastSale.payment_token.decimals);
    }

    getMore() {
        this.params.offset += this.params.limit;
        this.getAssets(false);
    }

    getTimeRemaining(asset: Asset) {
        let endDateMoment = moment(asset.sellOrders[0].closing_date).add(8, 'hours').toString();
        let timeDiff = this.timeService.getTimeDifference(new Date(endDateMoment));
        return `${timeDiff.daysToDday}d ${timeDiff.hoursToDday}h ${timeDiff.minutesToDday}m ${timeDiff.secondsToDday}s left`;
    }

    filterListing() {
        this.params.order_direction = this.orderByOptions[this.selectedOption].value.sortBy;
        this.params.order_by = this.orderByOptions[this.selectedOption].value.orderBy;

        // All
        if (this.selectedOption === 0) {
            delete(this.params.order_direction);
            delete(this.params.order_by);
        }

        this.getAssets(true);
    }

    processPriceFilter() {
        this.isOpenFilter = false;
        this.getAssets(true);
    }

    private getSelectedCelebrityWallets(): string[] {
        let coexistedWallets: string[] = [];
        this.categoryFilter.forEach(
            filter => {
                coexistedWallets = coexistedWallets.concat(this.filterCategoryCelebrities[filter].map(celebrity => celebrity.walletAddress));
            }
        );

        coexistedWallets = coexistedWallets.concat(this.creatorFilter);
        coexistedWallets = coexistedWallets.filter((item, index) => coexistedWallets.indexOf(item) === index);

        return coexistedWallets;
    }

}
