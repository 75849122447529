import { OpenSeaFungibleToken, OpenSeaAccount } from 'opensea-js/lib/types';
import { OpenSeaAssetEventType } from '../../enum/asset/opensea-asset-event-enum';
import { Moment } from 'moment';
import { BaseClass } from '../base-class';
import { Asset } from './opensea-asset';

export class OpenSeaEvent extends BaseClass {
    asset: Asset;
    bidAmount: string;
    paymentTokenContract: OpenSeaFungibleToken;
    quantity: number;
    fromAccount: OpenSeaAccount;
    toAccount: OpenSeaAccount;
    currentUnitPrice: any;
    eventType: string;
    createdDate: Moment;
    transaction: any;
    contractAddress: any;

    constructor(model: any) {
        super();
        this.asset = model.asset;
        this.bidAmount = model.bid_amount;
        this.paymentTokenContract = model.payment_token;
        this.quantity = model.quantity ? parseInt(model.quantity): 1;
        this.fromAccount = model.from_account;
        this.toAccount = model.to_account;
        this.eventType = model.event_type;
        this.createdDate = this.toMoment(model.created_date);
        this.transaction = model.transaction;
        this.contractAddress = model.contract_address

        if (model.event_type == OpenSeaAssetEventType.Transfer) {
            this.currentUnitPrice = 0;
        }
        else if (model.event_type == OpenSeaAssetEventType.Sale || model.event_type == OpenSeaAssetEventType.Cancel) {
            this.currentUnitPrice = (model.total_price / Math.pow(10, model.payment_token.decimals)) / this.quantity;
        } else {
            this.currentUnitPrice = (model.bid_amount / Math.pow(10, model.payment_token.decimals)) / this.quantity;
        }
    }
}
