import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { PopupType } from './popup-type.enum';

@Component({
    selector: 'agmo-popup-modal',
    templateUrl: './popup-modal.component.html',
    styleUrls: ['./popup-modal.component.scss']
})
export class PopupModalComponent {
    PopupType: typeof PopupType = PopupType;
    type: PopupType;
    message: string;
    okLabel: string;
    cancelLabel: string;
    okClass: string;
    cancelClass: string;
    titleLabel: string;

    constructor(public activeModal: NgbActiveModal) { }

    ok(): void {
        this.activeModal.close();
    }

    cancel(): void {
        this.activeModal.dismiss();
    }
}
