import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { OrderSide } from 'opensea-js/lib/types';
import { PopupService } from 'src/app/shared/services/popup/popup.service';
import { environment as env } from '../../../../../environments/environment';
import { ModalType } from '../../../../core/enum/modal/modal.enum';
import { Asset } from '../../../../core/models/opensea/opensea-asset';
import { OrderObj } from '../../../../core/models/opensea/opensea-orders';
import { OpenseaService } from '../../../../core/services/opensea.service';

@Component({
    selector: 'app-purchase-modal',
    templateUrl: './purchase-modal.component.html',
    styleUrls: ['./purchase-modal.component.scss']
})
export class PurchaseModalComponent implements OnInit {
    isLoading = false;
    transactionLoading = false;
    showFormError = false;
    modalType: ModalType;
    orderObject: OrderObj[];
    asset: Asset;
    celebrityName: string;
    isConvertPage = false;
    isDepositPage = false;
    uniswapSrc: string;
    form: FormGroup;
    availableWETH: string;
    availableETH: number = 0;
    currentUnitPrice: number = 0;
    walletAddress = this.openseaService.getWalletAddress();
    curDate = moment().add(1, 'day').set({ hour: 0, minute: 0, second: 0 }).toISOString();
    expirationDate = moment().add(1, 'day').toISOString();
    offerEndTime: string;
    expirationOption = [
        { name: '7 days', value: 0 },
        { name: '3 days', value: 1 },
        { name: 'A month', value: 2 },
        { name: 'Custom Date', value: 3 },
    ];
    selectedOption = 0;
    usdPerETH: number;
    transactionHash: string;
    transactionComplete: boolean;
    etherscanEndpoint = env.etherscanEndpoint;

    constructor(
        private modal: NgbActiveModal,
        private openseaService: OpenseaService,
        private fb: FormBuilder,
        private router: Router,
        private popupService: PopupService,
    ) { }

    ngOnInit(): void {
        this.getUsdPerETH();
        this.getWETHBalance();
        this.getETHBalance();
        if (this.modalType != ModalType.Checkout)
            this.createForm();
    }

    getUsdPerETH() {
        this.openseaService.getETHPrice().subscribe(response => {
            this.usdPerETH = response.USD;
        });
    }

    getETHBalance() {
        this.isLoading = true;
        this.openseaService.getBalance().then(result => {
            this.availableETH = result;
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            this.isLoading = false;
        });
    }

    getWETHBalance() {
        this.isLoading = true;
        this.openseaService.getWethBalance().then(result => {
            this.availableWETH = result;
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            this.isLoading = false;
        });
    }

    createForm() {
        if (this.orderObject) {
            this.currentUnitPrice = this.orderObject[0].currentUnitPrice;
        }

        this.form = this.fb.group({
            Price: [this.currentUnitPrice.toString().match(/^-?\d+(?:\.\d{0,4})?/)[0], Validators.required]
        });

        
        this.offerEndTime = moment().format('HH:mm');
        
    }

    checkout() {
        this.transactionLoading = true;
        this.openseaService.checkout(this.asset.assetContract.address, this.asset.tokenId, OrderSide.Sell).then(result => {
            this.transactionComplete = true;
            this.transactionHash = result;
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            this.transactionLoading = false;
        });
    }

    placeBid() {
        if (!this.form.valid) {
            this.showFormError = true;
            return;
        }

        let param: any = {
            asset: {
                tokenId: this.asset.tokenId,
                tokenAddress: this.asset.tokenAddress,
                schemaName: this.asset.assetContract.schema_name
            },
            accountAddress: this.openseaService.getWalletAddress(),
            startAmount: this.form.controls['Price'].value,
            referrerAddress: env.referrerAddress
        };

        this.transactionLoading = true;
        this.openseaService.createBuyOrder(param).then(result => {
            this.transactionComplete = true;
        }).catch(err => {
            this.popupService.alert(err.message);
        }).finally(() => {
            this.transactionLoading = false;
        })
    }

    makeAnOffer(expirationTimeEpoch: number) {
        let param: any = {
            asset: {
                tokenId: this.asset.tokenId,
                tokenAddress: this.asset.tokenAddress,
                schemaName: this.asset.assetContract.schema_name
            },
            accountAddress: this.openseaService.getWalletAddress(),
            startAmount: this.form.controls['Price'].value,
            referrerAddress: env.referrerAddress,
            expirationTime: expirationTimeEpoch
        };

        this.transactionLoading = true;
        this.openseaService.createBuyOrder(param).then(result => {
            this.transactionComplete = true;
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            this.transactionLoading = false;
        })
    }

    processExpirationTime() {
        let momentObj: moment.Moment;

        if (this.selectedOption == 0) {
            momentObj = moment(moment().add(7, 'days').format("MMM DD YY") + " " + this.offerEndTime);
        }
        else if (this.selectedOption == 1) {
            momentObj = moment(moment().add(3, 'days').format("MMM DD YY") + " " + this.offerEndTime);
        }
        else if (this.selectedOption == 2) {
            momentObj = moment(moment().add(1, 'month').format("MMM DD YY") + " " + this.offerEndTime);
        }
        else if (this.selectedOption == 3) {
            momentObj = moment(moment(this.expirationDate).format("MMM DD YY") + " " + this.offerEndTime);
        }

        this.makeAnOffer(momentObj.valueOf() / 1000);
    }

    toggleConvert() {
        this.isConvertPage = !this.isConvertPage;
        let uniswapURL = 'https://app.uniswap.org/#/swap?use=v1?';
        if (this.isConvertPage) {
            this.uniswapSrc = `${uniswapURL}outputCurrency=${env.mainnWETHAddress}&theme=light&exactAmount=${this.form.controls['Price'].value}&exactField=output`;
        }
        else {
            this.getWETHBalance();
        }
    }

    routePage(modalType: number) {
        this.modal.dismiss();
        this.router.navigate(['/profile/', modalType]);
    }

    close() {
        this.modal.dismiss();
    }

    get price() { return this.form.get('Price') }
}
