import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { PagedResult } from '../models/paged-result/paged-result';

@Injectable()
export class HttpResponseInterceptor implements HttpInterceptor {
    constructor(private router: Router) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(req).pipe(
            map(event => {
                if (event instanceof HttpResponse) {
                    if (event.headers.get("x-total-count")) {
                        return event.clone({
                            body: new PagedResult(event.body, parseInt(event.headers.get("x-total-count")))
                        });
                    }

                    return event;
                }

                return event;
            }),
            catchError((err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 404 && err.error && err.error.ErrorCode == 5) {
                        this.router.navigate(['login']);
                    }

                    if (err.error) {
                        return throwError(err.error);
                    }

                    return throwError({ ErrorMessage: "Application Error. Please report to our engineer." });
                }

                return throwError({ ErrorMessage: "Application Error. Please report to our engineer." });
            })
        );
    }
}
