import { Component, NgZone } from '@angular/core';
import { environment as env } from '../../../../environments/environment';
import { OpenSeaAssetEventType } from '../../../core/enum/asset/opensea-asset-event-enum';
import { OpenSeaEvent } from '../../../core/models/opensea/opensea-event';
import { OpenseaService } from '../../../core/services/opensea.service';
import { BlockUiService } from '../../../shared/services/blockUi/block-ui.service';
import { AssetEventsFilter } from '../../asset-detail/asset-events-filter';
import { ToastrService } from 'ngx-toastr';
import { BasePageComponent } from 'src/app/pages/base-page/base-page.component';

@Component({
    selector: 'app-user-activity',
    templateUrl: './user-activity.component.html',
    styleUrls: ['./user-activity.component.scss']
})
export class UserActivityComponent extends BasePageComponent {
    hasAccess = false;
    walletAddress = this.openseaService.getWalletAddress();
    etherscanEndpoint = env.etherscanEndpoint;

    loadTrading = false;

    tradingHistory: OpenSeaEvent[] = [];
    tradingHistoryFilter: AssetEventsFilter;
    tradingFilterOption = [
        { name: 'All', value: OpenSeaAssetEventType.All },
        { name: 'Listing', value: OpenSeaAssetEventType.List },
        { name: 'Sales', value: OpenSeaAssetEventType.Sale },
        { name: 'Offer', value: OpenSeaAssetEventType.Offer },
        { name: 'Bids', value: OpenSeaAssetEventType.Bid },
        { name: 'Bids Cancelled', value: OpenSeaAssetEventType.BidCancelled },
        { name: 'Transfers', value: OpenSeaAssetEventType.Transfer },
    ];
    selectedOption = OpenSeaAssetEventType.All;

    tradingTableDisplayColumns = ['Event', 'Item', 'UnitPrice', 'Quantity', 'From', 'To', 'Date'];

    constructor(
        protected openseaService: OpenseaService,
        protected blockUiService: BlockUiService,
        protected ngZone: NgZone,
        private toastr: ToastrService
    ) {
        super(openseaService, ngZone, blockUiService);
    }

    getTradingHistory(reload?: boolean) {
        this.loadTrading = true;
        if (reload) {
            
            this.tradingHistory = [];
        }

        this.openseaService.getEvents(this.tradingHistoryFilter).subscribe(response => {
            this.tradingHistory = this.tradingHistory.concat(response.data);
        }, err => {
            console.log(err)
            if (err) {
                if (err.detail) {
                    console.log(err.detail);
                }
                else {
                    console.log(err);
                }
            }
        }).add(() => {
            this.loadTrading = false;
        });
    }

    onTradingHistoryScroll(e) {
        const tableViewHeight = e.target.offsetHeight;
        const tableScrollHeight = e.target.scrollHeight;
        const scrollLocation = e.target.scrollTop;
        const buffer = 1;
        const limit = tableScrollHeight - tableViewHeight - buffer;

        if (scrollLocation > limit) {

            this.getTradingHistory();
        }
    }

    proccessTradingFilter() {
        if (this.selectedOption == OpenSeaAssetEventType.All) {
            this.tradingHistoryFilter = new AssetEventsFilter();
            this.tradingHistoryFilter.account_address = this.walletAddress;
        }
        else {
            this.tradingHistoryFilter.event_type = this.selectedOption;
        }

        this.getTradingHistory(true);
    }

    getTradingEventName(value: any) {
        for (let enumMember in OpenSeaAssetEventType) {
            if (value == OpenSeaAssetEventType[enumMember])
                return enumMember;
        }
    }

    isVideo(src: string) {
        let extension = src.split('.').pop();

        if (extension == ('mp4' || 'webm')) {
            return true;
        }
        else return false;
    }

    truncateMiddleText(str: string) {
        return str.substr(0, 6) + '...' + str.substr(str.length - 4, str.length)
    }

    promptToaster() {
        this.toastr.success('Wallet Address is Copied');
    }

    protected afterValidNetworkOrChain(): void {
        super.afterValidNetworkOrChain();
        this.proccessTradingFilter();
    }

}
