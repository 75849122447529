import { Component, NgZone, OnInit } from '@angular/core';
import { BlockUiType } from '../../../core/enum/general/blockUI-type.enum';
import { OpenseaService } from '../../../core/services/opensea.service';
import { BlockUiService } from '../../../shared/services/blockUi/block-ui.service';
import { ProfileMenu } from '../../../core/enum/asset/profile-menu.enum';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FirebaseService } from '../../../core/services/firebase.service';
import { ToastrService } from 'ngx-toastr';
import { ContactForm } from '../../../core/models/contact-form';
import { User } from '../../../core/models/user';
import { LoaderService } from '../../../shared/services/loader/loader.service';
import { PopupService } from '../../../shared/services/popup/popup.service';

@Component({
    selector: 'app-general',
    templateUrl: './general.component.html',
    styleUrls: ['./general.component.scss']
})
export class GeneralComponent implements OnInit {
    hasAccess = false;
    walletAddress = this.openseaService.getWalletAddress();
    ProfileMenu = ProfileMenu;
    ProfileMenuSelection: ProfileMenu;
    ProfileMenuOptions = [
        { label: ProfileMenu[ProfileMenu.Collected], value: 1 },
        { label: ProfileMenu[ProfileMenu.Activity], value: 2 },
    ];
    selectedTab: number = 1;
    form: FormGroup;
    showFormError: boolean = false;
    user: User;

    private readonly nameReg: RegExp = /^[\w\ `@\(\)\'\/\,\.\-]+$/;
    private readonly usernameReg: RegExp = /^[a-zA-Z0-9]*$/;

    constructor(
        private fb: FormBuilder,
        private openseaService: OpenseaService,
        private firebaseService: FirebaseService,
        private toastr: ToastrService,
        private loaderService: LoaderService,
        private popupService: PopupService
    ) { }

    ngOnInit(): void {
        this.constructForm();
        this.getUser();
    }

    constructForm() {
        this.form = this.fb.group({
            name: [null, [
                    Validators.required,
                    Validators.pattern(this.nameReg),
                    Validators.maxLength(100),
                ]
            ],
            username: [null, [
                    Validators.required,
                    Validators.minLength(6),
                    Validators.maxLength(50),
                    Validators.pattern(this.usernameReg),
                ]
            ],
            email: [null, [
                    Validators.required,
                    Validators.email,
                    Validators.minLength(6),
                    Validators.maxLength(50),
                ]
            ],
        });
    }

    getUser() {
        this.firebaseService.getUser(this.walletAddress).subscribe(response => {
            this.user = response[0];

            if (this.user) {
                this.form.patchValue(this.user);
            }
        });
    }

    submit() {
        if (!this.form.valid) {
            this.showFormError = true;
            return;
        }

        let body = new User(this.form.value);
        body.walletAddress = this.walletAddress;

        if (this.user)
            body.docId = this.user.docId

        this.loaderService.open();

        this.firebaseService.checkIsUserExist(body).subscribe(data => {
            let haveError = false;
            if (!data.walletCheck && !data.usernameCheck && !data.emailCheck) {
                haveError = false;
            }
            else if (this.user) {
                if (data.emailCheck.length > 0 && data.emailCheck[0].walletAddress != body.walletAddress) {
                    this.popupService.alert("Email has already existed. Please try another email.");
                    haveError = true;
                }
                if (data.usernameCheck.length > 0 && data.usernameCheck[0].walletAddress != body.walletAddress) {
                    this.popupService.alert("Username has already existed. Please try another username");
                    haveError = true;
                }
            }
            else {
                if (data.walletCheck.length > 0) {
                    this.popupService.alert("User already exists.");
                    haveError = true;
                }
                else if (data.usernameCheck.length > 0) {
                    this.popupService.alert("Username has already existed. Please try another username");
                    haveError = true;
                }
                else if (data.emailCheck.length > 0) {
                    this.popupService.alert("Email has already existed. Please try another email.");
                    haveError = true;
                }
            }

            if (!haveError) {
                let promise = this.user ?
                    this.firebaseService.updateUser(body) :
                    this.firebaseService.createUser(body);

                promise.then(result => {
                    this.toastr.success(`Profile ${this.user ? 'updated' : 'created'} successfully!`);
                    if (!this.user) {
                        window.location.reload();
                    }
                }).catch(err => {
                    this.popupService.alert(err.message);
                })
            }
            this.loaderService.close();
        }, err => {
            this.popupService.alert(err);
        }).add(() => {
            this.loaderService.close();
        });
    }

    get name() { return this.form.get('name'); }
    get email() { return this.form.get('email'); }
    get username() { return this.form.get('username'); }
}
