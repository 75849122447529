<ng-container *ngIf="hasAccess">
    <div class="container">
        <div class="text-center">
            <div class="ml-4 action-wrapper d-inline" *ngFor="let menu of SettingMenuOptions">
                <div class="btn section-title" (click)="filterListing(menu)" [ngClass]="{'gradient-seperator': selectedTab == menu.value}">
                    {{menu.label}}
                </div>
            </div>
        </div>
        <hr class="menu" />
        <div *ngIf="selectedTab == 1" class="asset-listing-wrapper">
            <app-general></app-general>
        </div>
        <div *ngIf="selectedTab == 2" class="asset-listing-wrapper">
            <app-my-wallet></app-my-wallet>
        </div>
    </div>
</ng-container>
