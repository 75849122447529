import { SocialMediaModel } from './social-media-model';

export class SocialMedia {
    facebook: string;
    facebookUrl: string;
    instagram: string;
    instagramUrl: string;
    twitter: string;
    twitterUrl: string;
    website: string;
    websiteUrl: string;

    constructor(model: SocialMediaModel) {
        this.facebook = model.facebook;
        this.facebookUrl = model.facebook_url;
        this.instagram = model.instagram;
        this.instagramUrl = model.instagram_url;
        this.twitter = model.twitter;
        this.twitterUrl = model.twitter_url;
        this.website = model.website;
        this.websiteUrl = model.website_url;
    }
}
