<div *ngIf="hasAccess" class="content-wrapper py-3">

    <div class="col-12 d-block d-md-none">
        <button class="btn btn-alt w-100 d-flex justify-content-center" (click)="isOpenFilter = !isOpenFilter">
            <div class="icon-filter"></div>
            <div>Filters</div>
        </button>
    </div>

    <div class="filter-container d-flex">
        <div class="filter-section d-none d-md-block d-lg-nonee">
            <div class="box-filter">
                <div class="filter-title">
                    Filters
                </div>
                <!--<div class="clear-btn" (click)="filterListing(0)">
                    Clear All
                </div>-->
            </div>
            <ng-template *ngTemplateOutlet="filterTemplate; context: {class: 'filter action-wrapper ml-4 mb-3'}">
            </ng-template>
        </div>
        <div *ngIf="tradingHistory.length > 0" class="d-block w-100">
            <div *ngFor="let item of tradingHistory" class="listing-section ml-3 mr-3 mb-3 w-100">
                <div class="box-item d-flex">
                    <div class="seperator-left">
                        <img class="item-img" [src]="item.asset.image_url" />
                    </div>
                    <div class="seperator-right">
                        <div class="item-title">
                            {{item.asset.name}}
                        </div>
                        <div class="item-description">
                            <div *ngIf="item.eventType == 'offer_entered'">
                                <span *ngIf="item.asset && item.asset.name" class="white-text">
                                    {{item.asset.name}}
                                </span>
                                <span *ngIf="!item.asset.name" class="white-text">
                                    {{truncateMiddleText(item.asset.assetContract.contractAddress)}}
                                </span>
                                <span class="gray-text">offered</span>
                                <span class="white-text">{{item.currentUnitPrice}} wETH</span>
                                <span class="gray-text">to</span>
                                <span *ngIf="item.contractAddress" class="white-text">
                                    {{truncateMiddleText(item.contractAddress)}}
                                </span>
                                <img src="/assets/img/svg-pangolin/Icon-check-gradient-large-24.svg" class="check-icon" />
                            </div>
                            <div *ngIf="item.eventType == 'successful'">
                                <span *ngIf="item.transaction && item.transaction.to_account && item.transaction.to_account.user && item.transaction.to_account.user.username" class="white-text">{{item.transaction.to_account.user.username}}</span>
                                <span *ngIf="!item.transaction || !item.transaction.to_account || !item.transaction.to_account.user || !item.transaction.to_account.user.username" class="white-text">{{truncateMiddleText(item.transaction.to_account.address)}}</span>
                                <img src="/assets/img/svg-pangolin/Icon-check-gradient-large-24.svg" class="check-icon mr-1" />
                                <span class="gray-text">accepted offer</span>
                                <span class="white-text">{{item.currentUnitPrice}} wETH</span>
                                <span class="gray-text">by</span>
                                <span *ngIf="!item.transaction || !item.transaction.from_account || !item.transaction.from_account.user || !item.transaction.from_account.user.username" class="white-text">
                                    {{truncateMiddleText(item.transaction.from_account.address)}}
                                </span>
                                <span *ngIf="!item.transaction || !item.transaction.from_account || !item.transaction.from_account.address" class="white-text">
                                    null
                                </span>
                            </div>
                            <div *ngIf="item.eventType == 'transfer'">
                                <span class="gray-text">transferred from</span>
                                <span *ngIf="item.fromAccount && item.fromAccount.user && item.fromAccount.user.username" class="white-text">{{item.fromAccount.user.username}}</span>
                                <span *ngIf="!item.fromAccount || !item.fromAccount.user || !item.fromAccount.user.username" class="white-text">{{truncateMiddleText(item.fromAccount.address)}}</span>
                                <img src="/assets/img/svg-pangolin/Icon-check-gradient-large-24.svg" class="check-icon mr-1" />
                                <span class="gray-text">to</span>
                                <span *ngIf="item.transaction && item.transaction.from_account.address" class="white-text">
                                    {{truncateMiddleText(item.transaction.from_account.address)}}
                                </span>
                                <span *ngIf="!item.transaction || !item.transaction.from_account.address" class="white-text">
                                    null
                                </span>
                            </div>
                        </div>
                        <div>
                            <span class="gray-text">at {{item.createdDate | amDateFormat: 'DD/MM/YYYY'}}, {{item.createdDate | amDateFormat: 'h:mma'}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-center pt-3" *ngIf="!loadTrading && haveMore && tradingHistory.length > 0">
                <button class="btn btn-alt" (click)="getMore()">Load More</button>
            </div>
            <div *ngIf="loadTrading">
                <agmo-loading></agmo-loading>
            </div>
        </div>
        <div class="no-data center-position mt-3" *ngIf="!loadTrading && tradingHistory.length == 0">
            No data available
        </div>

        <div class="mobile-filter d-block d-md-none" *ngIf="isOpenFilter">
            <div class="footer-title d-flex justify-content-between">
                <div class="font-weight-bold d-flex align-self-center">Filters</div>
                <div class="icon-close" (click)="isOpenFilter = false"></div>
            </div>
            <div class="footer-body pt-3">
                <ng-template *ngTemplateOutlet="filterTemplate; context: {class: 'filter action-wrapper px-3 mb-3'}">
                </ng-template>
            </div>
        </div>
    </div>
</div>

<ng-template #filterTemplate let-class="class">
    <div [class]="class" *ngFor="let category of tradingFilterOption; let i = index">
        <div class="btn btn-alt w-100 text-left" [ngClass]="{'active': selectedTab == i}" (click)="filterListing(i)">
            <img class="mr-3" *ngIf="i === 1" src="/assets/img/svg-pangolin/Icon-purchase-gradient-24.svg" />
            <img class="mr-3" *ngIf="i === 2" src="/assets/img/svg-pangolin/Icon-offer-gradient-24.svg" />
            <img class="mr-3" *ngIf="i === 3" src="/assets/img/svg-pangolin/Icon-transfer-gradient-24.svg" />
            <a>{{category.name}}</a>
        </div>
    </div>
</ng-template>
