import { CelebrityCategoryModel } from './celebrity-category-model';

export class CelebrityCategory {
    name: string;
    id: string;

    constructor(model: CelebrityCategoryModel) {
        this.name = model.name;
    }
}
