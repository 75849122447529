import { FaqModel } from './faq-model';

export class Faq {
    id: string;
    isOpen: boolean
    answer: string;
    category: string[];
    isPublished: boolean;
    question: string;

    constructor(model: FaqModel) {
        this.answer = model.answer;
        this.category = model.category;
        this.isPublished = model.is_published;
        this.question = model.question;
        this.isOpen = false;
    }
}
