<div class="wallet-wrapper">
    <div class="mb-4">
        <div class="title">
            Wallet address
        </div>
        <div class="text-detail">
            <div class="d-flex" (click)="promptToaster()" ngxClipboard [cbContent]="walletAddress">
                <div class="wallet-text">{{walletAddress}}</div>
                <div class="icon-copy ml-1"></div>
            </div>
        </div>
    </div>
    <div class="mb-4">
        <div class="title">
            Balance
        </div>
        <div class="text-detail">
            {{balance}} ETH
        </div>
    </div>
    <div class="mb-4">
        <div class="title">
            Offer Balance
        </div>
        <div>
            <div class="text-detail">
                {{offerBalance}} wETH
            </div>
            <div class="pt-2 float-right">
                <button (click)="toggleConvert()" class="btn text-converter w-100">Convert ETH</button>
            </div>
        </div>
    </div>
</div>
