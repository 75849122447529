import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../../shared.module';
import { ModalType } from '../../../core/enum/modal/modal.enum';
import { PurchaseModalComponent } from './purchase-modal/purchase-modal.component';
import { OrderObj } from '../../../core/models/opensea/opensea-orders';
import { Asset } from '../../../core/models/opensea/opensea-asset';

@Injectable({
    providedIn: SharedModule,
})
export class PurchaseService {
    constructor(
        private modalService: NgbModal
    ) { }

    open(modalType: ModalType, asset: Asset, celebrityName: string, orderObject?: OrderObj[]) {
        const popupOptions = {
            size: 'sm',
            backdrop: 'static',
            centered: true,
            keyboard: false,
            windowClass: 'modal-full-screen'
        } as NgbModalOptions;

        const modalRef = this.modalService.open(PurchaseModalComponent, popupOptions);
        modalRef.componentInstance.modalType = modalType;
        modalRef.componentInstance.asset = asset;
        modalRef.componentInstance.orderObject = orderObject;
        modalRef.componentInstance.celebrityName = celebrityName;
    }
}
