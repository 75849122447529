<div class="container-fluid pt-4 welcome-panel">

    <!-- Desktop -->
    <div class="row d-none d-md-flex">
        <div class="col-7 row">
            <div class="col-12 mb-4 title-container">
                <span>Welcome to </span>
                <span class="gradient">NFTPangolin</span>
                <span>!</span>
            </div>

            <div class="col-12">
                <p class="description-container">NFT Pangolin is a global marketplace for regional creators to issue and sell their unique crypto secured assets to collectors. It also can fund social causes and charitable campaigns to pay it forward.</p>
                <p class="description-container">We aspire to be the leading regional marketplace driving creative, innovative collaborations and boundary pushing campaigns in the NFT space globally for issuers and collectors alike, while making a difference for those in need.</p>
            </div>
        </div>

        <div class="col-5 align-self-center text-center">
            <img class="logo" src="assets/img/svg-pangolin/illus-pangolin-aboutus@2x.png" />
        </div>
    </div>

    <!-- Mobile -->
    <div class="row d-md-none mobile">
        <div class="col-12 mb-3 title-container">
            <span>Welcome to </span>
            <span class="gradient">NFTPangolin</span>
            <span>!</span>
        </div>

        <div class="col-12 mb-3 text-center">
            <img class="logo" src="assets/img/svg-pangolin/illus-pangolin-aboutus@2x.png" />
        </div>

        <div class="col-12 description-panel" [class.shrink]="isWelcomeDescriptionShrink">
            <div class="description-adjustment">
                <p class="description-container">NFT Pangolin is a global marketplace for regional creators to issue and sell their unique crypto secured assets to collectors. It also can fund social causes and charitable campaigns to pay it forward.</p>
                <p class="description-container mb-0">We aspire to be the leading regional marketplace driving creative, innovative collaborations and boundary pushing campaigns in the NFT space globally for issuers and collectors alike, while making a difference for those in need.</p>
                <span class="read-more text-center" (click)="onReadMoreClick()">{{isWelcomeDescriptionShrink ? 'Read more' : 'Read less'}}</span>
            </div>
        </div>

    </div>

    <hr class="gradient-seperator">

</div>

<div class="container-fluid pt-4 featured-talents">

    <div class="row mb-5">
        <div class="col-8 title">Featured Talents</div>
        <div class="col-4 text-right more-btn-container">
            <div class="btn btn-alt" [routerLink]="['/celebrities/Featured']">
                <span>More</span>
            </div>
        </div>
    </div>

    <div class="row nft-container">

        <ng-container *ngFor="let featuredCelebrity of featuredCelebrities | slice:0:3">
            <div class="col-4 no-padding-lr featured-celebrity">
                <div class="col-12 mb-3 featured-nft" [routerLink]="['/browse', featuredCelebrity.featuredNftAsset.assetAddress, featuredCelebrity.featuredNftAsset.tokenID]">
                    <img src="{{featuredCelebrity.featuredNftAsset.imageUrl}}" alt="Featured NFT" draggable="false">
                    <div class="nft-hover">
                        <span class="nft-title">{{featuredCelebrity.featuredNftAsset.name}}</span>
                    </div>
                </div>

                <div class="col-12 profile-container" [routerLink]="['/celebrities/celebrity-detail', featuredCelebrity.id]">
                    <div class="profile-picture">
                        <img class="profile-image" src="{{featuredCelebrity.profilePictureUrl}}" alt="Profile Picture" draggable="false">
                        <img *ngIf="featuredCelebrity.isVerified" class="verified-check" src="assets/img/svg-pangolin/Icon-verified-24.svg" alt="Verified" draggable="false">
                    </div>
                    <div class="profile-name">{{featuredCelebrity.name}}</div>
                </div>
            </div>
        </ng-container>

    </div>

    <hr class="gradient-seperator">

</div>

<div class="container-fluid pt-4 collectibles-container">

    <div class="row mb-4">
        <div class="col-12 title">Collectibles</div>
    </div>

    <!-- Desktop Filter -->
    <div class="row d-none d-md-flex">
        <div class="col-12">
            <div class="btn btn-alt mr-3" *ngFor="let category of categories;" [class.active]="selectedCategory == category.value" (click)="filterListing(category.value)">
                <span [ngClass]="category.name"> {{category.name}}</span>
            </div>
        </div>
    </div>

    <!-- Mobile Filter -->
    <div class="row d-md-none">
        <div class="col-12">
            <mat-select class="btn btn-alt category-select" panelClass="app-dropdown-panel" [(ngModel)]="selectedCategory" (selectionChange)="onMobileFilterChange()">
                <mat-select-trigger>
                    <div class="d-flex align-items-center">
                        <div *ngIf="selectedCategory=='All'" class="icon-all-category"></div>
                        <div class="category-name">{{ selectedCategoryName }}</div>
                    </div>
                </mat-select-trigger>
                <mat-option *ngFor="let category of categories;" [value]="category.value">
                    {{category.name}}
                </mat-option>
            </mat-select>
        </div>
    </div>

    <div class="row nft-container mt-5">

        <ng-container *ngFor="let celebrity of celebrities | slice:0:6">
            <div class="col-4 mb-4 no-padding-lr featured-celebrity">
                <div class="col-12 mb-3 featured-nft" [routerLink]="['/browse', celebrity.assets[0].asset_address, celebrity.assets[0].token_id]">
                    <img src="{{celebrity.assets[0].image_url}}" alt="Collectible" draggable="false">
                    <div class="nft-hover">
                        <span class="nft-title">{{celebrity.assets[0].name}}</span>
                    </div>
                </div>

                <div class="col-12 profile-container" [routerLink]="['/celebrities/celebrity-detail', celebrity.id]">
                    <div class="profile-picture">
                        <img class="profile-image" src="{{celebrity.profilePictureUrl}}" alt="Profile Picture" draggable="false">
                        <img *ngIf="celebrity.isVerified" class="verified-check" src="assets/img/svg-pangolin/Icon-verified-24.svg" alt="Verified" draggable="false">
                    </div>
                    <div class="profile-name">{{celebrity.name}}</div>
                </div>
            </div>
        </ng-container>

    </div>

    <div class="row mt-4 mb-4">
        <div class="col-12 text-center">
            <div class="btn btn-alt btn-normal-font-size" [routerLink]="['/browse']">
                View More
            </div>
        </div>
    </div>

    <hr class="gradient-seperator">

</div>

<div class="container-fluid px-0 buy-now-container">
    <div class="row d-flex align-items-center">
        <div class="col-md-3 d-none d-md-block">
            <img class="bg-design" src="assets/img/svg-pangolin/Contact-us-bg-design-left.svg" />
        </div>

        <div class="col-md-6 description-container text-center">
            <h1 class="color-header mb-4">
                Create and own unique nifty assets from Asian personalities
            </h1>
            <h1 class="color-header">
                Are you the next rare Pangolin to be discovered globally?
            </h1>
            <div class="mt-4 btn btn-alt btn-normal-font-size mr-5" [routerLink]="['/contact-us']">
                Create now
            </div>
            <div class="mt-4 btn btn-alt btn-normal-font-size active" [routerLink]="['/browse']">
                Buy now
            </div>
        </div>

        <div class="col-md-3 d-none d-md-block">
            <img class="bg-design" src="assets/img/svg-pangolin/Contact-us-bg-design-right.svg" />
        </div>
    </div>
</div>
