<div class="container-fluid">
    <div class="row pb-5">
        <div class="col-12 col-lg-4 pt-3">
            <div class="img-container">
                <img [src]="profilePicture" *ngIf="profilePicture" (click)="viewPhoto(profilePicture)" />
            </div>
        </div>
        <div class="col-12 col-lg-8 pt-5" *ngIf="celebrity">
            <div class="d-none d-md-flex overlay-name noselect">{{celebrity.name}}</div>
            <div class="row">
                <div class="col-12 celebrity-name">
                    {{celebrity.name}}
                </div>
                <div class="col-12 description">
                    {{celebrity.description}}
                </div>
            </div>
            <div class="row col-12 pt-sm-3 align-items-center">
                <div class="pt-3 c-pointer social-button" title="Copy Wallet Address">
                    <div class="gradient-capsule" (click)="promptToaster()" ngxClipboard [cbContent]="celebrity.walletAddress">
                        <div class="icon-wallet"></div>
                    </div>
                </div>
                <div class="pt-3 c-pointer social-button" title="Facebook" *ngIf="celebrity.socialMedia.facebook || celebrity.socialMedia.facebookUrl">
                    <a target="_blank" [href]="celebrity.socialMedia.facebookUrl">
                        <div class="gradient-capsule">
                            <div class="social-icon icon-fb"></div>
                        </div>
                    </a>
                </div>
                <div class="pt-3 c-pointer social-button" title="Instagram" *ngIf="celebrity.socialMedia.instagram || celebrity.socialMedia.instagramUrl">
                    <a target="_blank" [href]="celebrity.socialMedia.instagramUrl">
                        <div class="gradient-capsule">
                            <div class="social-icon icon-instagram"></div>
                        </div>
                    </a>
                </div>
                <div class="pt-3 c-pointer social-button" title="Twitter" *ngIf="celebrity.socialMedia.twitter || celebrity.socialMedia.twitterUrl">
                    <a target="_blank" [href]="celebrity.socialMedia.twitterUrl">
                        <div class="gradient-capsule">
                            <div class="social-icon icon-twitter"></div>
                        </div>
                    </a>
                </div>
                <div class="pt-3 c-pointer social-button" title="Website" *ngIf="celebrity.socialMedia.website || celebrity.socialMedia.websiteUrl">
                    <a target="_blank" [href]="celebrity.socialMedia.websiteUrl">
                        <div class="gradient-capsule">
                            <div class="social-icon icon-globe"></div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="collection">
        <div class="gradient-seperator section-title">Created</div>
        <div class="asset-listing-wrapper">
            <app-asset-listing *ngIf="assetParams" [params]="assetParams" [filters]="assetFilters"></app-asset-listing>
        </div>
    </div>
</div>
