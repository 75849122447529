import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { FirebaseService } from '../../core/services/firebase.service';
import { ContactForm, ContactFormQuestion } from '../../core/models/contact-form';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from '../../shared/services/loader/loader.service';

@Component({
    selector: 'app-contact-us',
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
    contactFormQuestions: ContactFormQuestion[] = [];
    form: FormGroup;
    showFormError: boolean = false;

    private readonly nameReg: RegExp = /^[\w\ `@\(\)\'\/\,\.\-]+$/;
    private readonly alphabetOnlyReg: RegExp = /^[a-zA-Z]*$/;

    constructor(
        private fb: FormBuilder,
        private firebaseService: FirebaseService,
        private toastr: ToastrService,
        private loaderService: LoaderService
    ) { }

    ngOnInit(): void {
        this.getContactFormQuestion();
        this.constructForm();
    }

    private constructForm() {
        this.form = this.fb.group({
            name: ['', {
                    validators: [
                        Validators.required,
                        Validators.pattern(this.nameReg),
                        Validators.maxLength(100),
                    ],
                    updateOn: 'blur',
                }
            ],
            email: ['', {
                    validators: [
                        Validators.required,
                        Validators.email,
                        Validators.minLength(6),
                        Validators.maxLength(50),
                    ],
                    updateOn: 'blur',
                }
            ],
            title: ['', {
                    validators: [
                        Validators.required,
                        Validators.pattern(this.alphabetOnlyReg),
                        Validators.minLength(6),
                        Validators.maxLength(50),
                    ],
                    updateOn: 'blur',
                }
            ],
            message: ['', [
                    Validators.required,
                    Validators.maxLength(999),
                ]
            ],
            question: ['', Validators.required],
        });
    }

    submit() {
        if (!this.form.valid) {
            this.showFormError = true;
            return;
        }

        let selectedQuestion: ContactFormQuestion = this.contactFormQuestions[this.form.get('question').value];
        let body = new ContactForm(this.form.value);
        body.question = selectedQuestion.question;
        body.supportEmail = selectedQuestion.email;

        this.loaderService.open();

        this.firebaseService.createContactForm(body).then(response => {
            this.firebaseService.sendEmailToDepartment(body).then(
                () => { },
            );

            this.toastr.success('Your message has been sent!').onHidden.subscribe(
                () => {
                    this.form.reset();
                    this.loaderService.close();
                }
            );
        }).catch(err => {
            console.error(err);
            this.loaderService.close();
        });
    }

    get name() { return this.form.get('name'); }
    get email() { return this.form.get('email'); }
    get title() { return this.form.get('title'); }
    get message() { return this.form.get('message'); }
    get question() { return this.form.get('question'); }

    private getContactFormQuestion(): void {
        this.firebaseService.getContactFormQuestion().subscribe(
            (questions) => {
                this.contactFormQuestions = questions;
            },
            (err) => { console.error(err); },
        );
    }

}
