<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="top-panel">
                <img class="left-confetti d-none d-md-block" src="assets/img/svg-pangolin/bg-confetti-left-contact-us.svg">
                <img class="right-confetti d-none d-md-block" src="assets/img/svg-pangolin/bg-confetti-left-contact-us.svg">
                <div class="title">Frequently asked <span class="gradient-text horizontal">Questions</span>
                </div>
            </div>
        </div>
    </div>
    <div class="row pt-3">
        <div class="col-lg-3 col-md-4 d-none d-md-block">
            <div class="filter-container">
                <div class="box-filter">
                    <div class="filter-title">
                        Filter by category
                    </div>
                </div>
                <ng-container *ngIf="faqCategories">
                    <div class="btn btn-alt mt-2 w-100 text-left" *ngFor="let category of faqCategories"
                        [class.active]="selectedCategory == category.id" (click)="filterListing(category.id)">
                        {{category.name}}
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="col-12 pb-3 d-md-none">
            <app-dropdown [options]="faqDropdown" [(value)]="selectedCategory" (callback)="filterListing($event)"
                [isGradient]="true">
            </app-dropdown>
        </div>
        <div class="col-lg-9 col-md-8">
            <div class="category-title d-md-block d-none">{{selectedCategoryName}}</div>
            <ng-container *ngIf="faqs?.length > 0">
                <ng-container *ngFor="let faq of faqs; let i = index">
                    <mat-accordion displayMode="flat">
                        <mat-expansion-panel (opened)="faq.isOpen = true" (closed)="faq.isOpen = false">
                            <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                                <span class="font-weight-bold text-white">{{faq.question}}</span>
                            </mat-expansion-panel-header>
                            <div class="answer pt-3" [innerHTML]="faq.answer"></div>
                        </mat-expansion-panel>
                        <hr class="my-3" [ngClass]="faq.isOpen ? 'gradient-seperator' : 'accordion-seperator'" />
                    </mat-accordion>
                </ng-container>
            </ng-container>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <hr class="gradient-seperator" />
        </div>
        <div class="col-12 faq-title pb-3">Have more <span class="gradient-text horizontal">questions?</span></div>
        <div class="col-12 text-center">For any further question, please <a [routerLink]="'/contact-us'">contact us
                here</a>. We are happy to help!</div>
    </div>
</div>