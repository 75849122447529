import { CommonModule } from '@angular/common';
import { HeyjomNavbarComponent } from './heyjom-navbar/heyjom-navbar.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    HeyjomNavbarComponent,
  ],
  imports: [
    CommonModule,
    MatAutocompleteModule,
    NgbModule,
    RouterModule,
  ],
  exports: [
    HeyjomNavbarComponent,
  ],
})
export class HeyjomModule { }
