<div [hidden]="!block">
    <div class="modal-backdrop" [class.show]="show"></div>

    <div class="modal show" [class.d-block]="show">
        <div class="modal-dialog modal-sm modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <agmo-loading></agmo-loading>
                    <div *ngIf="blockUiType == 1">Processing...</div>
                    <div *ngIf="blockUiType == 2">{{incorrectNetworkMessage}}</div>
                </div>
            </div>
        </div>
    </div>
</div>
