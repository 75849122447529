<mat-form-field [ngClass]="isGradient ? 'gradient-capsule' : ''" class="form-control px-2 w-100">
    <mat-select disableOptionCentering msInfiniteScroll
                #select="matSelect" panelClass="app-dropdown-panel"
                [(ngModel)]="value" (selectionChange)="update($event)">
        <mat-option *ngFor="let option of options"
                    [value]="option.value">
            {{option.name}}
        </mat-option>
    </mat-select>
</mat-form-field>
