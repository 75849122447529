import { APP_VERSION } from 'src/app/core/constants';
import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Asset } from '../../core/models/opensea/opensea-asset';
import { OpenseaService } from '../../core/services/opensea.service';
import { AdminLayoutRoutes } from '../../layouts/admin-layout/admin-layout.routing';
import { AssetFilter } from '../../pages/browse/asset-filter';
import { ConnectWalletComponent } from '../connect-wallet/connect-wallet.component';
import { FirebaseService } from '../../core/services/firebase.service';
import { Celebrity } from '../../core/models/celebrity';
import { PopupService } from '../../shared/services/popup/popup.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    APP_VERSION = APP_VERSION;
    isConnected = false;
    walletAddress;
    walletBalance: number;
    isDrop = false;
    isSearch = false;
    control = new FormControl();
    creatorResults: any;
    assets: Asset[];
    collectiblesResults: Asset[];
    params = new AssetFilter();
    celebrities: Celebrity[];
    celebritiesResults: Celebrity[];

    @ViewChild('autoCompleteInput', { read: MatAutocompleteTrigger }) autoComplete: MatAutocompleteTrigger;
    constructor(
        private location: Location,
        private router: Router,
        private openseaService: OpenseaService,
        private modalService: NgbModal,
        private firebaseService: FirebaseService,
        private popupService: PopupService
    ) { }

    ngOnInit() {
        this.openseaService.getAccount().then(response => {
            this.isConnected = response.result.length > 0 ? true : false;
            this.walletAddress = this.truncateAddressText();
        }).finally(() => {
            if (this.isConnected) {
                this.openseaService.getBalance().then(response => {
                    this.walletBalance = response;
                });
            }
        });

        this.getCelebrity();
        this.getAssets();

        this.control.valueChanges.pipe(debounceTime(100), distinctUntilChanged())
            .subscribe(() => {
                return this.filterCelebrity(this.control.value);
            });

        this.control.valueChanges.pipe(debounceTime(100), distinctUntilChanged())
            .subscribe(() => {
                return this.filterAssets(this.control.value);
            });
    }

    getTitle() {
        let url = this.location.prepareExternalUrl(this.location.path());

        if (url.charAt(0) === '#') {
            url = url.slice(2);
        }

        const route = AdminLayoutRoutes.find(r => r.path == url);

        return route && route.data ? route.data.title : null;
    }

    getCelebrity() {
        this.firebaseService.getCelebrityListingByCategory('All').subscribe(response => {
            this.celebrities = response;
            this.celebrities.map(celebrity => {
                this.firebaseService.getImageUrl(celebrity.profilePicture).subscribe(url => {
                    celebrity.profilePicture = url;
                });
            })
        })
    }

    filterCelebrity(searchText: string): Celebrity[] {
        if (this.celebrities) {
            this.celebritiesResults = this.celebrities.filter(celebrity => {
                return celebrity.name.toLowerCase().includes(searchText.toLowerCase());
            })
        } else {
            return [];
        }
    }

    getAssets() {
        this.params.limit = 50;
        this.openseaService.getAssetsHttp(this.params).subscribe(response => {
            this.assets = response.assets.map(e => { return new Asset(e) });
        })
    }

    filterAssets(searchText: string): Asset[] {
        if (this.assets) {
            this.collectiblesResults = this.assets.filter(asset => {
                return asset.name.toLowerCase().includes(searchText.toLowerCase());
            });
        }
        else {
            return [];
        }
    }

    checkIsConnected(url) {
        this.isDrop = false;
        this.openseaService.getAccount().then(response => {
            if (!response || response.result.length == 0 ) {
                this.popupService.alert('Please connect to your wallet to proceed.');
                this.router.navigate(['/landing-page']);
            }
            else {
                this.router.navigate([url]);
            }
        });
    }

    routeToPage(url) {
        this.isDrop = false;
        this.router.navigate([url]);
    }

    truncateAddressText() {
        let str = this.openseaService.getWalletAddress();
        return str.substr(0, 6) + '...' + str.substr(str.length - 4, str.length)
    }

    connectWallet() {
        this.openModal().then(result => {
        });
    }

    mobileCloseDrop() {
        this.isSearch = false;
        this.isDrop = false;
    }

    logout(): void {
        this.openseaService.fakeLogout();
        this.isConnected = false;
        this.popupService.alert('Successfully disconnected.');
    }

    private openModal(): Promise<any> {
        let defaultOptions: any = {
            size: 'sm',
            backdrop: 'static',
            centered: true,
            keyboard: false,
            windowClass: 'connect-modal-popup',
            backdropClass: 'connect-backdrop-popup',
        };

        const modalRef = this.modalService.open(ConnectWalletComponent, defaultOptions);

        return modalRef.result;
    }
}
