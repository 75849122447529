import { Component, NgZone, OnInit } from '@angular/core';
import { OpenseaService } from '../../../core/services/opensea.service';
import { BlockUiService } from '../../../shared/services/blockUi/block-ui.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup } from '@angular/forms';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ConvertCurrencyPopup } from '../../../shared/services/convert-currency-popup/convert-currency-popup.component';

@Component({
    selector: 'app-my-wallet',
    templateUrl: './my-wallet.component.html',
    styleUrls: ['./my-wallet.component.scss']
})
export class MyWalletComponent implements OnInit {
    hasAccess = false;
    walletAddress = this.openseaService.getWalletAddress();
    balance;
    offerBalance;
    selectedTab: number = 1;
    form: FormGroup;
    isConvertPage = false;
    uniswapSrc: string;
    isLoading = false;
    availableWETH: string;

    constructor(
        private openseaService: OpenseaService,
        private blockUiService: BlockUiService,
        private ngZone: NgZone,
        private toastr: ToastrService,
        public modalService: NgbModal,
    ) { }

    ngOnInit(): void {
        this.getBalance();
        this.getOfferBalance();
    }

    getBalance() {
        this.openseaService.getBalance().then(response => {
            this.balance = response;
        }, err => {
            console.log(err)
            if (err) {
                if (err.detail) {
                    console.log(err.detail);
                }
                else {
                    console.log(err);
                }
            }
        });
    }

    getOfferBalance() {
        this.openseaService.getWethBalance().then(response => {
            this.offerBalance = response;
        }, err => {
            console.log(err)
            if (err) {
                if (err.detail) {
                    console.log(err.detail);
                }
                else {
                    console.log(err);
                }
            }
        });
    }

    toggleConvert() {
        const popupOptions = {
            size: 'lg',
            backdrop: 'static',
            centered: true,
            keyboard: false,
        } as NgbModalOptions;

        const modalRef = this.modalService.open(ConvertCurrencyPopup, popupOptions);
    }

    promptToaster() {
        this.toastr.success('Wallet Address is Copied');
    }
}
