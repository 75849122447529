import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OpenseaService } from '../../core/services/opensea.service';

@Component({
    selector: 'app-connect-wallet',
    templateUrl: './connect-wallet.component.html',
    styleUrls: ['./connect-wallet.component.scss']
})
export class ConnectWalletComponent implements OnInit {
    @Input() walletAddress: string;
    @Input() walletBalance: number;
    isLoading = false;

    constructor(
        public activeModal: NgbActiveModal,
        private openseaService: OpenseaService,
    ) { }

    ngOnInit(): void {
    }

    connectWallet() {
        this.isLoading = true;
        this.openseaService.connectMetamask().then(result => {
            if (result)
                this.activeModal.close();
        }).finally(() => {
            this.isLoading = false;
        })
    }

    cancel() {
        this.activeModal.dismiss();
    }

    onLinkClick(): void {
        this.activeModal.dismiss();
    }
}
