<div class="modal-body">
    <div class="message" [innerHTML]="message | linkify"></div>

    <image-cropper [imageFileChanged]="imageFileChanged" 
        [maintainAspectRatio]="true" 
        [aspectRatio]="4 / 3"
        outputType="file" 
        format="png"
        outputType="both"
        [resizeToWidth]="128"
        (imageCropped)="imageCropped($event)"
    ></image-cropper>

    <div>Image Cropped: </div>
    <div><img [src]="croppedImage" /></div>
    
    <div class="buttons">
        <button type="button" class="btn btn-primary ok" aria-label="OK" (click)="ok()">OK</button>
        <button type="button" class="btn btn cancel" aria-label="Cancel" (click)="cancel()">Cancel</button>
    </div>
</div>