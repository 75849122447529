import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AssetFilter } from '../browse/asset-filter';
import { Celebrity } from '../../core/models/celebrity';
import { ClientSideFilter } from '../asset-listing/client-side-filter';
import { Component, OnInit } from '@angular/core';
import { FirebaseService } from '../../core/services/firebase.service';
import { PreviewService } from '../../shared/services/preview/preview.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-celebrity-detail',
    templateUrl: './celebrity-detail.component.html',
    styleUrls: ['./celebrity-detail.component.scss']
})
export class CelebrityDetailComponent implements OnInit {

    celebrityId = this.route.snapshot.paramMap.get('id');
    celebrity: Celebrity;
    profilePicture: string;
    assetParams: AssetFilter;
    assetFilters: ClientSideFilter;

    private destroy$ = new Subject();

    constructor(
        private route: ActivatedRoute,
        private firebaseService: FirebaseService,
        private toastr: ToastrService,
        private previewService: PreviewService,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.urlParamChangeHandler();
        this.getCelebrityDetail();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private getCelebrityDetail() {
        this.firebaseService.getCelebrity(this.celebrityId).then(response => {
            this.celebrity = response;

            this.firebaseService.getImageUrl(this.celebrity.profilePicture).subscribe(url => {
                this.profilePicture = url;
            });

            this.assetParams = new AssetFilter();
            this.assetParams.owner = this.celebrity.walletAddress;
            this.assetFilters = { creators: [this.celebrity.walletAddress] };
        })
    }

    /**
     * @deprecated
     */
    truncateMiddleText(str: string) {
        return str.substr(0, 6) + '...' + str.substr(str.length - 4, str.length)
    }

    promptToaster() {
        this.toastr.success('Wallet Address is Copied');
    }

    viewPhoto(photo: any) {
        let media = { imageUrl: photo };
        this.previewService.open(media);
    }

    /**
     * We will get the new parameter from URL & determine the new celebrity.
     */
    private urlParamChangeHandler(): void {
        this.router.events.pipe(takeUntil(this.destroy$)).subscribe(
            (val) => {
                if (val instanceof NavigationEnd) {
                    this.getCelebrityDetail();
                }
            }
        );
    }
}
