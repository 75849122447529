import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-preview-modal',
    templateUrl: './preview-modal.component.html',
    styleUrls: ['./preview-modal.component.scss']
})
export class PreviewModalComponent {
    isArray: boolean;
    data: any;
    activeId: number;

    constructor(
        private modal: NgbActiveModal) { }

    close() {
        this.modal.dismiss();
    }
}
