import { Component, NgZone, OnInit } from '@angular/core';
import { AssetFilter } from './asset-filter';
import { AssetFilterService } from './asset-filter.service';

@Component({
    selector: 'app-browse',
    templateUrl: './browse.component.html',
    styleUrls: ['./browse.component.scss']
})
export class BrowseComponent implements OnInit {
    params = this.assetFilterService.filter ? this.assetFilterService.filter : new AssetFilter();

    constructor(
        private assetFilterService: AssetFilterService
    ) { }

    ngOnInit(): void {
    }
}
