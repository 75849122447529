<div class="container-fluid">
    <div [ngClass]="{'row' : currentPage == 'browse'}">
        <div *ngIf="currentPage == 'browse'" class="filter-wrapper pt-3 col-3 d-md-block d-none">
            <div class="filter-container">
                <div class="box-filter">
                    <div class="filter-title">
                        Filters
                    </div>
                </div>
                <mat-accordion displayMode="flat">
                    <mat-expansion-panel expanded>
                        <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                            Categories
                        </mat-expansion-panel-header>
                        <div *ngFor="let category of categories; let i = index">
                            <mat-checkbox *ngIf="i < 3" name="category-{{i}}" [(ngModel)]="category.isSelected" (change)="selectCategory(i, $event)">
                                <span [ngClass]="category.name"> {{category.name}}</span>
                            </mat-checkbox>
                            <mat-checkbox *ngIf="i >= 3 && isCategoryExpand" name="category-{{i}}" [(ngModel)]="category.isSelected" (change)="selectCategory(i, $event)">
                                <span [ngClass]="category.name"> {{category.name}}</span>
                            </mat-checkbox>
                        </div>
                        <a class="expand" (click)="isCategoryExpand = !isCategoryExpand">{{isCategoryExpand? 'See less' : 'See more'}}</a>
                    </mat-expansion-panel>
                </mat-accordion>
                <hr class="accordion-seperator" />
                <mat-accordion displayMode="flat">
                    <mat-expansion-panel expanded>
                        <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                            Creators
                        </mat-expansion-panel-header>
                        <div *ngFor="let creator of creators; let i = index">
                            <mat-checkbox *ngIf="i < 5" name="creator-{{i}}" [(ngModel)]="creator.isSelected" (change)="selectCreator(i, $event)">
                                {{creator.name}}
                            </mat-checkbox>
                            <mat-checkbox *ngIf="i >= 5 && isCreatorExpand" name="creator-{{i}}" [(ngModel)]="creator.isSelected" (change)="selectCreator(i, $event)">
                                {{creator.name}}
                            </mat-checkbox>
                        </div>
                        <a class="expand" (click)="isCreatorExpand = !isCreatorExpand">{{isCreatorExpand? 'See less' : 'See more'}}</a>
                    </mat-expansion-panel>
                </mat-accordion>
                <hr class="accordion-seperator" />
                <mat-accordion displayMode="flat">
                    <mat-expansion-panel expanded>
                        <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                            Price
                        </mat-expansion-panel-header>
                        <div class="d-flex">
                            <div class="btn btn-alt w-100 d-flex" [ngClass]="{'active': selectedPriceFilter == 'ETH'}" (click)="selectedPriceFilter = 'ETH'">
                                <div [ngClass]="selectedPriceFilter == 'ETH' ? 'icon-eth-white' : 'icon-eth-gradient'"></div>
                                <div>ETH</div>
                            </div>
                            <div class="btn btn-alt w-100 d-flex" [ngClass]="{'active': selectedPriceFilter == 'USD'}" (click)="selectedPriceFilter = 'USD'">
                                <div [ngClass]="selectedPriceFilter == 'USD' ? 'icon-dollar-white' : 'icon-dollar-gradient'"></div>
                                <div>USD</div>
                            </div>
                        </div>
                        <div class="d-flex pt-3">
                            <input class="form-control" [(ngModel)]="minPrice" placeholder="Min" type="number" min="0" [max]="maxPrice" />
                            <div class="price-to">to</div>
                            <input class="form-control" [(ngModel)]="maxPrice" placeholder="Max" type="number" [min]="minPrice || 0" />
                            <button class="btn btn-purple ml-1" (click)="getAssets(true)">Go</button>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
        <div [ngClass]="currentPage == 'browse' ? 'asset-wrapper col-12 col-md-9' : ''" class="pt-3">
            <div *ngIf="currentPage == 'browse'" class="row justify-content-end">
                <div class="col-12 col-md-3">
                    <mat-form-field class="form-control px-2">
                        <mat-select disableOptionCentering msInfiniteScroll
                                    #select="matSelect" panelClass="app-dropdown-panel"
                                    [(ngModel)]="selectedOption">
                            <mat-option *ngFor="let option of orderByOptions; let i = index"
                                        [value]="i" (click)="filterListing()">
                                {{option.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 d-block d-md-none">
                    <button class="btn btn-alt w-100 mt-3 d-flex justify-content-center" (click)="isOpenFilter = true">
                        <div class="icon-filter"></div>
                        <div>Filters</div>
                    </button>
                </div>
            </div>
            <div class="asset-list-wrapper">
                <app-flex-gallery [nftDataObs]="nftData$"></app-flex-gallery>

                <div class="no-data" *ngIf="!isLoading && data.length == 0 && !haveMore">
                    No asset available
                </div>
                <div class="text-center pt-3" *ngIf="!isLoading && haveMore">
                    <button class="btn btn-alt" (click)="getMore()">Load More</button>
                </div>
                <div class="pt-3" *ngIf="isLoading">
                    <agmo-loading></agmo-loading>
                </div>
            </div>
        </div>
    </div>
</div>

<div [ngClass]="{'d-block' : isOpenFilter == true}" class="d-none mobile-filter">
    <div class="container-fluid py-3">
        <div class="box-filter d-flex justify-content-between">
            <div class="filter-title">
                Filters
            </div>
            <div class="icon-close" (click)="isOpenFilter = false"></div>
        </div>
        <mat-accordion displayMode="flat">
            <mat-expansion-panel expanded>
                <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                    Categories
                </mat-expansion-panel-header>
                <div *ngFor="let category of categories; let i = index">
                    <mat-checkbox *ngIf="i < 3" name="category-{{i}}" [(ngModel)]="category.isSelected" (change)="selectCategory(i, $event)">
                        <span [ngClass]="category.name"> {{category.name}}</span>
                    </mat-checkbox>
                    <mat-checkbox *ngIf="i >= 3 && isCategoryExpand" name="category-{{i}}" [(ngModel)]="category.isSelected" (change)="selectCategory(i, $event)">
                        <span [ngClass]="category.name"> {{category.name}}</span>
                    </mat-checkbox>
                </div>
                <a class="expand" (click)="isCategoryExpand = !isCategoryExpand">{{isCategoryExpand? 'See less' : 'See more'}}</a>
            </mat-expansion-panel>
        </mat-accordion>
        <hr class="accordion-seperator" />
        <mat-accordion displayMode="flat">
            <mat-expansion-panel expanded>
                <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                    Creators
                </mat-expansion-panel-header>
                <div *ngFor="let creator of creators; let i = index">
                    <mat-checkbox *ngIf="i < 5" name="creator-{{i}}" [(ngModel)]="creator.isSelected" (change)="selectCreator(i, $event)">
                        {{creator.name}}
                    </mat-checkbox>
                    <mat-checkbox *ngIf="i >= 5 && isCreatorExpand" name="creator-{{i}}" [(ngModel)]="creator.isSelected" (change)="selectCreator(i, $event)">
                        {{creator.name}}
                    </mat-checkbox>
                </div>
                <a class="expand" (click)="isCreatorExpand = !isCreatorExpand">{{isCreatorExpand? 'See less' : 'See more'}}</a>
            </mat-expansion-panel>
        </mat-accordion>
        <hr class="accordion-seperator" />
        <mat-accordion displayMode="flat">
            <mat-expansion-panel expanded>
                <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                    Price
                </mat-expansion-panel-header>
                <div class="d-flex">
                    <div class="btn btn-alt w-100 d-flex" [ngClass]="{'active': selectedPriceFilter == 'ETH'}" (click)="selectedPriceFilter = 'ETH'">
                        <div [ngClass]="selectedPriceFilter == 'ETH' ? 'icon-eth-white' : 'icon-eth-gradient'"></div>
                        <div>ETH</div>
                    </div>
                    <div class="btn btn-alt w-100 d-flex" [ngClass]="{'active': selectedPriceFilter == 'USD'}" (click)="selectedPriceFilter = 'USD'">
                        <div [ngClass]="selectedPriceFilter == 'USD' ? 'icon-dollar-white' : 'icon-dollar-gradient'"></div>
                        <div>USD</div>
                    </div>
                </div>
                <div class="d-flex pt-3">
                    <input class="form-control" [(ngModel)]="minPrice" placeholder="Min" type="number" min="0" [max]="maxPrice" />
                    <div class="price-to">to</div>
                    <input class="form-control" [(ngModel)]="maxPrice" placeholder="Max" type="number" [min]="minPrice || 0" />
                    <button class="btn btn-purple ml-1" (click)="processPriceFilter()">Go</button>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
    <div class="filter-footer container-fluid">
        <div class="w-100">
            <button class="w-100 btn btn-solid" (click)="isOpenFilter = false">
                View Results
            </button>
        </div>
    </div>
</div>
