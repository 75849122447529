<div class="modal-dialog">
    <div class="modal-content">
        <div class="modal-header">
            <h2 class="modal-title">
                Connect your Wallet
            </h2>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel()">
                <div class="icon icon-close"></div>
            </button>
        </div>
        <div class="modal-body">
            <div class="pb-3">
                By connecting your wallet, you agree to our <span class="popup-link" [routerLink]="['/terms']" (click)="onLinkClick()">Terms of Service</span> and our <span class="popup-link" [routerLink]="['/privacy-policy']" (click)="onLinkClick()">Privacy Policy</span>
            </div>
            <button class="btn btn-connect" (click)="connectWallet()">
                {{isLoading ? 'Connecting...' : 'Metamask'}}
                <img *ngIf="!isLoading" src="/assets/img/svg-pangolin/Logo-metamask.svg" />
                <div *ngIf="isLoading" class="app-loading">
                    <svg class="spinner" viewBox="25 25 50 50">
                        <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="3" stroke-miterlimit="10" />
                    </svg>
                </div>
            </button>
            <div class="pt-3">
                New to NFTPangolin?
            </div>
            <span class="popup-link" [routerLink]="['/how-to-get-started']" fragment="tab3" (click)="onLinkClick()">Learn more on getting started</span>
        </div>
    </div>
</div>
