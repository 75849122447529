<div class="modal-header">
    <div *ngIf="type == PopupType.alert">{{ titleLabel || 'Information' }}</div>
    <div *ngIf="type == PopupType.confirm">{{ titleLabel || 'Confirmation' }}</div>
</div>
<div class="modal-body">
    <div class="message" [innerHTML]="message | linkify"></div>

    <div class="buttons">
        <button type="button" class="btn btn-primary ok" aria-label="OK" (click)="ok()" [ngClass]="okClass">
            {{ okLabel || 'OK' }}
        </button>
        <button type="button" class="btn btn cancel" aria-label="Cancel" (click)="cancel()" [ngClass]="cancelClass" *ngIf="type == PopupType.confirm">
            {{ cancelLabel || 'Cancel' }}
        </button>
    </div>
</div>
