import { SortDirection } from '../../core/enum/general/sort-direction.enum';
import { AssetSort } from '../../core/enum/asset/asset-sort.enum';
import { environment as env } from '../../../environments/environment';

export class AssetFilter {
    offset: number;
    limit: number;
    order_direction: SortDirection | string;
    order_by: AssetSort;
    collection: string;
    owner: string;

    constructor() {
        this.offset = 0;
        this.limit = 10;
        this.order_direction = SortDirection.desc ;
        this.collection = env.openSeaCollection;
    }
}
